/* import __COLOCATED_TEMPLATE__ from './conversation-card.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
import Component from '@glimmer/component';
import type { ConversationExploreConversation } from './conversation-explore-panel';
import { tracked } from '@glimmer/tracking';

interface Args {
  conversation: ConversationExploreConversation;
  onConversationSelected: (conversationId: number) => void;
  isSelected: boolean;
}

interface AvatarDataModel {
  avatarData: {
    avatarShape: 'circle' | 'squircle' | undefined;
    initials: string;
    isCustomer: boolean;
  };
}

export default class ConversationCard extends Component<Args> {
  @tracked isHovered = false;
  get tooltipContent() {
    return this.args.conversation.topicSummary;
  }

  get avatarModel(): AvatarDataModel {
    let initials = this.args.conversation.userName
      ? this.args.conversation.userName
          .split(' ')
          .map((name) => name[0])
          .join('')
          .toUpperCase()
      : '-';
    return {
      avatarData: {
        avatarShape: 'circle',
        initials,
        isCustomer: true,
      },
    };
  }

  get nameToDisplay() {
    return this.args.conversation.userName
      ? this.args.conversation.userName
      : this.args.conversation.userPseudonym;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::ConversationCard': typeof ConversationCard;
  }
}
