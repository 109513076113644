/* import __COLOCATED_TEMPLATE__ from './list-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

const OPERATOR_LABEL_MAPPINGS = {
  category: 'is',
  not_in_category: 'is not',
  exists: 'has any value',
  not_exists: 'is unknown',
};

export default class ListFilter extends Component {
  @service appService;
  @service store;
  @service intercomEventService;
  @tracked label = this.currentLabel;
  // store the selection here so that the UI doesn't update until the drop down is closed
  @tracked values = this.startingValues;
  @tracked operator = this.startingOperator;

  get startingValues() {
    let values = this.args.selected.values || [this.listOptions[0].id];
    return values[0] === REPORTING_FILTER_SELECT_ALL ? [] : values;
  }

  get startingOperator() {
    return this.args.selected.operator || 'category';
  }

  get customField() {
    let id = this.args.type;
    return this.store.peekRecord('conversation-attributes/descriptor', id);
  }

  get name() {
    if (this.customField.archived) {
      return `${this.customField.name} (Archived)`;
    } else {
      return this.customField.name;
    }
  }

  get listOptions() {
    // get the options based on the conversation attribute descriptor id
    return this.customField.listOptions
      .map((option) => ({
        id: option.listOptionId,
        label: option.label,
        archived: option.archived,
      }))
      .sort((a, b) => a.archived - b.archived);
  }

  get items() {
    return [
      {
        items: this.allItems,
      },
    ];
  }

  get allItems() {
    return this.listOptions.map((option) => ({
      text: option.label,
      value: option.id,
      isSelected: this.values.includes(option.id),
      count: option.archived ? '(Archived)' : '',
    }));
  }

  get currentLabel() {
    switch (this.operator) {
      case 'category':
      case 'not_in_category': {
        let label = this.optionNames || 'Any';
        return `${OPERATOR_LABEL_MAPPINGS[this.operator]} ${label}`;
      }
      case 'exists':
      case 'not_exists':
        return OPERATOR_LABEL_MAPPINGS[this.operator];
    }
  }

  get optionNames() {
    return this.values
      .map((optionId) => {
        return this.listOptions.find((option) => option.id === optionId).label;
      })
      .join(', ');
  }

  get openerLabel() {
    return this.optionNames || 'Add';
  }

  get valuesForFilter() {
    let valuesOrPlaceholder = this.values.length ? this.values : [REPORTING_FILTER_SELECT_ALL];
    return ['category', 'not_in_category'].includes(this.operator) ? valuesOrPlaceholder : [];
  }

  @action
  onClose() {
    if (!this.valuesForFilter.length && !this.operator) {
      return;
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_custom_field',
      object: this.args.analyticsObject,
      custom_field_type: 'list',
    });

    this.args.setSelected(this.valuesForFilter, this.operator);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.values = [];
    this.operator = null;
    this.args.removeFilter();
  }
}
