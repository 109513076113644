/* import __COLOCATED_TEMPLATE__ from './insights-report.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { filterableProperties } from 'embercom/lib/reporting/custom/filter-helpers';
import type RouterService from '@ember/routing/router-service';
// @ts-ignore no type declaration available for ember-copy
import { copy } from 'ember-copy';

interface Args {
  conversationAttributeDescriptors: ConversationAttributeDescriptor[];
}

export default class InsightsReport extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare router: RouterService;

  get aiCsatMetricIdOrFallback() {
    // Only use AI CSAT metric if the workspace has access to it
    return this.reportingMetrics.metricExists('conversation.ai_generated_metrics.csat_score')
      ? 'conversation.ai_generated_metrics.csat_score'
      : 'conversation_rating.any_agent.csat';
  }

  mergeWithDefaults(charts: any[]) {
    return charts.map((chart: any) => this.mergeChartWithDefaults(chart));
  }

  mergeChartWithDefaults(chart: any) {
    let result = copy(chart, true);
    result.default_drill_in_attributes = [
      'conversation.id',
      'conversation_custom_fields#ai_insights_topic',
      'conversation_custom_fields#ai_insights_subtopic',
      'conversation_custom_fields#ai_insights_conversation_summary',
    ];
    result.visualization_options ||= {};
    result.visualization_options.allow_drill_in_from_chart = true;
    result.chart_series.forEach((series: any) => {
      if (series.filters.type === 'and' && series.filters.filters.length === 0) {
        series.filters.filters = [
          {
            type: 'exists',
            data: {
              attribute: 'conversation_custom_fields#ai_insights_topic',
              property: 'conversation_custom_fields#ai_insights_topic',
            },
          },
          {
            type: 'exists',
            data: {
              attribute: 'conversation_custom_fields#ai_insights_subtopic',
              property: 'conversation_custom_fields#ai_insights_subtopic',
            },
          },
        ];
      }
    });
    return result;
  }

  get topicAttribute() {
    if (this.reportingMetrics.getAttributeById('conversation_custom_fields#ai_insights_topic')) {
      return 'conversation_custom_fields#ai_insights_topic';
    }
    return 'topic';
  }

  // TODO translate all strings once they've been finalized
  /* eslint-disable @intercom/intercom/no-bare-strings */
  get reportDefinition() {
    return {
      id: 'insights_report',
      title: 'Topics: what your customers are talking about',
      description: null,
      charts: this.mergeWithDefaults([
        {
          id: 'insights.asking_about',
          title: 'What are users asking about?',
          visualization_type: 'donut',
          created_by_id: -1,
          updated_by_id: -1,
          date_range: {
            selection: 'past_4_weeks',
          },
          filters: {
            type: 'and',
            filters: [],
          },
          stacked: false,
          view_by: this.topicAttribute,
          view_by_display_limit: 15,
          view_by_display_other: false,
          segment_by_display_limit: 10,
          segment_by_display_other: false,
          chart_series: [
            {
              metric_id: 'v1.new_conversations',
              aggregation: 'count',
              show_data_labels: true,
              filters: {
                type: 'and',
                filters: [],
              },
            },
          ],
          visualization_options: {
            show_relative_values: false,
            show_summary_row: false,
            show_time_comparison: false,
          },
          grid_position_x: 0,
          grid_position_y: 0,
          grid_width: 12,
          grid_height: 8,
        },
        {
          visualization_type: 'banner',
          description: 'Topic volume',
          grid_position_x: 0,
          grid_position_y: 0,
          grid_width: 12,
          grid_height: 1,
          chart_series: [],
          date_range: {},
          filters: {},
        },
        {
          id: 'insights.volume_over_time',
          title: 'Topic volume over time',
          description: 'Click on legend to toggle visibility',
          visualization_type: 'line',
          created_by_id: -1,
          updated_by_id: -1,
          date_range: {
            selection: 'past_12_weeks',
          },
          filters: {
            type: 'and',
            filters: [],
          },
          stacked: false,
          view_by: 'time',
          view_by_time_interval: 'week',
          view_by_display_limit: 10,
          view_by_display_other: false,
          segment_by: this.topicAttribute,
          segment_by_display_limit: 15,
          segment_by_display_other: false,
          chart_series: [
            {
              metric_id: 'v1.new_conversations',
              aggregation: 'count',
              show_data_labels: false,
              filters: {
                type: 'and',
                filters: [],
              },
            },
          ],
          visualization_options: {
            show_relative_values: false,
            show_summary_row: false,
            show_time_comparison: false,
          },
          grid_position_x: 6,
          grid_position_y: 0,
          grid_width: 12,
          grid_height: 9,
        },
        {
          visualization_type: 'banner',
          description: 'Topics deep dive',
          grid_position_x: 0,
          grid_position_y: 0,
          grid_width: 12,
          grid_height: 1,
          chart_series: [],
          date_range: {},
          filters: {},
        },
        {
          id: 'insights.topics-resolved',
          title: 'Topics most frequently resolved by AI Agent',
          visualization_type: 'horizontal-bar',
          created_by_id: -1,
          updated_by_id: -1,
          date_range: {
            selection: 'past_4_weeks',
          },
          filters: {
            type: 'and',
            filters: [],
          },
          stacked: false,
          view_by: this.topicAttribute,
          view_by_display_limit: 15,
          view_by_display_other: false,
          segment_by_display_limit: 10,
          segment_by_display_other: false,
          chart_series: [
            {
              metric_id: 'fin.ai_answer.count',
              show_data_labels: true,
              filters: {
                type: 'and',
                filters: [],
              },
            },
          ],
          visualization_options: {
            show_relative_values: false,
            show_summary_row: false,
            show_time_comparison: false,
          },
          grid_position_x: 0,
          grid_position_y: 7,
          grid_width: 6,
          grid_height: 7,
        },
        {
          id: 'insights.topics-escalated',
          title: 'Topics most frequently escalated to humans',
          visualization_type: 'horizontal-bar',
          created_by_id: -1,
          updated_by_id: -1,
          date_range: {
            selection: 'past_4_weeks',
          },
          filters: {
            type: 'and',
            filters: [],
          },
          stacked: false,
          view_by: this.topicAttribute,
          view_by_display_limit: 15,
          view_by_display_other: false,
          segment_by_display_limit: 10,
          segment_by_display_other: false,
          chart_series: [
            {
              metric_id: 'fin.conversations_routed_to_team.count',
              show_data_labels: true,
              filters: {
                type: 'and',
                filters: [],
              },
            },
          ],
          visualization_options: {
            show_relative_values: false,
            show_summary_row: false,
            show_time_comparison: false,
          },
          grid_position_x: 4,
          grid_position_y: 7,
          grid_width: 6,
          grid_height: 7,
        },
        {
          visualization_type: 'banner',
          description: 'Drivers',
          grid_position_x: 0,
          grid_position_y: 0,
          grid_width: 12,
          grid_height: 1,
          chart_series: [],
          date_range: {},
          filters: {},
        },
        {
          id: 'insights.topics-csat',
          title: "What's driving CSAT and Resolution Rate?",
          visualization_type: 'tabular',
          created_by_id: -1,
          updated_by_id: -1,
          date_range: {
            selection: 'past_4_weeks',
          },
          filters: {
            type: 'and',
            filters: [],
          },
          stacked: false,
          view_by: this.topicAttribute,
          view_by_display_limit: 40,
          view_by_display_other: false,
          segment_by_display_limit: 7,
          segment_by_display_other: false,
          chart_series: [
            {
              metric_id: 'v1.new_conversations',
              aggregation: 'count',
              show_data_labels: false,
              filters: {
                type: 'and',
                filters: [],
              },
            },
            {
              metric_id: 'fin.resolution_rate',
              show_data_labels: false,
              filters: {
                type: 'and',
                filters: [],
              },
              label: 'Resolution rate (Fin AI Agent)',
            },
            {
              metric_id: 'fin.routed_to_team_rate',
              show_data_labels: false,
              filters: {
                type: 'and',
                filters: [],
              },
              label: 'Escalation rate',
            },
            {
              metric_id: this.aiCsatMetricIdOrFallback,
              show_data_labels: false,
              filters: {
                type: 'and',
                filters: [],
              },
            },
          ],
          visualization_options: {
            allow_drill_in_from_chart: true,
            show_relative_values: false,
            show_summary_row: false,
            show_time_comparison: false,
          },
          grid_position_x: 0,
          grid_position_y: 11,
          grid_width: 12,
          grid_height: 8,
        },
      ]),
      date_range: {
        selection: 'past_12_weeks',
        start: null,
        end: null,
      },
      filters: {
        type: 'and',
        filters: [],
      },
    };
  }

  get filterableProperties() {
    return [...filterableProperties(), 'teammate', 'team', 'conversation.replied.workflow_ids'];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::InsightsReport': typeof InsightsReport;
  }
}
