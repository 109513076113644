/* import __COLOCATED_TEMPLATE__ from './data-table.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { restartableTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import {
  buildFiltersForDataConfig,
  buildFiltersForDatasetConfig,
} from '../../../lib/reporting/custom/data-config-builder-helpers';
import { type Task, type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type { FilterItem, Metric } from 'embercom/objects/reporting/unified/metrics/types';
import type IntlService from 'ember-intl/services/intl';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import { compact, isEmpty } from 'underscore';
import { action } from '@ember/object';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { tracked } from '@glimmer/tracking';
import type Router from '@ember/routing/router-service';
import { trackedTask } from 'ember-resources/util/ember-concurrency';
import type ApplicationInstance from '@ember/application/instance';
import type { CountSummary } from 'embercom/components/reporting/custom/report/underlying-data-modal';
import type { StreamResourceManager } from 'embercom/components/reporting/custom/report/drill-in-preview-panel';
import type ReportingUnderlyingDataService from 'embercom/services/reporting-underlying-data-service';
import Conversation from 'embercom/objects/inbox/conversation';
import { CopilotPromptResponsePair } from 'embercom/lib/inbox2/copilot/types';
import { type LogicalFilter } from 'embercom/components/reporting/custom/filters';
import { type FlexibleQueryTimeRange } from 'embercom/services/reporting-csv-export';
import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import type ReportingChartService from 'embercom/services/reporting-chart-service';
import { type DrillInResult } from 'embercom/services/reporting-underlying-data-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

type Args = {
  renderableChart?: RenderableChart;
  filters: LogicalFilter;
  drillInTimeRange: FlexibleQueryTimeRange;
  columns: string[];
  tableProperties: FilterItem[];
  pageFrom: number;
  updatePageFrom: (value: number) => void;
  onDataUpdate: (summary: CountSummary) => void;
  resetPageFrom: () => void;
  streamResourceManager: StreamResourceManager;
  onSortUpdate: (sortBy: string, sortDirection: 'asc' | 'desc') => void;
  timezone: string;
  rowsPerPage: number;
  metric?: Metric;
  isBeingRenderedAsAChart: boolean;
  isStandalone?: boolean;
  datasetId: string;
  sortBy: string;
};

interface EmptyState {
  icon: InterfaceIconName;
  title: string;
  description: string;
}

const TEAMMATE_ID = 'teammate_id';

export default class DataTable extends Component<Args> {
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare router: Router;
  @service declare intercomEventService: any;
  @service declare reportingUnderlyingDataService: ReportingUnderlyingDataService;
  @service declare reportingChartService: ReportingChartService;

  @tracked sortBy: string = this.args.sortBy;
  @tracked sortDirection: 'asc' | 'desc' = 'desc';
  @tracked isLoading = true;
  @tracked private results: NativeArray<any> = A([]);
  @tracked totalHits = 0;
  @tracked sortState = {
    valuePath: this.sortBy,
    direction: this.sortDirection,
  };
  @tracked accessRestricted = false;

  readonly CopilotPromptResponsePairType = CopilotPromptResponsePair;

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);
  }

  fetchDataTask: Task<any, any> = taskFor(this.fetchData);

  tableData = trackedTask(this, this.fetchDataTask, () => [
    this.sortBy,
    this.sortDirection,
    this.filters,
    this.args.pageFrom,
  ]);

  get app() {
    return this.appService.app;
  }

  get getSortedAttributeId() {
    return this.reportingUnderlyingDataService.getSortByAttributeId(
      this.args.datasetId,
      this.args.metric,
    );
  }

  get columns() {
    let columns = this.args.columns.map((attributeId) => {
      // Only show columns for attributes that are in the dataset
      let dataset = this.reportingMetrics.getDatasetById(this.args.datasetId);
      if (!dataset || !dataset.hasAttribute(attributeId)) {
        return null;
      }

      let attribute = this.reportingMetrics.getAttributeById(attributeId);
      let { id, field, headerName, name, dataType } = attribute;

      let label = headerName || name || '';
      let tooltipText = null;
      if (field === TEAMMATE_ID && this.args.metric) {
        // TODO we can remove this as it's no longer needed
        tooltipText = this.args.metric.teammateIdDescription || null;
      } else if (headerName) {
        tooltipText = name;
      }
      return this.makeColumn(label, tooltipText, id, dataType);
    });

    return compact(columns);
  }

  get shouldShowEmptyState() {
    return (
      this.accessRestricted ||
      (isEmpty(this.tableData.value) && !this.tableData.isRunning) ||
      isEmpty(this.columns)
    );
  }

  get emptyState(): EmptyState {
    if (this.accessRestricted) {
      return {
        icon: 'alert-circle',
        title: this.intl.t('components.reporting.custom.report.data-table.access-restricted'),
        description: this.intl.t(
          'components.reporting.custom.report.data-table.access-restricted-description',
        ),
      };
    } else if (isEmpty(this.tableData.value) && !this.tableData.isRunning) {
      return {
        icon: 'column-chart',
        title: this.intl.t('components.reporting.custom.report.data-table.empty-state-header'),
        description: this.intl.t(
          'components.reporting.custom.report.data-table.empty-state-description',
        ),
      };
    } else {
      return {
        icon: 'column-chart',
        title: this.intl.t('components.reporting.custom.report.data-table.no-columns-header'),
        description: this.intl.t(
          'components.reporting.custom.report.data-table.no-columns-description',
        ),
      };
    }
  }

  private makeColumn(
    label: string,
    tooltipText: string | null,
    valuePath: string,
    dataType: string,
  ) {
    return {
      label,
      valuePath,
      isSortable: true,
      labelCanWrap: true,
      minWidth: '192px', //min-w-48
      maxWidth: '384px', //max-w-96
      tooltip: tooltipText,
      dataType,
    };
  }

  updateSortState(sortBy: string, sortDirection: 'asc' | 'desc') {
    this.sortState = {
      valuePath: sortBy,
      direction: sortDirection,
    };
  }

  @restartableTask
  *fetchData(): TaskGenerator<any> {
    if (this.args.pageFrom === 0) {
      this.results = [];
    }

    this.updateSortState(this.args.sortBy, this.sortDirection);

    try {
      let responseResult: DrillInResult =
        yield this.reportingUnderlyingDataService.loadDrillInResult(
          this.app.id,
          this.args.datasetId,
          this.filters as LogicalFilter,
          this.args.pageFrom,
          this.args.rowsPerPage,
          this.args.drillInTimeRange,
          this.args.columns,
          this.args.sortBy,
          this.sortDirection,
          this.reportingChartService.useSyntheticData,
          this.args.timezone,
          this.args.isBeingRenderedAsAChart,
        );
      this.accessRestricted = false;
      this.results.addObjects(responseResult.data);
      this.totalHits = responseResult.totalHits;
      this.args.onDataUpdate({
        retrievedCount: this.results.length,
        totalCount: this.totalHits,
      });
      return this.results;
    } catch (e) {
      if (e.errorThrown === 'Forbidden') {
        this.totalHits = 0;
        this.results = [];
        this.args.onDataUpdate({
          retrievedCount: 0,
          totalCount: 0,
        });
        this.accessRestricted = true;
      } else {
        console.error(e);
      }
    }
  }

  get filters() {
    if (!this.args.renderableChart) {
      return buildFiltersForDatasetConfig(this.args.datasetId, this.args.filters);
    }
    return buildFiltersForDataConfig(
      this.args.renderableChart,
      this.args.metric,
      undefined,
      this.args.filters,
      false,
      this.args.datasetId !== 'admin_status_change',
    );
  }

  get lastPageHit() {
    return this.results.length === this.totalHits;
  }

  get disableActions() {
    return this.args.renderableChart?.reportState?.isTemplate || false;
  }

  @action
  onSort(valuePath: string) {
    this.args.resetPageFrom();
    if (valuePath === this.sortBy) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortBy = valuePath;
      this.sortDirection = 'desc';
    }
    let direction = this.sortDirection;
    this.sortState = { valuePath, direction };
    this.args.onSortUpdate(this.sortBy, this.sortDirection);
  }

  @action
  loadNextPage() {
    this.args.updatePageFrom(this.args.rowsPerPage);

    this.intercomEventService.trackAnalyticsEvent({
      object: 'data_table',
      action: 'clicked_load_more',
      page_number: this.args.pageFrom / this.args.rowsPerPage,
    });
  }

  get isInitialLoad() {
    return this.tableData.isRunning && isEmpty(this.results);
  }

  get conversationIds() {
    return this.results.map((result) => result['conversation.id']);
  }

  get copilotPromptIds() {
    return this.results.map((result) => result['copilot.question.id']);
  }

  @action
  getAdminById(id: any) {
    return this.appService.app.admins.findBy('id', id.toString());
  }

  get columnWidthClasses() {
    return this.args.isBeingRenderedAsAChart ? 'max-w-48' : 'min-w-48 max-w-96';
  }

  @action
  showConversationPreview(conversationId: number, event: PointerEvent) {
    if (event.metaKey || event.ctrlKey) {
      // we want the default behavior to happen if the user is trying to open in a new tab
      return;
    }
    taskFor(this.args.streamResourceManager.showPreviewSideBar).perform(
      conversationId,
      Conversation,
      this.conversationIds,
    );
    // prevent the click event from bubbling up to anchor tag
    event.preventDefault();
  }

  @action
  openConversationInInbox(conversationId: number) {
    let url = this.router.urlFor(
      'inbox.workspace.inbox.conversation.conversation',
      this.app,
      conversationId,
    );
    safeWindowOpen(url, '_blank');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::DataTable': typeof DataTable;
  }
}
