/* import __COLOCATED_TEMPLATE__ from './chart-options-tab.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import type Chart from 'embercom/models/reporting/custom/chart';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import {
  SEGMENT_BY,
  VIEW_BY,
  SHOW_TIME_COMPARISON,
  type ToggleableVisualizationTraitNames,
  type VisualizationTraits,
  UNLIMITED_RESULTS,
  TERMS_GROUPING_MULTIMETRIC,
} from 'embercom/models/reporting/custom/visualization-options';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import type Report from 'embercom/models/reporting/custom/report';
import { isPresent } from '@ember/utils';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';
import type Metric from 'embercom/objects/reporting/unified/metrics/types';
import { indexBy, flatten, intersection } from 'underscore';
import { type Attribute } from 'embercom/objects/reporting/unified/datasets/types';
import { BUCKET } from 'embercom/lib/reporting/flexible/constants';
import { uniqBy } from 'embercom/helpers/uniq-by';

interface Arguments {
  chart: Chart;
  renderableChart: RenderableChart;
  report: Report;
  toggleValue: (key: ToggleableVisualizationTraitNames) => void;
  values: VisualizationTraits;
  disableTimeComparison: boolean;
  sharedAnalyticsData: any;
  resetColumns: (chartSeries: ChartSeries) => void;
  isStandalone?: boolean;
}
interface Signature {
  Args: Arguments;
}

const DEFAULT_CUSTOM_SEGMENT_LIMIT = 400;

const UNLIMITED_CUSTOM_SEGMENT_LIMIT = 10000;

export default class ChartOptionsTab extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare reportingUnderlyingDataService: any;
  @service declare reportingMetrics: any;
  @service declare store: any;
  @service declare chartBuilderFilterService: any;
  @service declare appService: any;

  get renderableChart() {
    return this.args.renderableChart;
  }

  get showDateOverwritten() {
    return this.args.report.showDateOverwritten(this.args.chart.dateRange);
  }

  get suggestedProperties() {
    // Breakdowns don't yet use attribute ids, so we pass properties for now
    let metrics = this.args.chart.chartSeries.map((series: ChartSeries) => series.metric);
    let suggestedProps: string[] = [];
    // iterate over the chart metrics and return the common properties
    metrics.forEach((metric: Metric) => {
      let attributeIds = this.reportingUnderlyingDataService.getSuggestedAttributeIds(metric);
      attributeIds.forEach((attributeId: string) => {
        let attribute = this.reportingMetrics.getAttributeById(attributeId);
        if (isPresent(attribute) && !suggestedProps.includes(attribute.field)) {
          suggestedProps.push(attribute.field);
        }
      });
    });
    return suggestedProps;
  }

  get breakdownSupportedAttributes() {
    if (!this.args.chart.isMultimetric) {
      return this.filterBreakdownForCurrentVizualizationType(
        this.args.chart.chartSeries.firstObject.supportedBreakdownAttributes,
      );
    }
    let supportedAttributes: Attribute[] = [this.reportingMetrics.timeAttribute];

    if (this.args.chart.supportsFeature(TERMS_GROUPING_MULTIMETRIC)) {
      let availableAttributes = this.args.chart.chartSeries.map(
        (series: ChartSeries) => series.supportedBreakdownAttributes,
      );

      let ids = availableAttributes.map((attributes: Attribute[]) => attributes.mapBy('id'));
      let indexAttributes = indexBy(flatten(availableAttributes), 'id');
      let commonAttributes = intersection(...ids).map((id: string) => indexAttributes[id]);
      supportedAttributes.push(...commonAttributes);
    }

    if (this.args.chart.supportsTeammateAttribute) {
      supportedAttributes.push(this.reportingMetrics.teammateAttribute);
    }
    if (this.args.chart.supportsTeamAttribute) {
      supportedAttributes.push(this.reportingMetrics.teamAttribute);
    }
    let uniqueAttributes = uniqBy(supportedAttributes, (attribute: Attribute) => attribute.id);
    return this.filterBreakdownForCurrentVizualizationType(uniqueAttributes);
  }

  filterBreakdownForCurrentVizualizationType(attributes: Attribute[]) {
    if (!this.args.chart.supportsFeature(BUCKET)) {
      return attributes.reject((attribute: Attribute) => attribute.field === BUCKET);
    }
    return attributes;
  }

  get breakdownSupportedAttributesForSegmentBy() {
    return this.breakdownSupportedAttributes.reject(
      (attribute: Attribute) => attribute.id === BUCKET,
    );
  }

  get suggestedPropertiesForSegmentBy() {
    return this.suggestedProperties.reject((property: string) => property === BUCKET);
  }

  get conversationAttributeDescriptors() {
    return this.store.peekAll('conversation-attributes/descriptor');
  }

  get chartRange() {
    return this.args.chart.getRangeWithTimeZone(this.timezone);
  }

  get reportTimezone() {
    if (isPresent(this.args.report?.timezone)) {
      return this.args.report.timezone;
    }
    return undefined;
  }

  get timezone() {
    return this.reportTimezone || this.appService.app.timezone;
  }

  get supportTimeComparison() {
    return this.args.chart.supportsFeature(SHOW_TIME_COMPARISON);
  }

  get supportViewBy() {
    return this.args.chart.supportsFeature(VIEW_BY);
  }

  get supportSegmentBy() {
    // don't check for multimetrics here as we want to always show this component
    // it'll be disabled in case of multimetrics
    return this.args.chart.supportsFeature(SEGMENT_BY, false);
  }

  get disableSegmentBy() {
    return !this.args.chart.supportsFeature(SEGMENT_BY) || this.args.chart.isBrokenDownByBucket;
  }

  get customSegmentLimit() {
    return this.args.chart.supportsFeature(UNLIMITED_RESULTS) && !this.args.chart.segmentBy
      ? UNLIMITED_CUSTOM_SEGMENT_LIMIT
      : DEFAULT_CUSTOM_SEGMENT_LIMIT;
  }

  @action
  trackAnalyticsEvent(data: any) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.args.sharedAnalyticsData,
      ...data,
    });
  }

  @action
  updateViewBy(value: string) {
    this.trackAnalyticsEvent({
      action: 'edited_break_down_by',
      group: value,
    });
    this.args.chart.updateViewBy(value);
  }

  @action
  updateViewByTimeInterval(value: string) {
    this.trackAnalyticsEvent({
      action: 'edited_break_down_by_time_interval',
      break_down_by_time_interval: value,
    });
    this.args.chart.viewByTimeInterval = value;
  }

  @action
  updateViewByDisplayLimit(value: number) {
    this.trackAnalyticsEvent({
      action: 'edited_break_down_by_display_limit',
      break_down_by_display_limit: value,
    });
    this.args.chart.viewByDisplayLimit = value;
  }

  @action
  updateSegmentBy(value: string) {
    this.trackAnalyticsEvent({
      action: 'edited_group_by',
      group: value,
    });

    if (this.args.chart.viewByDisplayLimit > DEFAULT_CUSTOM_SEGMENT_LIMIT) {
      this.updateViewByDisplayLimit(DEFAULT_CUSTOM_SEGMENT_LIMIT);
    }
    this.args.chart.updateSegmentBy(value);
  }

  @action
  updateSegmentByTimeInterval(value: string) {
    this.trackAnalyticsEvent({
      action: 'edited_segment_by_time_interval',
      segment_by_time_interval: value,
    });
    this.args.chart.segmentByTimeInterval = value;
  }

  @action
  updateSegmentByDisplayLimit(value: number) {
    this.trackAnalyticsEvent({
      action: 'edited_group_by_display_limit',
      group_by_display_limit: value,
    });
    this.args.chart.segmentByDisplayLimit = value;
  }

  @action
  updateSegmentByDisplayOther(value: boolean) {
    this.trackAnalyticsEvent({
      action: 'edited_group_by_display_other',
      value,
    });
    this.args.chart.segmentByDisplayOther = value;
  }

  @action
  onFiltersChanged(
    index: number,
    attribute: any,
    values: any[],
    operator: string,
    chartSeries: ChartSeries,
  ) {
    let { report, chart } = this.args;
    this.trackAnalyticsEvent({
      action: 'filtered',
      filter_name: attribute.id,
      object: 'custom_chart',
      custom_chart_id: this.args.chart.id,
    });
    let updatedChartSeries = chartSeries || chart.chartSeries.firstObject;
    this.chartBuilderFilterService.changeFilter(report, chart, updatedChartSeries, {
      index,
      attribute,
      values,
      operator,
    });
  }

  @action
  onFiltersCleared(chartSeries: ChartSeries) {
    let { report, chart } = this.args;
    let updatedChartSeries = chartSeries || chart.chartSeries.firstObject;
    this.chartBuilderFilterService.clearFilter(report, chart, updatedChartSeries);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::ChartOptionsTab': typeof ChartOptionsTab;
    'reporting/custom/chart-builder/chart-options-tab': typeof ChartOptionsTab;
  }
}
