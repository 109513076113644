/* import __COLOCATED_TEMPLATE__ from './mapped-attribute-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import { dropTask } from 'ember-concurrency-decorators';
import type { SelectedFilter } from 'embercom/services/filter-pattern-service';
import { type Attribute as DatasetAttribute } from 'embercom/objects/reporting/unified/datasets/types';
import { taskFor } from 'ember-concurrency-ts';
import type ReportingUnderlyingDataService from 'embercom/services/reporting-underlying-data-service';
import { type AttributeMapping } from 'embercom/services/reporting-underlying-data-service';
import { type FilterBarItem } from 'embercom/services/filter-pattern-service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  selected: SelectedFilter;
  analyticsObject: string;
  setSelected: (filterValues: string[], operator?: string) => void;
  removeFilter: () => void;
  initiallyOpened: boolean;
  icon: string;
  type: string;
  name: string;
  isDisabled: boolean;
  attribute: DatasetAttribute;
}

export default class MappedAttributeFilter extends Component<Args> {
  @service declare intercomEventService: $TSFixMe;
  @service declare reportingUnderlyingDataService: ReportingUnderlyingDataService;
  @service declare intl: IntlService;

  @tracked operator = this.args.selected.operator || 'category';
  @tracked selectedDropdownItems = this.args.selected.values || [];
  @tracked stagedValuesForLabel = this.selectedValues;
  @tracked allItems: FilterBarItem[] = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.loadMappingsTask.perform();
  }

  loadMappingsTask = taskFor(this.loadSelectedValuesMappings);

  // Displaying the current label requires first loading the mappings
  // and then mapping the values
  get currentLabel() {
    if (this.stagedValuesForLabel.length === 0) {
      return this.intl.t('components.reporting.custom.chart-builder.filter-bar.any');
    } else if (this.loadMappingsTask.isRunning) {
      return '...';
    } else {
      return this.stagedValuesForLabel
        .map((value) =>
          this.extractTitle(
            this.reportingUnderlyingDataService.mappedValueFor(value, this.args.attribute.id),
          ),
        )
        .join(', ');
    }
  }

  get selectedValues() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  @dropTask
  *loadSelectedValuesMappings() {
    yield taskFor(this.reportingUnderlyingDataService.mapAttributeValues).perform(
      this.args.attribute.id,
      this.selectedValues,
    );
  }

  @dropTask
  *filterKnownValues(searchTerm: string) {
    let result = (yield taskFor(
      this.reportingUnderlyingDataService.searchAttributeMappings,
    ).perform(this.args.attribute.id, searchTerm)) as AttributeMapping;

    // We need to include the mappings for the selected values as they will also
    // appear in the dropdown when selecting new values
    let selectedValueMappings: AttributeMapping = this.selectedValues.reduce((acc, value) => {
      return {
        ...acc,
        [value]: this.reportingUnderlyingDataService.mappedValueFor(value, this.args.attribute.id),
      };
    }, {});
    result = { ...selectedValueMappings, ...result };

    this.allItems = Object.entries(result).map(([key, value]) => ({
      text: this.extractTitle(value),
      value: key,
      isSelected: false,
    }));
  }

  extractTitle(value: any) {
    switch (this.args.attribute.dataType) {
      case 'knowledge_hub_content_reference':
        return value.title;
      default:
        return value;
    }
  }

  @action
  trackAnalytics() {
    let safeAttributeId = this.args.attribute.id.replace(/[^\w\d]/g, '_');
    this.intercomEventService.trackAnalyticsEvent({
      action: `filtered_${safeAttributeId}`,
      object: this.args.analyticsObject,
      operator: this.operator,
    });
  }

  @action
  onClose() {
    if (this.isDestroying) {
      // for some reason onClose gets triggered when you remove the filter
      // even if the dropdown isn't currently open
      return;
    }

    this.stagedValuesForLabel = this.selectedValues;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Flexible::FilterBar::MappedAttributeFilter': typeof MappedAttributeFilter;
    'reporting/flexible/filter-bar/mapped-attribute-filter': typeof MappedAttributeFilter;
  }
}
