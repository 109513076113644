/* import __COLOCATED_TEMPLATE__ from './chart-card.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
// eslint-disable-next-line @intercom/intercom/max-file-length
import Component from '@glimmer/component';
import type Chart from 'embercom/models/reporting/custom/chart';
import RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { humanizeFilters } from 'embercom/lib/reporting/custom/filter-helpers';
import { getOwner } from '@ember/application';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import { isEmpty, isPresent } from '@ember/utils';
import { TEAM_OPTIONS } from 'embercom/lib/reporting/custom/data-config-builder-helpers';
import type CustomIntlService from 'embercom/services/intl';
import { type Filter } from '../filters';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';
import type Metric from 'embercom/objects/reporting/unified/metrics/types';
import type Report from 'embercom/models/reporting/custom/report';
import { taskFor } from 'ember-concurrency-ts';
import type ConversationTopicModel from 'embercom/models/conversational-insights/conversation-topic';
import type ReportingUnderlyingDataService from 'embercom/services/reporting-underlying-data-service';
import type ChartBuilderFilterService from 'embercom/services/chart-builder-filter-service';
import type CustomReportsService from 'embercom/services/custom-reports-service';
import type ReportingTemplates from 'embercom/services/reporting-templates';
import { type Modifier } from '@popperjs/core';

export interface ReportState {
  settings?: any;
  dateRange: any;
  filters: any;
  timezone: any;
  isTemplate?: boolean;
  isStatic?: boolean;
}

export interface AnalyticsData {
  action: string;
  object: string;
  chart_metric_id?: string;
  currentFilters?: Filter[];
  chart_id?: string;
}

interface Args {
  chart: Chart;
  reportState: ReportState;
  report: Report;
  deleteChart: (chartId: string) => void;
  editChart: (chartId: string) => void;
  duplicateChart: (chartId: string, description: string) => void;
  trackAnalyticsEvent: (options: AnalyticsData) => void;
  updateShowPropagationFinishedBanner: () => void;
  editMode: boolean;
  paywall?: any;
  actionsAreDisabled?: boolean;
  isStandalone?: boolean;
  extraContext?: Record<string, any>;
  noChrome?: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: any;
}

export default class ChartCard extends Component<Signature> {
  @service store: any;
  @service realTimeEventService: any;
  @service appService: any;
  @service permissionsService: any;
  @service intl!: CustomIntlService;
  @service declare chartBuilderFilterService: ChartBuilderFilterService;
  @service declare reportingUnderlyingDataService: ReportingUnderlyingDataService;
  @service declare customReportsService: CustomReportsService;
  @service declare reportingTemplates: ReportingTemplates;

  @tracked isHovered = false;
  @tracked hideChart = false;
  @tracked topics: ConversationTopicModel[] = [];
  @tracked actionDropdownIsOpened = false;
  @tracked showSideDrawer = false;
  @tracked showUnderlyingData = false;
  @tracked selection: string[] = [];
  @tracked previousSelection: string[] = [];
  @tracked drillInChartSeries: ChartSeries = this.args.chart.chartSeries.firstObject;
  @tracked editMode = false;
  @tracked exportDataTable: (() => void) | undefined;
  @tracked dataTableTotalCount = 0;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    taskFor(this.loadTopics).perform();
    this.realTimeEventService.on('ConversationTopicPropagatedStatusChanged', this, (event: any) => {
      this.handleTopicPropagationStatusChange(event.topicId);
    });
  }

  handleTopicPropagationStatusChange(topicId: string) {
    let wasPropagating = this.hasPropagatingTopic;
    this.topics.forEach(async (topic: any) => {
      if (topic.id === topicId) {
        await topic.reload();
        if (wasPropagating === true && !this.hasPropagatingTopic) {
          this.args.updateShowPropagationFinishedBanner();
        }
      }
    });
  }

  @action
  openActionDropdown() {
    this.actionDropdownIsOpened = true;
    this.args.trackAnalyticsEvent({
      action: 'opened_action_dropdown',
      object: 'custom_chart',
    });
  }

  @action
  closeActionDropdown() {
    this.actionDropdownIsOpened = false;
  }

  @task({ restartable: true })
  *loadTopics(): any {
    if (isPresent(this.topicFilters)) {
      this.topics = yield this.store.findAll('conversational-insights/conversation-topic', {
        reload: false,
      });
    }
  }

  get renderableChart() {
    return new RenderableChart(this.args.chart, this.args.reportState, getOwner(this));
  }

  get availableTeammates() {
    return this.appService.app.humanAdmins;
  }

  get hasFilters() {
    return (
      this.teammateFilter ||
      this.teamFilter ||
      this.memberOfFilter ||
      this.hasReportFiltersExceptTeammateOrTeam ||
      this.hasChartFilters
    );
  }

  get hasReportFiltersExceptTeammateOrTeam() {
    return isPresent(this.humanizedReportFilters);
  }

  get reportFiltersExceptTeammateOrTeam() {
    let filters = this.args.reportState.filters?.filters?.reject(
      (filter: Filter) => filter.data.property === 'teammate' || filter.data.property === 'team',
    );

    return { ...this.args.reportState.filters, filters };
  }

  get hasChartFilters() {
    return this.args.chart.chartSeries.toArray().some((chartSeries: ChartSeries) => {
      return chartSeries.filters.filters.length > 0;
    });
  }

  get teammateFilter() {
    return this.args.reportState.filters?.filters?.find((filter: Filter) => {
      return filter.data.property === 'teammate';
    });
  }

  get teamFilter() {
    return this.args.reportState.filters?.filters?.find((filter: Filter) => {
      return filter.data.property === 'team';
    });
  }

  get memberOfFilter() {
    return this.args.reportState.filters?.filters?.find((filter: Filter) => {
      return TEAM_OPTIONS.includes(filter.type);
    });
  }

  get canChangeCustomReports() {
    return this.customReportsService.canChangeCustomReports;
  }

  get shouldShowActions() {
    // If the chart is paywalled & we're not in edit mode, there are no actions available
    if (this.isPaywalled && !this.args.editMode) {
      return false;
    }

    return this.isHovered || this.actionDropdownIsOpened;
  }

  get titleHovered() {
    return this.isHovered || this.actionDropdownIsOpened;
  }

  get isPaywalled() {
    return (
      (this.args.paywall?.isActive && isEmpty(this.args.chart.templateId)) ||
      (isPresent(this.args.chart) &&
        isPresent(this.args.chart.templateId) &&
        !this.reportingTemplates.canUseChartTemplate(this.args.chart.templateId))
    );
  }

  // TODO: Clean this up when cleaning up reporting-r2-beta FF
  get hidePaywallIcon() {
    return this.args.chart.isSmall || this.args.chart.isXSmall;
  }

  get hideEmptyIcon() {
    return this.args.chart.gridHeight <= 2;
  }

  get hideEmptyDescription() {
    return this.args.chart.gridWidth <= 2 || this.args.chart.gridHeight <= 2;
  }

  findFilter(property: string) {
    return this.args.reportState.filters?.filters?.find((filter: Filter) => {
      return filter.data.property === property;
    });
  }

  get humanizedChartFilters() {
    // TODO update this to work with multiple series
    let filters = this.args.chart.chartSeries.firstObject.filters;
    return this.intl.formatList(humanizeFilters(filters, this.renderableChart), {
      type: 'unit',
    });
  }

  get humanizedReportFilters() {
    return this.intl.formatList(
      humanizeFilters(this.reportFiltersExceptTeammateOrTeam, this.renderableChart),
      {
        type: 'unit',
      },
    );
  }

  get humanizedTeammateFilter() {
    let names = this.teammateFilter?.data?.values?.map((teammateId: string) => {
      if (teammateId === REPORTING_FILTER_SELECT_ALL) {
        return this.intl.t('reporting.custom-reports.any-teammate');
      }

      return (
        this.availableTeammates.find((teammate: any) => teammate.id.toString() === teammateId)
          ?.display_as_assignee || this.intl.t('reporting.custom-reports.former-teammate')
      );
    });

    let formattedNames = this.intl.formatList(names, { type: 'unit' });

    return this.intl.t('reporting.custom-reports.report-level-teammate-tooltip', {
      teammateNames: formattedNames,
      filterName: this.renderableChart.displayNameForTeammateProperty,
    });
  }

  get humanizeTeammateMemberOfFilter() {
    let names = this.memberOfFilter?.data?.values?.map((teamId: string) => {
      let teams = [...this.appService.app.teams, this.appService.app.unassignedAdmin];

      return (
        teams.find((team) => team.id.toString() === teamId)?.display_as_assignee ||
        this.intl.t('reporting.custom-reports.former-team')
      );
    });

    let formattedNames = this.intl.formatList(names, { type: 'unit' });

    return this.intl.t('reporting.custom-reports.report-level-teammate-tooltip', {
      teammateNames: formattedNames,
      filterName: this.renderableChart.displayNameForTeammateProperty,
    });
  }

  get humanizedTeamFilter() {
    let names = this.teamFilter?.data?.values?.map((teamId: string) => {
      if (teamId === REPORTING_FILTER_SELECT_ALL) {
        return this.intl.t('reporting.custom-reports.any-team');
      }

      let teams = [...this.appService.app.teams, this.appService.app.unassignedAdmin];

      return (
        teams.find((team) => team.id.toString() === teamId)?.display_as_assignee ||
        this.intl.t('reporting.custom-reports.former-team')
      );
    });

    let formattedNames = this.intl.formatList(names, { type: 'unit' });

    let displayName = this.renderableChart.displayNameForTeamProperty;
    if (!displayName) {
      return undefined;
    }

    return this.intl.t('reporting.custom-reports.report-level-team-tooltip', {
      teamNames: formattedNames,
      filterName: this.renderableChart.displayNameForTeamProperty,
    });
  }

  get showTeamOrTeammateFilter() {
    return this.teamFilter || this.teammateFilter || this.memberOfFilter;
  }

  get humanizedDynamicFilter() {
    if (this.memberOfFilter) {
      return this.humanizeTeammateMemberOfFilter;
    }
    if (this.teammateFilter) {
      return this.humanizedTeammateFilter;
    }
    if (this.teamFilter) {
      return this.humanizedTeamFilter;
    }

    return undefined;
  }

  get hasPropagatingTopic() {
    if (isPresent(this.topicFilters)) {
      if (taskFor(this.loadTopics).isRunning) {
        return true;
      }
      let topicIds = this.topicFilters.flatMap((topicFilter: any) => topicFilter.data.values);
      if (topicIds.includes('any')) {
        return false;
      }
      return topicIds.some((selectedTopicId: string) => {
        let topic = this.topics.find((topic) => topic.id === selectedTopicId);
        if (topic) {
          return this.range?.comparisonStartMoment.isBefore(topic.fullyPropagatedFrom);
        } else {
          return false;
        }
      });
    }
    return false;
  }

  get topicFilters() {
    return this.args.chart.chartSeries
      .toArray()
      .flatMap((chartSeries: ChartSeries) => chartSeries.filters.filters)
      .filter((filter: any) => filter.data.property === 'topic');
  }

  get range() {
    return this.args.reportState.dateRange || this.args.chart.range;
  }

  get dateRangePrefix() {
    return this.args.reportState.dateRange
      ? this.intl.t('reporting.custom-reports.report-date-range')
      : this.intl.t('reporting.custom-reports.chart-date-range');
  }

  @action
  openSideDrawer() {
    // TODO update this to work with multiple series
    let filters = this.args.chart.chartSeries.firstObject.filters;
    this.args.trackAnalyticsEvent({
      action: 'opened',
      object: 'conversations_list',
      currentFilters: filters,
    });
    this.showSideDrawer = true;
  }

  @action
  openUnderlyingDataModal(selectedChartSeries?: ChartSeries) {
    let chartSeries: ChartSeries = selectedChartSeries ?? this.args.chart.chartSeries.firstObject;
    if (this.canAccessDrillIn) {
      this.args.trackAnalyticsEvent({
        action: 'opened',
        object: 'underlying_data_modal',
        chart_metric_id: chartSeries.metric.id,
        chart_id: this.args.chart.id,
      });
      this.setDefaultColumns(chartSeries);
      this.drillInChartSeries = chartSeries;
      this.showUnderlyingData = true;
    } else {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_reporting__drillin__access',
      );
    }
  }

  get canAccessDrillIn() {
    return this.permissionsService.currentAdminCan('can_reporting__drillin__access');
  }

  get isMultiMetricChart() {
    return this.renderableChart.chartSeries.length > 1;
  }

  get canViewCustomReports() {
    if (this.appService.app.canShareReportsInternally) {
      return this.permissionsService.currentAdminCan('can_access_reporting');
    }
    return this.permissionsService.currentAdminCan('can_reporting__custom_reports__read');
  }

  get reportPlace(): string {
    if (this.args.report) {
      if (isNaN(Number(this.args.report.id))) {
        return this.args.report.id;
      }
      return 'custom_report';
    }

    return 'custom_chart';
  }

  get knownValuesEndpointSources() {
    let metrics = this.args.chart.chartSeries.map((series: ChartSeries) => series.metric);
    return metrics.map((metric: Metric) => metric.firstSource);
  }

  get conversationAttributeDescriptors() {
    return this.store.peekAll('conversation-attributes/descriptor');
  }

  get chartRange() {
    return this.appService.app.canSeeTimezoneFilterInCustomReport
      ? this.args.chart.getRangeWithTimeZone(this.timezone)
      : this.args.chart.range;
  }

  get reportTimezone() {
    if (
      this.appService.app.canSeeTimezoneFilterInCustomReport &&
      isPresent(this.args.report?.timezone)
    ) {
      return this.args.report.timezone;
    }
    return undefined;
  }

  get timezone() {
    return this.reportTimezone || this.appService.app.timezone;
  }

  get reportTeamOfficeHoursWarning() {
    if (this.args.reportState.dateRange) {
      return (
        this.args.chart.chartSeries.firstObject?.metric.metricVariantType === 'team_office_hours' &&
        this.args.reportState.dateRange.startMoment.isBefore('2023-07-05T00:00:00Z')
      );
    } else {
      return this.args.chart.usesOfficeHoursVariantMetric;
    }
  }

  get columns() {
    return this.selection;
  }

  get metric(): Metric {
    return this.args.chart.chartSeries.firstObject.metric;
  }

  defaultColumns(chartSeries: ChartSeries) {
    return this.reportingUnderlyingDataService.getDefaultColumns(
      chartSeries,
      this.renderableChart,
      this.args.isStandalone,
    );
  }

  @action
  setDefaultColumns(chartSeries: ChartSeries) {
    let defaultSelection = [];
    let cachedSelection: string[] = [];

    if (this.args.chart.id) {
      cachedSelection = this.reportingUnderlyingDataService.loadSelectedColumnFromCache(
        this.args.chart.id,
        chartSeries,
      );
    }

    if (isPresent(cachedSelection)) {
      defaultSelection = cachedSelection;
    } else {
      defaultSelection = this.defaultColumns(chartSeries);
    }
    this.selection = defaultSelection.uniq().compact();
    this.previousSelection = this.selection;
  }

  @action
  setColumns(chartSeries: ChartSeries, columns: string[]) {
    this.selection = this.reportingUnderlyingDataService.getOrderedAttributeIds(
      chartSeries.metric,
      columns,
      this.previousSelection,
    );

    if (this.args.chart.id) {
      this.reportingUnderlyingDataService.setColumnSelectionInCache(
        this.args.chart.id,
        chartSeries,
        this.selection,
      );
    }

    this.previousSelection = this.selection;
  }

  @action
  onCloseUnderlyingDataModal() {
    this.showUnderlyingData = false;
    this.args.trackAnalyticsEvent({
      action: 'closed',
      object: 'underlying_data_modal',
    });
  }

  @action
  setDataTableExportFunction(exportFunction: () => void) {
    this.exportDataTable = exportFunction;
  }

  @action
  setDataTableTotalCount(count: number) {
    this.dataTableTotalCount = count;
  }

  get showMoveCursor() {
    return this.args.editMode && this.isHovered;
  }

  get chartHeight() {
    let otherChartCardHeightTotal = 90;
    /*
      this is only used for tables, all other visualizations, the height is set to null
      delegating the height setting to HighCharts.
      Tables need a max height to enable scrolling within the card.
    */
    let height = this.args.chart.gridHeight * 80 - otherChartCardHeightTotal;
    return this.args.chart.visualizationType === 'tabular' ? height : null;
  }

  get setZIndex(): Modifier<string, any> {
    return {
      name: 'z-index',
      enabled: true,
      phase: 'write',
      fn({ state }: any) {
        state.styles.popper.zIndex = 49;
      },
    };
  }

  get dataTableExportDisabled() {
    return (
      this.dataTableTotalCount === 0 ||
      !this.canAccessDrillIn ||
      this.dataTableTotalCount > 10_000_000
    );
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ChartCard': typeof ChartCard;
    'reporting/custom/report/chart-card': typeof ChartCard;
  }
}
