/* import __COLOCATED_TEMPLATE__ from './conversation-list.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
import Component from '@glimmer/component';
import type { ConversationExploreConversation } from './conversation-explore-panel';
import { tracked } from '@glimmer/tracking';
interface Args {
  conversationExploreConversations: ConversationExploreConversation[];
  onConversationSelected: (conversationId: number) => void;
  selectedConversationId: number;
  hasMoreConversations: boolean;
  loadMoreConversations: () => void;
  totalHits: number;
}

export default class ConversationList extends Component<Args> {
  @tracked isHovered = false;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::ConversationList': typeof ConversationList;
  }
}
