/* RESPONSIBLE TEAM: team-ai-insights */
import type IntlService from 'ember-intl/services/intl';
import { type Filter, type LogicalFilter } from 'embercom/components/reporting/custom/filters';
// @ts-ignore no type declaration available for ember-copy
import { copy } from 'ember-copy';

export function and(...filters: any): LogicalFilter {
  filters = filters.compact();
  if (filters.length === 1) {
    return filters[0];
  }

  return {
    type: 'and',
    filters: filters.flatMap((f: any) => (f.type === 'and' ? f.filters : f)),
  };
}

export function or(...filters: Filter[]): Filter | LogicalFilter {
  let properties = filters.map((f: Filter) => f.data.property).uniq();
  let allAreCategoryFilters = filters.every((f: Filter) => f.type === 'category');
  if (properties.length === 1 && allAreCategoryFilters) {
    // merge the filters into a single filter
    return {
      type: 'category',
      data: {
        property: filters[0].data.property,
        attribute: filters[0].data.attribute,
        values: filters.flatMap((f: Filter) => f.data.values),
      },
    };
  } else {
    return {
      type: 'or',
      filters: filters.flatMap((f: any) => (f.type === 'or' ? f.filters : f)),
    };
  }
}

export function not(filter: any): Filter {
  filter = copy(filter, true);
  if (filter.type === 'category') {
    // The "Fin AI Agent involved" data attribute uses a filter type that doesn't
    // support "not_in_category", so if it's a Boolean category, we can just negate
    let values = filter.data.values;
    if (values.length === 1 && typeof values.firstObject === 'boolean') {
      filter.data.values = [!values.firstObject];
    } else {
      filter.type = 'not_in_category';
    }
  } else if (filter.type === 'exists') {
    filter.type = 'not_exists';
  } else {
    throw new Error(`Unsupported filter type: ${filter.type}`);
  }
  return filter;
}

const filters = {
  get queryTypeInformational() {
    return {
      type: 'category',
      data: {
        property: 'conversation_custom_fields#ai_insights_query_type',
        attribute: 'conversation_custom_fields#ai_insights_query_type',
        values: ['informational'],
      },
    };
  },

  get queryTypePersonalized() {
    return {
      type: 'category',
      data: {
        property: 'conversation_custom_fields#ai_insights_query_type',
        attribute: 'conversation_custom_fields#ai_insights_query_type',
        values: ['personalized'],
      },
    };
  },

  get queryTypeActions() {
    return {
      type: 'category',
      data: {
        property: 'conversation_custom_fields#ai_insights_query_type',
        attribute: 'conversation_custom_fields#ai_insights_query_type',
        values: ['actions'],
      },
    };
  },
  get queryTypeComplex() {
    return {
      type: 'category',
      data: {
        property: 'conversation_custom_fields#ai_insights_query_type',
        attribute: 'conversation_custom_fields#ai_insights_query_type',
        values: ['complex'],
      },
    };
  },

  // Main filter for resolved conversations - use this instead of individual resolution state filters
  get finResolved() {
    return {
      type: 'category',
      data: {
        property: 'conversation_custom_fields#ai_chatbot_resolution_state',
        attribute: 'conversation_custom_fields#ai_chatbot_resolution_state',
        values: ['hard_resolution', 'soft_resolution'],
      },
    };
  },

  get finRoutedToTeam() {
    return {
      type: 'category',
      data: {
        property: 'conversation_custom_fields#ai_chatbot_resolution_state',
        attribute: 'conversation_custom_fields#ai_chatbot_resolution_state',
        values: ['routed_to_team'],
      },
    };
  },
  get positiveCSAT() {
    return {
      type: 'category',
      data: {
        property: 'ai_generated_metrics.csat',
        attribute: 'conversation.ai_generated_metrics.csat',
        values: ['4', '5'],
      },
    };
  },
  get neutralCSAT() {
    return {
      type: 'category',
      data: {
        property: 'ai_generated_metrics.csat',
        attribute: 'conversation.ai_generated_metrics.csat',
        values: ['3'],
      },
    };
  },
  get negativeCSAT() {
    return {
      type: 'category',
      data: {
        property: 'ai_generated_metrics.csat',
        attribute: 'conversation.ai_generated_metrics.csat',
        values: ['1', '2'],
      },
    };
  },

  get finWasEverInvolved() {
    return {
      type: 'category',
      data: {
        property: 'fin.participated',
        attribute: 'conversation.fin.participated',
        values: [true],
      },
    };
  },

  get teammateWasEverInvolved() {
    return {
      type: 'exists',
      data: {
        property: 'admin_participant_ids',
        attribute: 'conversation.admin_participant_ids',
      },
    };
  },

  get finAnswered() {
    return {
      type: 'exists',
      data: {
        property: 'fin.last_sent_answer_type',
        attribute: 'conversation.fin.last_sent_answer_type',
      },
    };
  },

  get finResolutionStateExists() {
    return {
      type: 'exists',
      data: {
        property: 'conversation_custom_fields#ai_chatbot_resolution_state',
        attribute: 'conversation.conversation_custom_fields#ai_chatbot_resolution_state',
      },
    };
  },

  get teammateWasOnlyInvolved() {
    return and(not(this.finWasEverInvolved), this.teammateWasEverInvolved);
  },
};

export function wrap(filters: any) {
  // because of assumptions made elsewhere in the code, it's easier to
  // just make sure there's always a top level logical filter
  if (filters && filters.type !== 'and' && filters.type !== 'or') {
    return {
      type: 'and',
      filters: [filters],
    };
  }
  return filters;
}

export function funnelSeries(filters?: any, label?: string) {
  return {
    label,
    metric_id: 'v1.new_conversations',
    filters: wrap(filters),
  };
}

function config(name: string, filters?: any) {
  return { name, series: funnelSeries(filters, name) };
}

interface Metrics {
  conversations: number;
  queryTypeInformational: number;
  informationalAndFinResolved: number;

  informationalAndFinRoutedToTeam: number;
  informationalAndTeammateOnly: number;
  queryTypePersonalized: number;
  personalizedAndFinResolved: number;

  personalizedAndFinRoutedToTeam: number;
  personalizedAndTeammateOnly: number;
  queryTypeActions: number;
  actionsAndFinResolved: number;

  actionsAndFinRoutedToTeam: number;
  actionsAndTeammateOnly: number;
  queryTypeComplex: number;
  complexAndFinResolved: number;
  complexAndFinRoutedToTeam: number;
  complexAndTeammateOnly: number;
  finResolved: number;
  finResolvedAndPositiveCSAT: number;
  finResolvedAndNegativeCSAT: number;
  finResolvedAndNeutralCSAT: number;

  finRoutedToTeam: number;
  finRoutedToTeamAndPositiveCSAT: number;
  finRoutedToTeamAndNegativeCSAT: number;
  finRoutedToTeamAndNeutralCSAT: number;
  teammateOnly: number;
  teammateOnlyAndPositiveCSAT: number;
  teammateOnlyAndNegativeCSAT: number;
  teammateOnlyAndNeutralCSAT: number;
  positiveCSAT: number;
  negativeCSAT: number;
  neutralCSAT: number;
}

export const FinInsightsSankeyConfig = {
  get filters() {
    return filters;
  },

  get chartSeriesFilters() {
    return [
      config('conversations'),
      // Query Types
      config('queryTypeInformational', this.filters.queryTypeInformational),
      config(
        'informationalAndFinResolved',
        and(this.filters.queryTypeInformational, this.filters.finResolved),
      ),
      config(
        'informationalAndFinRoutedToTeam',
        and(this.filters.queryTypeInformational, this.filters.finRoutedToTeam),
      ),
      config(
        'informationalAndTeammateOnly',
        and(this.filters.queryTypeInformational, this.filters.teammateWasOnlyInvolved),
      ),

      config('queryTypePersonalized', this.filters.queryTypePersonalized),
      config(
        'personalizedAndFinResolved',
        and(this.filters.queryTypePersonalized, this.filters.finResolved),
      ),

      config(
        'personalizedAndFinRoutedToTeam',
        and(this.filters.queryTypePersonalized, this.filters.finRoutedToTeam),
      ),
      config(
        'personalizedAndTeammateOnly',
        and(this.filters.queryTypePersonalized, this.filters.teammateWasOnlyInvolved),
      ),

      config('queryTypeActions', this.filters.queryTypeActions),
      config('actionsAndFinResolved', and(this.filters.queryTypeActions, this.filters.finResolved)),
      config(
        'actionsAndFinRoutedToTeam',
        and(this.filters.queryTypeActions, this.filters.finRoutedToTeam),
      ),
      config(
        'actionsAndTeammateOnly',
        and(this.filters.queryTypeActions, this.filters.teammateWasOnlyInvolved),
      ),

      config('queryTypeComplex', this.filters.queryTypeComplex),
      config('complexAndFinResolved', and(this.filters.queryTypeComplex, this.filters.finResolved)),
      config(
        'complexAndFinRoutedToTeam',
        and(this.filters.queryTypeComplex, this.filters.finRoutedToTeam),
      ),
      config(
        'complexAndTeammateOnly',
        and(this.filters.queryTypeComplex, this.filters.teammateWasOnlyInvolved),
      ),

      // resolution flows
      config('finResolved', this.filters.finResolved),
      config(
        'finResolvedAndPositiveCSAT',
        and(this.filters.finResolved, this.filters.positiveCSAT),
      ),
      config(
        'finResolvedAndNegativeCSAT',
        and(this.filters.finResolved, this.filters.negativeCSAT),
      ),
      config('finResolvedAndNeutralCSAT', and(this.filters.finResolved, this.filters.neutralCSAT)),

      config('finRoutedToTeam', this.filters.finRoutedToTeam),
      config(
        'finRoutedToTeamAndPositiveCSAT',
        and(this.filters.finRoutedToTeam, this.filters.positiveCSAT),
      ),
      config(
        'finRoutedToTeamAndNegativeCSAT',
        and(this.filters.finRoutedToTeam, this.filters.negativeCSAT),
      ),
      config(
        'finRoutedToTeamAndNeutralCSAT',
        and(this.filters.finRoutedToTeam, this.filters.neutralCSAT),
      ),

      config('teammateOnly', this.filters.teammateWasOnlyInvolved),
      config(
        'teammateOnlyAndPositiveCSAT',
        and(this.filters.teammateWasOnlyInvolved, this.filters.positiveCSAT),
      ),
      config(
        'teammateOnlyAndNegativeCSAT',
        and(this.filters.teammateWasOnlyInvolved, this.filters.negativeCSAT),
      ),
      config(
        'teammateOnlyAndNeutralCSAT',
        and(this.filters.teammateWasOnlyInvolved, this.filters.neutralCSAT),
      ),

      //CSAT flows
      config('positiveCSAT', this.filters.positiveCSAT),
      config('negativeCSAT', this.filters.negativeCSAT),
      config('neutralCSAT', this.filters.neutralCSAT),
    ];
  },
  get chartSeries() {
    return this.chartSeriesFilters.map((config) => config.series);
  },

  getChartSeriesWithLabels(intl: IntlService) {
    return this.chartSeriesFilters.map((config) => ({
      ...config.series,
      label: intl.t(`reporting.overview.charts.funnel.sankey.metrics.${config.name}`),
    }));
  },

  getMetricsFromChartDataRequest(chartData: any) {
    let result = {} as Metrics;
    for (let i = 0; i < chartData.length; i++) {
      let seriesName = this.chartSeriesFilters[i].name as keyof Metrics;
      let seriesData = chartData[i].data[0];
      result[seriesName] = seriesData;
    }
    return result;
  },
};
