/* import __COLOCATED_TEMPLATE__ from './static-report-section.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import type Report from 'embercom/models/reporting/custom/report';
import type Chart from 'embercom/models/reporting/custom/chart';
import { type SerializedChart } from './static-report';

interface Signature {
  Args: {
    charts: SerializedChart[];
    title?: string;
    // the following Args are private and are set by StaticReport
    report: Report;
    reportState: any;
    addChartsToReport: (charts: SerializedChart[]) => Chart[];
    extraContext?: Record<string, any>;
    noChrome?: boolean;
    actionsAreDisabled?: boolean;
  };
  Blocks: {
    title: any;
    cta: any;
    buttonBlock: any;
  };
}

export default class StaticReportSection extends Component<Signature> {
  charts: Chart[];

  constructor(owner: any, args: Signature['Args']) {
    super(owner, args);
    this.charts = this.args.addChartsToReport(this.args.charts);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::InContext::StaticReportSection': typeof StaticReportSection;
    'reporting/in-context/static-report-section': typeof StaticReportSection;
  }
}
