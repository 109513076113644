/* import __COLOCATED_TEMPLATE__ from './chart-template-card-settings.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { type Aggregation } from 'embercom/objects/reporting/unified/metrics/types';
import { INSIDE_OFFICE_HOURS, ALL_HOURS } from 'embercom/services/reporting-templates';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

type GroupItem = { text: string; value: string };
type Header = { text: string; icon: InterfaceIconName };
interface Args {
  selectedAggregation: Aggregation;
  onUpdateSettings: (value: any) => void;
  includeOfficeHoursVariant: boolean;
  selectedOfficeHours: string;
  supportedAggregations: GroupItem[];
}

export default class ChartTemplateCardSettings extends Component<Args> {
  @service declare intl: IntlService;

  get groupList() {
    let groups: { header: Header; items: GroupItem[] }[] = [];

    if (this.args.supportedAggregations.length > 1) {
      groups.pushObject({
        header: {
          text: this.intl.t('reporting.custom-reports.chart.aggregation'),
          icon: 'inapp-full' as InterfaceIconName,
        },
        items: this.args.supportedAggregations,
      });
    }

    if (this.args.includeOfficeHoursVariant) {
      groups.pushObject({
        header: {
          text: this.intl.t('reporting.custom-reports.chart.office-hours-variant.header'),
          icon: 'clock' as InterfaceIconName,
        },
        items: this.officeHours,
      });
    }
    return groups;
  }

  get aggregations() {
    return this.args.supportedAggregations;
  }

  get officeHours(): GroupItem[] {
    return [
      {
        text: this.intl.t(
          'reporting.custom-reports.chart.office-hours-variant.inside-office-hours',
        ),
        value: INSIDE_OFFICE_HOURS,
      },
      {
        text: this.intl.t('reporting.custom-reports.chart.office-hours-variant.all-hours'),
        value: ALL_HOURS,
      },
    ];
  }

  get openerLabel() {
    let aggregationLabel = this.aggregations.find(
      (a: any) => a.value === this.args.selectedAggregation,
    )?.text;
    if (!this.args.includeOfficeHoursVariant) {
      return aggregationLabel;
    }
    let officeHoursLabel = this.officeHours.find(
      (o: any) => o.value === this.args.selectedOfficeHours,
    )?.text;
    return `${aggregationLabel}, ${officeHoursLabel}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ChartTemplateCardSettings': typeof ChartTemplateCardSettings;
    'reporting/custom/report/chart-template-card-settings': typeof ChartTemplateCardSettings;
  }
}
