/* import __COLOCATED_TEMPLATE__ from './conversation-stream.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TaskInstance, type TaskGenerator } from 'ember-concurrency';
import { restartableTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { trackedTask } from 'ember-resources/util/ember-concurrency';
import type InboxApi from 'embercom/services/inbox-api';
import { action } from '@ember/object';
import type Conversation from 'embercom/objects/inbox/conversation';
interface Args {
  selectedConversationId: number;
  conversationIds: number[];
  onConversationSelected: (conversationId: number) => void;
  totalHits: number;
  loadMoreConversations: () => void;
  isStandalone: boolean;
}

type GroupList = [
  {
    items: GroupListItem[];
  },
];

type GroupListItem = {
  text: string;
  icon: string;
  onSelectItem: () => void;
  isSelected: boolean;
  component: string;
};

export default class ConversationStream extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare inboxApi: InboxApi;
  @tracked hideConversationEvents = false;
  fetchDataTask = taskFor(this.fetchConversation);
  conversation = trackedTask(this, this.fetchDataTask, () => [
    this.args.selectedConversationId,
  ]) as TaskInstance<Conversation | undefined>;

  private cachedConversations: Record<number, Conversation> = {};

  @restartableTask
  *fetchConversation(): TaskGenerator<Conversation | undefined> {
    let conversation: Conversation | undefined =
      this.cachedConversations[this.args.selectedConversationId];
    if (!conversation) {
      try {
        conversation = yield this.inboxApi.fetchConversation(this.args.selectedConversationId);
        if (conversation) {
          this.cachedConversations[this.args.selectedConversationId] = conversation;
        }
      } catch (error) {
        console.error('Error fetching conversation', error);
      }
    }
    return conversation;
  }

  get currentIndex() {
    return this.args.conversationIds.indexOf(this.args.selectedConversationId);
  }

  get app() {
    return this.appService.app;
  }

  @action
  onPreviousConversation() {
    this.args.onConversationSelected(this.args.conversationIds[this.currentIndex - 1]);
  }

  @action
  onNextConversation() {
    let nextIndex = this.currentIndex + 1;
    if (nextIndex >= this.args.conversationIds.length && nextIndex < this.args.totalHits) {
      this.args.loadMoreConversations();
    } else {
      this.args.onConversationSelected(this.args.conversationIds[nextIndex]);
    }
  }

  @action
  toggleHideConversationEvents() {
    this.hideConversationEvents = !this.hideConversationEvents;
  }

  get summary() {
    return this.intl.t('reporting.conversation-stream.summary', {
      currentStreamPage: this.intl.formatNumber(this.currentIndex + 1),
      totalStreams: this.intl.formatNumber(this.args.totalHits),
    });
  }

  get groupList(): GroupList {
    return [
      {
        items: [
          {
            text: this.intl.t('inbox.conversation-header.conversation-events'),
            icon: 'eye',
            onSelectItem: this.toggleHideConversationEvents,
            isSelected: !this.hideConversationEvents,
            component:
              'reporting/custom/report/conversation-stream/conversation-stream-options-dropdown-item',
          },
        ],
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::ConversationStream': typeof ConversationStream;
    'reporting/automation/ai-insights/conversation-stream': typeof ConversationStream;
  }
}
