/* import __COLOCATED_TEMPLATE__ from './dataset-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type { InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  selected: any;
  analyticsObject: string;
  setSelected: (templateId: string) => void;
}

const DESIRED_ORDER = [
  'conversation',
  'consolidated_conversation_part',
  'conversation_rating_sent',
  'conversation_state',
  'conversation_sla_status_log',
  'tickets',
  'ticket_time_in_state',
  'teammate_handling_conversation',
  'admin_status_change',
  'copilot_prompt_response_pair',
  'call',
  'call_team_stats',
  'call_teammate_stats',
];

export default class DatasetFilter extends Component<Args> {
  @service intercomEventService: any;
  @service intl: any;
  @service appService: any;
  @service declare reportingMetrics: ReportingMetrics;

  get label() {
    return this.selectionItems.find((obj) => obj.value === this.args.selected)?.text || '';
  }

  get icon() {
    return this.selectionItems.find((obj) => obj.value === this.args.selected)
      ?.icon as InterfaceIconName;
  }

  get selectionItems() {
    let datasets = this.reportingMetrics.datasets;
    return [
      ...datasets.map((dataset) => {
        return {
          text: dataset.name,
          value: dataset.id,
          icon: dataset.icon,
        };
      }),
    ].sort((a, b) => {
      let indexA = DESIRED_ORDER.indexOf(a.value);
      let indexB = DESIRED_ORDER.indexOf(b.value);
      return indexA - indexB;
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::FilterBar::DatasetFilter': typeof DatasetFilter;
  }
}
