/* import __COLOCATED_TEMPLATE__ from './metric-selector.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { cached } from 'tracked-toolbox';
import { inject as service } from '@ember/service';

export default class MetricSelector extends Component {
  @service appService;

  @cached
  get itemsWithComponentOverride() {
    return this.overrideItemComponents(this.args.items);
  }

  overrideItemComponents(items) {
    return items.map((item) => this.overrideItemComponent(item));
  }

  overrideItemComponent(item) {
    if (item.items) {
      return Object.assign(item, { items: this.overrideItemComponents(item.items) });
    }
    if (item.component) {
      return item;
    }
    return Object.assign(item, {
      component: 'reporting/custom/chart-builder/metric-picker/metric-item',
    });
  }

  get linkToArticle() {
    if (this.appService.app.isStandaloneApp) {
      return 'https://fin.ai/help/en/articles/10867126-reporting-metrics-attributes';
    }
    return 'https://www.intercom.com/help/en/articles/7022438-reporting-metrics-attributes';
  }

  get articleId() {
    if (this.appService.app.isStandaloneApp) {
      return 10867126;
    }
    return 7022438;
  }
}
