/* import __COLOCATED_TEMPLATE__ from './insights-curate-manage.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { filterableProperties } from 'embercom/lib/reporting/custom/filter-helpers';
import type RouterService from '@ember/routing/router-service';
// @ts-ignore no type declaration available for ember-copy
import { copy } from 'ember-copy';
import type CustomReport from 'embercom/models/reporting/custom/report';
import type CustomChart from 'embercom/models/reporting/custom/chart';
import { action } from '@ember/object';

interface Args {
  conversationAttributeDescriptors: ConversationAttributeDescriptor[];
}

export default class InsightsCurateManage extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare router: RouterService;

  mergeWithDefaults(charts: any[]) {
    return charts.map((chart: any) => this.mergeChartWithDefaults(chart));
  }

  mergeChartWithDefaults(chart: any) {
    let result = copy(chart, true);
    result.default_drill_in_attributes = [
      'conversation.id',
      'conversation_custom_fields#ai_insights_topic',
      'conversation_custom_fields#ai_insights_subtopic',
      'conversation_custom_fields#ai_insights_conversation_summary',
    ];
    result.visualization_options ||= {};
    result.visualization_options.allow_drill_in_from_chart = true;
    result.chart_series?.forEach((series: any) => {
      if (
        !series.filters ||
        (series.filters.type === 'and' && series.filters.filters.length === 0)
      ) {
        series.filters = { type: 'and', filters: [] };
        series.filters.filters = [
          {
            type: 'exists',
            data: {
              attribute: 'conversation_custom_fields#ai_insights_topic',
              property: 'conversation_custom_fields#ai_insights_topic',
            },
          },
          {
            type: 'exists',
            data: {
              attribute: 'conversation_custom_fields#ai_insights_subtopic',
              property: 'conversation_custom_fields#ai_insights_subtopic',
            },
          },
        ];
      }
    });
    return result;
  }

  @action
  onSelectedTopicChanged(reportModel: CustomReport, topic: string | null, subtopics: string[]) {
    let topicsTable = reportModel.charts.lastObject;
    this.applyChangesToTopicsTable(topicsTable, topic, subtopics);
  }

  // TODO translate all strings once they've been finalized
  /* eslint-disable @intercom/intercom/no-bare-strings */
  applyChangesToTopicsTable(
    topicsTable: CustomChart,
    selectedTopic: string | null,
    subtopicsOfSelectedTopic: string[],
  ) {
    let filters;
    if (selectedTopic) {
      topicsTable.title = 'Subtopics';
      topicsTable.viewBy = 'conversation_custom_fields#ai_insights_subtopic';
      filters = {
        type: 'and',
        filters: [
          {
            type: 'category',
            data: {
              attribute: 'conversation_custom_fields#ai_insights_topic',
              property: 'conversation_custom_fields#ai_insights_topic',
              values: [selectedTopic],
            },
          },
          {
            type: 'category',
            data: {
              attribute: 'conversation_custom_fields#ai_insights_subtopic',
              property: 'conversation_custom_fields#ai_insights_subtopic',
              values: subtopicsOfSelectedTopic,
            },
          },
        ],
      };
    } else {
      topicsTable.title = 'Topics';
      topicsTable.viewBy = 'conversation_custom_fields#ai_insights_topic';
      filters = { type: 'and', filters: [] };
    }
    for (let series of topicsTable.chartSeries.toArray()) {
      series.filters = filters;
    }
  }

  // TODO translate all strings once they've been finalized
  /* eslint-disable @intercom/intercom/no-bare-strings */
  get reportDefinition() {
    return {
      id: 'insights_report',
      title: 'Topics',
      description: null,
      charts: this.mergeWithDefaults([
        {
          id: 'insights.asking_about',
          title: null,
          show_default_chart_title: false,
          visualization_type: 'bespoke',
          component_name: 'ai_insights/curate/treemap',
          created_by_id: -1,
          updated_by_id: -1,
          date_range: {
            selection: 'past_4_weeks',
          },
          filters: {
            type: 'and',
            filters: [],
          },
          stacked: false,
          view_by: 'ai_topic_subtopic_pair',
          view_by_display_limit: 5000,
          view_by_display_other: false,
          segment_by_display_limit: 10,
          segment_by_display_other: false,
          chart_series: [
            {
              metric_id: 'v1.new_conversations',
              aggregation: 'count',
              show_data_labels: true,
              filters: {
                type: 'and',
                filters: [],
              },
            },
          ],
          visualization_options: {
            show_relative_values: false,
            show_summary_row: false,
            show_time_comparison: false,
          },
          grid_position_x: 0,
          grid_position_y: 0,
          grid_width: 12,
          grid_height: 9,
        },
        {
          id: 'insights.topics-table',
          title: 'Topics',
          visualization_type: 'tabular',
          stacked: false,
          view_by: 'conversation_custom_fields#ai_insights_topic',
          view_by_display_limit: 100,
          view_by_display_other: false,
          segment_by_display_limit: 7,
          segment_by_display_other: false,
          chart_series: [
            {
              metric_id: 'v1.new_conversations',
              aggregation: 'count',
              label: 'Volume',
            },
          ],
          visualization_options: {
            allow_drill_in_from_chart: true,
            show_relative_values: false,
            show_summary_row: false,
            show_time_comparison: false,
          },
          grid_position_x: 0,
          grid_position_y: 11,
          grid_width: 12,
          grid_height: 8,
        },
      ]),
      date_range: {
        selection: 'past_12_weeks',
        start: null,
        end: null,
      },
      filters: {
        type: 'and',
        filters: [],
      },
    };
  }

  get filterableProperties() {
    return [...filterableProperties(), 'teammate', 'team', 'conversation.replied.workflow_ids'];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::InsightsCurateManage': typeof InsightsCurateManage;
  }
}
