/* import __COLOCATED_TEMPLATE__ from './continent-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

export default class ContinentFilter extends Component {
  @service intercomEventService;
  @service intl;
  @service filterConfigsService;
  // store the selection here so that the UI doesn't update until the drop down is closed
  @tracked selectedDropdownItems = this.args.selected.values || [];
  @tracked operator = this.args.selected.operator || 'category';

  get availableContinents() {
    return this.filterConfigsService.availableContinents();
  }

  get selectedContinents() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get allItems() {
    return Object.entries(this.availableContinents).map(([value, text]) => ({
      text,
      value,
      icon: 'globe',
      isSelected: this.selectedDropdownItems.includes(value),
    }));
  }

  @action
  trackAnalytics() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_continent',
      object: this.args.analyticsObject,
      continent: this.selectedContinents,
      operator: this.operator,
    });
  }

  get currentLabel() {
    if (this.selectedContinents.length === 0) {
      return this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.continent-filter.any',
      );
    }

    let continentNames = this.selectedContinents.map((continentId) => {
      return this.availableContinents[continentId];
    });
    return continentNames.join(', ');
  }
}
