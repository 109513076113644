/* import __COLOCATED_TEMPLATE__ from './job-card.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
/* eslint-disable ember/no-empty-glimmer-component-classes */
import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Args: {
    isSelected: boolean;
    onClick: () => void;
    icon: InterfaceIconName;
    heading: string;
    subheading: string;
  };
  Element: HTMLElement;
}

export default class JobCard extends Component<Signature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::Monitor::JobCard': typeof JobCard;
    'reporting/automation/ai-insights/monitor/job-card': typeof JobCard;
  }
}
