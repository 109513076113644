/* import __COLOCATED_TEMPLATE__ from './banner-color-picker.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { type BorderColor, type Color } from 'embercom/models/reporting/custom/component';

interface Signature {
  Args: {
    onSelect: (bgClass: Color, border: BorderColor) => void;
    onDelete: () => void;
    color: string;
  };
}

export default class BannerColorPickerComponent extends Component<Signature> {
  @tracked selectedColor = this.args.color;

  get lastIndex() {
    return this.colors.length - 1;
  }

  get colors() {
    return [
      {
        border: 'border-neutral-border',
        bgClass: 'bg-transparent',
        hoverBorder: 'border-neutral-border-emphasis',
      },
      {
        border: 'border-beta-container-emphasis',
        bgClass: 'bg-beta-container',
        hoverBorder: 'border-beta-border-emphasis',
      },
      {
        border: 'border-success-container-emphasis',
        bgClass: 'bg-success-container',
        hoverBorder: 'border-success-border',
      },
      {
        border: 'border-neutral-container-emphasis',
        bgClass: 'bg-neutral-container',
        hoverBorder: 'border-neutral-border-emphasis',
      },
      {
        border: 'border-error-container-emphasis',
        bgClass: 'bg-error-container',
        hoverBorder: 'border-error-border',
      },
      {
        border: 'border-notice-container-emphasis',
        bgClass: 'bg-notice-container',
        hoverBorder: 'border-notice-border-emphasis',
      },
    ];
  }

  @action
  handleColorSelect(bgClass: Color, border: BorderColor) {
    this.selectedColor = bgClass;
    this.args.onSelect(bgClass, border);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartActions::BannerColorPicker': typeof BannerColorPickerComponent;
    'reporting/custom/chart-actions-banner-color-picker': typeof BannerColorPickerComponent;
  }
}
