/* import __COLOCATED_TEMPLATE__ from './treemap.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ChartDataResource from 'embercom/lib/reporting/chart-data-resource';
import { useResource } from 'ember-resources';
import { isEmpty, isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import { restartableTask } from 'ember-concurrency-decorators';
import { get } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { action } from '@ember/object';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type Highcharts from 'highcharts';
import { type Options, type SeriesOptionsType } from 'highcharts';
import { cached } from 'tracked-toolbox';
import { getOwner } from '@ember/application';
import { groupBy, zip } from 'underscore';

function mergeWith<TValue>(a: any, b: any, customizer: (a: any, b: any, key: string) => TValue) {
  let keys = new Set([...Object.keys(a), ...Object.keys(b)]);
  let combined = {} as Record<string, TValue>;
  for (let key of keys) {
    combined[key] = customizer(a[key], b[key], key);
  }
  return combined;
}
// TODO localise all strings once they are confirmed
/* eslint-disable @intercom/intercom/no-bare-strings */
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Bespoke::AiInsights::Monitor::Treemap': typeof TreemapChart;
  }
}

interface TreemapArgs {
  renderableChart: RenderableChart;
  extraContext: {
    onSelectedTopicChanged: (topicName: string | null, subtopics: string[]) => void;
    topTopicsDisplayed?: number;
    selectedTopic?: string | null;
    onShowConversationExplorer: (
      topic: Topic,
      subtopic: string | null,
      type: 'suggestions' | 'conversations',
    ) => void;
  };
}

interface SignalFlexibleResponseViewBy {
  groups: Array<{
    values: string[];
    aggregations: Array<{ name: string; values: number[]; processedValues?: any[] }>;
    name: string;
    type: string;
  }>;
}

interface Topic {
  name: string;
  description: string;
  subtopics: Set<string>;
}

interface TopicsHash {
  [key: string]: Topic;
}

interface SerializedSubtopic {
  topic_name: string;
  topic_description: string;
  subtopic_name: string;
}

export default class TreemapChart extends Component<TreemapArgs> {
  @service declare appService: $TSFixMe;
  @service declare reportingMetrics: ReportingMetrics;

  @tracked topics: Record<string, Topic> = {};
  @tracked topicLimit = this.args.extraContext.topTopicsDisplayed || 20;
  highchartsChart: any;
  @tracked tooltipOpenerElement: HTMLElement | undefined = undefined;
  @tracked highlightedName: string | undefined = undefined;
  @tracked conversationCount = 0;
  constructor(owner: unknown, args: TreemapArgs) {
    super(owner, args);
    taskFor(this.loadTopics).perform();
  }

  dataResource = useResource(this, ChartDataResource, () => ({
    renderableChart: this.renderableChartForSubtopics,
  }));

  topLevelDataResource = useResource(this, ChartDataResource, () => ({
    renderableChart: this.renderableChartForTopics,
  }));

  get renderableChartForSubtopics() {
    return this.args.renderableChart;
  }

  get renderableChartForTopics() {
    let chart = {
      ...this.args.renderableChart,
      viewBy: 'conversation_custom_fields#ai_insights_topic',
      cleanupConfig: () => {},
    };
    return new RenderableChart(chart, this.args.renderableChart.reportState, getOwner(this));
  }

  // This populates the topics hash with a structure like this:
  // { topicName: { subtopicName: true } }
  @restartableTask
  *loadTopics() {
    let response = (yield get('/ember/ai_insights/topics', {
      app_id: this.appService.app.id,
    })) as { topics: SerializedSubtopic[] };
    let topics = {} as TopicsHash;
    for (let entry of response.topics) {
      let topic = topics[entry.topic_name] || {
        name: entry.topic_name,
        description: entry.topic_description,
        subtopics: new Set(),
      };
      topic.subtopics.add(entry.subtopic_name);
      topics[topic.name] = topic;
    }
    this.topics = topics;
  }

  get currentNodeId() {
    return this.args.extraContext.selectedTopic;
  }

  get currentTopicName() {
    return this.currentNodeId;
  }

  makeLookup(responseData: SignalFlexibleResponseViewBy) {
    let keys = responseData.groups[0].values;
    let aggregations = responseData.groups[0].aggregations[0];
    let values = aggregations.processedValues || aggregations.values;
    return Object.fromEntries(zip(keys, values));
  }

  makeCombinedLookup(responseData: SignalFlexibleResponseViewBy[]) {
    if (isEmpty(responseData)) {
      return {};
    }
    let volume = this.makeLookup(responseData[0]);
    let metric = this.makeLookup(responseData[1]);
    return mergeWith(volume, metric, (a, b, key) => ({
      key,
      volume: a as number,
      metric: b,
      metricValue: isPresent(b) && isPresent(b.value) ? b.value : (b as number | undefined),
    }));
  }

  @cached
  get topicLookup() {
    return this.makeCombinedLookup(this.topLevelDataResource.rawChartData);
  }

  @cached
  get topTopics() {
    return Object.values(this.topicLookup)
      .sort((a, b) => b.volume - a.volume)
      .slice(0, this.topicLimit);
  }

  @cached
  get subtopicLookup() {
    let lookup = this.makeCombinedLookup(this.dataResource.rawChartData);
    // remove topic -> subtopic entries that aren't in the taxonomy stored in this.topics
    for (let key in lookup) {
      if (!this.subtopicIsInTaxonomy(key)) {
        delete lookup[key];
      }
    }
    return lookup;
  }

  subtopicIsInTaxonomy(subtopic: string) {
    let [topicName, subtopicName] = subtopic.split(':');
    return this.topics[topicName]?.subtopics.has(subtopicName);
  }

  @cached
  get subtopicsByTopic() {
    return groupBy(Object.values(this.subtopicLookup), ({ key }) => key.split(':')[0]);
  }

  get isLoading() {
    return (
      this.dataResource.isLoading ||
      this.topLevelDataResource.isLoading ||
      taskFor(this.loadTopics).isRunning
    );
  }

  @cached
  get chartData() {
    if (this.isLoading) {
      return [];
    }
    let topicsData = this.topTopics
      .sort((a, b) => b.metricValue - a.metricValue)
      .map((topic, index) => ({
        isSubtopic: false,
        id: topic.key,
        name: topic.key,
        value: topic.volume,
        colorValue: topic.metricValue,
        events: {
          mouseOver: this.onTopicMouseOver.bind(this),
          mouseOut: this.onTopicMouseOut.bind(this),
        },
        sortIndex: index,
      }));

    let displayedTopics = new Set(this.topTopics.map((topic) => topic.key));

    let subtopicsData = Object.values(this.subtopicLookup)
      .sort((a, b) => b.metricValue - a.metricValue)
      .map((subtopic, index) => {
        let [parentTopicName, subtopicName] = subtopic.key.split(':');
        let parentTopic = this.topicLookup[parentTopicName];
        if (displayedTopics.has(parentTopicName) && parentTopic) {
          return {
            isSubtopic: true,
            id: subtopic.key,
            parent: parentTopicName,
            name: subtopicName,
            value: subtopic.volume,
            colorValue: parentTopic.metricValue,
            sortIndex: index,
            events: {
              mouseOver: this.onTopicMouseOver.bind(this),
              mouseOut: this.onTopicMouseOut.bind(this),
            },
          };
        } else {
          return null;
        }
      })
      .compact();
    return [...topicsData, ...subtopicsData];
  }

  get percentageCover() {
    if (this.isLoading) {
      return 0;
    }
    let totalVolume = Object.values(this.topicLookup).reduce((acc, topic) => acc + topic.volume, 0);
    let displayedVolume = this.topTopics.reduce((acc, topic) => acc + topic.volume, 0);
    return Number(100 * (displayedVolume / totalVolume)).toFixed(1);
  }

  @action
  onTopicClick(event: any) {
    if (!event.point.isSubtopic) {
      this.changeNodeTo(event.point.id);
    }
  }

  @action
  goBackToTopics() {
    this.changeNodeTo(null, true);
  }

  @action
  onTopicMouseOver(event: any) {
    this.tooltipOpenerElement = event.target.graphic.element;
    this.highlightedName = event.target.options.name;
    this.conversationCount = event.target.value ?? 0;
  }

  @action
  onTopicMouseOut(_: any) {
    this.tooltipOpenerElement = undefined;
  }

  get topicName() {
    if (!this.highlightedName) {
      return '';
    }
    if (this.args.extraContext.selectedTopic) {
      return this.args.extraContext.selectedTopic;
    }
    return this.highlightedName;
  }

  get subtopicName() {
    if (!this.highlightedName || !this.args.extraContext.selectedTopic) {
      return '';
    }
    return this.highlightedName;
  }

  get tooltipDescription() {
    if (!this.highlightedName) {
      return '';
    }
    if (this.args.extraContext.selectedTopic) {
      return this.topics[this.args.extraContext.selectedTopic]?.description;
    }
    return this.topics[this.highlightedName]?.description;
  }

  get chartOptions(): Options {
    return {
      colors: ['#D2D2D2'], // this color is used when colorValue is undefined
      colorAxis: {
        min: 0,
        max: 100,
        stops: [
          [0.0, '#EB9082'],
          [0.25, '#FBBA6A'],
          [0.5, '#F8E78C'],
          [0.75, '#ADEDBD'],
          [1, '#7CDC95'],
        ],
      },
      plotOptions: {
        series: {
          borderWidth: 1,
        },
      },
      tooltip: {
        enabled: false,
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
    };
  }

  get highchartsSeries(): SeriesOptionsType[] {
    return [
      {
        type: 'treemap',
        turboThreshold: 0,
        data: this.chartData,
        breadcrumbs: {
          floating: true,
        },
        allowTraversingTree: true,
        layoutAlgorithm: 'squarified',
        animation: false,
        borderWidth: 1,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          style: {
            color: 'black',
            textOutline: '0px white',
            fontWeight: 'normal',
          },
        },
        events: {
          click: this.onTopicClick.bind(this),
          afterAnimate: this.onAfterAnimate.bind(this),
        },
        levels: [
          {
            level: 1,
            borderWidth: 3,
            dataLabels: {
              enabled: true,
              style: {
                fontSize: '14px',
              },
            },
            // @ts-ignore
            levelIsConstant: false,
          },
          {
            level: 2,
            borderWidth: 1,
            borderColor: '#ffffff',
            dataLabels: {
              enabled: false,
              style: {
                fontSize: '13px',
              },
            },
            // @ts-ignore
            levelIsConstant: true,
          },
        ],
        accessibility: {
          exposeAsGroupOnly: true,
        },
      },
    ];
  }

  @action
  onTopicLimitChange(event: any) {
    this.topicLimit = event.target.value;
  }

  @action
  onAfterAnimate(event: Highcharts.SeriesAfterAnimateEventObject) {
    this.highchartsChart = event.target.chart;
  }

  @action
  openConversationExplorer(type: 'suggestions' | 'conversations') {
    if (!this.highlightedName) {
      return;
    }
    if (this.args.extraContext.selectedTopic) {
      this.args.extraContext.onShowConversationExplorer(
        this.topics[this.args.extraContext.selectedTopic],
        this.highlightedName,
        type,
      );
    } else {
      this.args.extraContext.onShowConversationExplorer(
        this.topics[this.highlightedName],
        null,
        type,
      );
    }
  }

  changeNodeTo(nodeId: string | null, drillUp = false) {
    let series = this.highchartsChart.series[0];

    if (drillUp) {
      series.options.levels[1].dataLabels.enabled = false;
      series.drillUp();
    }

    let selectedNode = null;
    if (nodeId !== null) {
      selectedNode = series.nodeMap[nodeId];
    }

    if (selectedNode) {
      selectedNode.children.forEach((c: any) => {
        let subtopic = this.subtopicLookup[c.point.id];
        let colorValue = subtopic.metricValue;
        c.point.update({ colorValue }, false);
      });
    } else if (this.currentNodeId) {
      let oldSelectedNode = series.nodeMap[this.currentNodeId];
      oldSelectedNode.children.forEach((c: any) => {
        let colorValue = oldSelectedNode.point.colorValue;
        c.point.update({ colorValue }, false);
      });
    }
    series.redraw();

    if (!drillUp) {
      // enable labels for subtopics only after we're redrawn the colors to avoid slow performance
      series.options.levels[1].dataLabels.enabled = true;
    }

    let topicName = selectedNode?.name;
    let subtopics = topicName ? Array.from(this.topics[topicName]?.subtopics || []) : [];
    this.args.extraContext.onSelectedTopicChanged(topicName, subtopics);
  }
}
