/* import __COLOCATED_TEMPLATE__ from './treemap-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  topicName: string;
  subtopicName: string;
  description: string;
  onSeeConversations: (type: 'suggestions' | 'conversations') => void;
  conversationCount: number;
}

const TreemapTooltip = templateOnlyComponent<Args>();
export default TreemapTooltip;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::TreemapTooltip': typeof TreemapTooltip;
  }
}
