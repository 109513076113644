/* import __COLOCATED_TEMPLATE__ from './suggestion-list.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */
import Component from '@glimmer/component';
import { type Topic } from 'embercom/components/reporting/automation/ai-insights/fin-insights/topics-data-resources';
import AiContentLibraryApi from 'embercom/lib/ai-content-library/list-api';
import { taskFor } from 'ember-concurrency-ts';
import { restartableTask } from 'ember-concurrency-decorators';
import { getOwner } from '@ember/application';
import { Status } from 'embercom/models/data/content-service/content-review-statuses';
import { EntityType } from 'embercom/models/data/entity-types';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { ContentWrapperType } from 'embercom/models/content-service/content-wrapper';
import { action } from '@ember/object';

interface Args {
  selectedTopic: Topic;
  selectedSubTopic: string | null;
}

export default class SuggestionList extends Component<Args> {
  @service declare appService: $TSFixMe;

  @tracked declare listApi: AiContentLibraryApi;
  @tracked declare suggestions: ContentWrapper[];

  constructor(owner: any, args: any) {
    super(owner, args);
    this.performLoadSuggestions();
  }

  get isLoadingSuggestions() {
    return taskFor(this.loadSuggestions).isRunning;
  }

  @action
  async performLoadSuggestions() {
    await taskFor(this.loadSuggestions).perform();
  }

  @restartableTask private *loadSuggestions() {
    let additionalSearchableData: { topic: string; subtopic?: string } = {
      topic: this.args.selectedTopic.name,
    };
    if (this.args.selectedSubTopic) {
      additionalSearchableData.subtopic = this.args.selectedSubTopic;
    }

    let api = new AiContentLibraryApi(getOwner(this), {
      object_types: [EntityType.ArticleContent, EntityType.ContentSnippet],
      content_wrapper_types: [ContentWrapperType.AI_CONTENT],
      app_id: this.appService.app.id as string,
      per_page: 100,
      content_review_statuses: [Status.Pending],
      additional_searchable_data: additionalSearchableData,
    });

    yield api.loadPage();
    this.listApi = api;
    this.suggestions = api.contentWrappers;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::SuggestionList': typeof SuggestionList;
  }
}
