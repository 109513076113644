/* import __COLOCATED_TEMPLATE__ from './help-center-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Args {
  selected: string[];
  setSelected: (selected: string) => void;
}

export default class HelpCenterFilter extends Component<Args> {
  @service declare helpCenterService: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.helpCenterService.forceFetchSite(undefined);
  }

  get sites(): HelpCenterSite[] {
    return this.helpCenterService.allSites;
  }

  get filterValues() {
    let result = this.sites?.reduce((acc, current) => {
      return {
        ...acc,
        [`${current.id}`]: current.name,
      };
    }, {});

    return result || {};
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Flexible::FilterBar::Articles::HelpCenterFilter': typeof HelpCenterFilter;
    'reporting/flexible/filter-bar/articles/help-center-filter': typeof HelpCenterFilter;
  }
}
