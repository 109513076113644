/* import __COLOCATED_TEMPLATE__ from './add-chart-template-side-panel-card.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Report from 'embercom/models/reporting/custom/report';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type { ChartTemplate } from 'embercom/services/reporting-templates';
import type IntlService from 'ember-intl/services/intl';
import type Metric from 'embercom/objects/reporting/unified/metrics/types';
import { type Aggregation } from 'embercom/objects/reporting/unified/metrics/types';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type ReportingTemplates from 'embercom/services/reporting-templates';
import { modifier } from 'ember-modifier';
import type Paywall from 'embercom/components/paywall';
import { isPresent } from '@ember/utils';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';
import { INSIDE_OFFICE_HOURS, ALL_HOURS } from 'embercom/services/reporting-templates';
import { getNameFromFilterIdentifierFromMetric } from 'embercom/lib/reporting/custom/filter-helpers';

interface Args {
  chartTemplate: ChartTemplate;
  idx: number;
  report: Report;
  reportTemplate: any;
}

export default class AddChartTemplateSidePanelCard extends Component<Args> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare reportingTemplates: ReportingTemplates;
  @service declare appService: any;
  @tracked addedToReport = false;
  @tracked selectedAggregation: Aggregation = this.initialAggregation;
  @tracked selectedOfficeHours: any = this.defaultOfficeHoursValue();
  @tracked isHovered = false;
  @tracked isDragging = false;
  @tracked paywall: Paywall | null = null;

  updatePaywall = modifier((_: Element, [paywall]: [Paywall]) => {
    this.paywall = paywall;
  });

  get paywallIsActiveAsString() {
    return this.paywall?.isActive.toString() ?? 'false';
  }

  get isBrokenDownByBucket() {
    return this.args.chartTemplate.viewBy === 'bucket';
  }

  get initialAggregation() {
    if (this.isBrokenDownByBucket) {
      return 'range';
    }
    return 'median';
  }

  get supportedAggregations() {
    if (this.isBrokenDownByBucket) {
      return [{ text: this.intl.t('reporting.aggregations.range'), value: 'range' }];
    }
    return [
      { text: this.intl.t('reporting.aggregations.median'), value: 'median' },
      { text: this.intl.t('reporting.aggregations.mean'), value: 'mean' },
    ];
  }

  get viewByLabel() {
    if (this.isHeatmap) {
      return this.intl.t('reporting.chart.default-heatmap-view-by');
    } else if (this.args.chartTemplate.viewBy === 'time') {
      return this.intl.t('reporting.flexible.view-config.time');
    } else if (this.isBrokenDownByBucket) {
      return this.intl.t('reporting.object-catalog.custom-time-interval');
    }
    return getNameFromFilterIdentifierFromMetric(this.args.chartTemplate.viewBy, this.firstMetric);
  }

  get isHeatmap() {
    return this.args.chartTemplate.visualizationType === 'heatmap';
  }

  get segmentByLabel() {
    if (this.isHeatmap) {
      return this.intl.t('reporting.chart.default-heatmap-segment-by');
    }
    if (this.args.chartTemplate.segmentBy === 'time') {
      return this.intl.t('reporting.flexible.view-config.time');
    }
    return getNameFromFilterIdentifierFromMetric(
      this.args.chartTemplate.segmentBy,
      this.firstMetric,
    );
  }

  @action
  addChart() {
    if (this.paywall?.isActive) {
      return;
    }

    this.args.report.addChartFromTemplate(this.chartDefinition);
    this.addedToReport = true;
  }

  get chartDefinition() {
    return this.reportingTemplates.buildChartFromTemplate(
      this.args.chartTemplate,
      this.selectedAggregation,
      this.args.report,
      this.selectedOfficeHours,
    );
  }

  get aggregations() {
    return ['median', 'mean'];
  }

  get firstMetric() {
    let metricId = this.args.chartTemplate.chartSeries.firstObject.metricId;
    return this.reportingMetrics.getMetricById(metricId);
  }

  get firstOfficeHoursMetric() {
    let metricId = this.args.chartTemplate.chartSeries.find((series: ChartSeries) => {
      let metric = this.reportingMetrics.getMetricById(series.metricId);
      return ['office_hours', 'team_office_hours'].includes(metric.metricVariantType!);
    })?.metricId;
    return isPresent(metricId) ? this.reportingMetrics.getMetricById(metricId) : undefined;
  }

  get includeOfficeHoursVariant() {
    return isPresent(this.firstOfficeHoursMetric);
  }

  get metricDefaultId() {
    if (!this.firstOfficeHoursMetric) {
      return undefined;
    }
    return this.reportingMetrics
      .getMetricsByFamilyId(this.firstOfficeHoursMetric.metricFamilyId!)
      .find((metric: Metric) => metric.metricFamilyDefault)?.id;
  }

  get insideOfficeHours() {
    return this.firstOfficeHoursMetric?.id === this.metricDefaultId;
  }

  defaultOfficeHoursValue() {
    return this.insideOfficeHours ? INSIDE_OFFICE_HOURS : ALL_HOURS;
  }

  @action
  onUpdateSettings(value: any) {
    if (this.aggregations.includes(value)) {
      this.selectedAggregation = value;
    } else {
      this.selectedOfficeHours = value;
    }
  }

  get isNew() {
    return (
      this.args.chartTemplate.templateId === 'component.template.banner_component' ||
      this.args.chartTemplate.templateId ===
        'template.tabular.v2.first_response_time.bot_inbox_excluded.by_bucket' ||
      this.args.chartTemplate.templateId ===
        'template.tabular.v2.time_to_close.bot_inbox_excluded.by_bucket' ||
      this.args.chartTemplate.templateId ===
        'template.tabular.v2.time_to_close.bot_inbox_included.by_bucket' ||
      this.args.chartTemplate.templateId ===
        'template.tabular.v2.first_response_time.bot_inbox_included.by_bucket'
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::AddChartTemplateSidePanelCard': typeof AddChartTemplateSidePanelCard;
    'reporting/custom/report/add-chart-template-side-panel-card': typeof AddChartTemplateSidePanelCard;
  }
}
