/* import __COLOCATED_TEMPLATE__ from './suggestion-button.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type SuggestionService from 'embercom/services/suggestion-service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  topicId: string;
  subTopicId: string | null;
  onClick: () => void;
}
export default class SuggestionButton extends Component<Args> {
  @service declare suggestionService: SuggestionService;
  @service declare intl: IntlService;

  get topicsSuggestionCount() {
    if (this.args.subTopicId) {
      // If subTopicId is set, then we need to get the count for the subtopics grouped by the topicId
      return (
        this.suggestionService.topicsSummary
          .get(this.args.topicId)
          ?.subtopics.find((subtopic) => subtopic.name === this.args.subTopicId)?.count ?? 0
      );
    }
    return this.suggestionService.topicsSummary.get(this.args.topicId)?.totalCount ?? 0;
  }

  get icon() {
    // TODO change this based on the first suggestion type
    return this.topicsSuggestionCount > 0 ? 'knowledge' : undefined;
  }

  get label() {
    if (this.topicsSuggestionCount === 0) {
      return this.intl.t('automation.ai-insights.suggestions.suggestion-button-label-none');
    } else {
      // TODO change this based on the first suggestion type, plus the count of other suggestions
      return this.intl.t('automation.ai-insights.suggestions.suggestion-button-label', {
        count: this.topicsSuggestionCount - 1,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::FinInsights::SuggestionButton': typeof SuggestionButton;
  }
}
