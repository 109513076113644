/* import __COLOCATED_TEMPLATE__ from './barchart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import HighchartsDataBuilder from 'embercom/lib/reporting/flexible/highcharts-data-builder';
import SerieschartBuilder from 'embercom/lib/reporting/flexible/serieschart-builder';
import { cached } from 'tracked-toolbox';
import { getOwner } from '@ember/application';
import { buildFunctionToMapFromValueToSeriesColor } from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import {
  shouldAllowZeroValues,
  shouldConvertNullsToZeros,
  getFirstSeriesBucketKeys,
} from 'embercom/lib/reporting/flexible/data-response-helpers';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import { zip } from 'underscore';
import { mapXAxisLabel } from 'embercom/lib/reporting/flexible/label-formatter';
import {
  mapHumanReadableLabelsToRawKey,
  getChartSeriesName,
} from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import { isPresent } from '@ember/utils';
import { RANGE } from 'embercom/lib/reporting/flexible/constants';
import Formatters from 'embercom/lib/reporting/flexible/formatters';

export default class Barchart extends Component {
  @service appService;
  @service intl;
  @service reportingChartService;

  dataResource = useResource(this, ChartDataResourceCompatible, () => ({
    dataConfig: this.args.dataConfig,
    viewConfig: this.viewConfig,
    renderableChart: this.renderableChart,
  }));

  get renderableChart() {
    return this.args.renderableChart;
  }

  get viewConfig() {
    return {
      ...this.args.viewConfig,
      colorMappingFunction: buildFunctionToMapFromValueToSeriesColor(this.renderableChart.viewBy),
    };
  }

  @cached
  get chartData() {
    if (this.renderableChart.stacked || this.renderableChart.segmentBy) {
      return this.chartDataForStackedResponse;
    }
    return this.chartDataForSingleResponse;
  }

  get chartDataForStackedResponse() {
    try {
      return new HighchartsDataBuilder(
        getOwner(this),
        this.args.dataConfig,
        this.viewConfig,
      ).forHorizontalBarChartResponse(this.rawChartData);
    } catch (e) {
      this.dataResource?.notifyError();
      return [];
    }
  }

  buildSeriesData(data, keys = []) {
    // we assume there's only one group for a simple case
    let group = data.groups[0];
    let aggregation = group.aggregations[0];

    let points = zip(group.values, aggregation.values).map(([x, y]) => {
      return { x, y };
    });

    return points
      .map((point) => {
        if (isPresent(keys) && !keys.includes(point.x)) {
          return undefined;
        }
        let y = point.y !== 0 || this.shouldAllowZeroValues ? point.y : null;
        if (this.args.dataConfig.xAxis.type === 'nominal') {
          let xAxisLabel = mapXAxisLabel(this.args.dataConfig, this.args.viewConfig, point.x);
          return { x: xAxisLabel, y, name: xAxisLabel };
        } else if (this.args.dataConfig.xAxis.type === 'numerical') {
          let formatter = new Formatters[RANGE]();
          let formattedX = formatter.formatData(point.x);
          return { x: formattedX, y, name: formattedX };
        } else {
          return { x: point.x, y, name: point.x };
        }
      })
      .compact();
  }

  get rawChartData() {
    return this.dataResource?.rawChartData || [];
  }

  @cached
  get seriesData() {
    try {
      let firstSeriesBucketKeys = getFirstSeriesBucketKeys(this.renderableChart, this.rawChartData);
      return this.rawChartData.map((data) => this.buildSeriesData(data, firstSeriesBucketKeys));
    } catch (e) {
      this.dataResource?.notifyError();
      return [];
    }
  }

  get isSingleBar() {
    return this.renderableChart.stacked && !this.renderableChart.segmentBy;
  }

  getLabelFunction(seriesName, index) {
    if (this.renderableChart?.isMultimetric) {
      return () => getChartSeriesName(index, this.renderableChart);
    }
    let legendHash = this.args.viewConfig.legend || {};
    let func = legendHash[seriesName];
    if (func) {
      return func;
    }
    return function () {
      return undefined;
    };
  }

  get chartDataForSingleResponse() {
    return zip(this.viewConfig.metrics, this.seriesData).map(([metric, data], index) => {
      let seriesName = metric.id;
      let legendLabelFunc = this.getLabelFunction(seriesName, index);
      let aggregation = this.aggregationFunction;

      let cleanedData = shouldConvertNullsToZeros(aggregation, metric)
        ? this.reportingChartService.convertNullsToZeros(data)
        : data;

      return {
        name: seriesName,
        data: cleanedData.map((data) => [data.x, data.y, data.name]),
        legendLabel: legendLabelFunc(),
        showInLegend: this.seriesData.length > 1,
        marker: {
          symbol: 'circle',
        },
        colorByPoint:
          !this.renderableChart.isBrokenDownByTime && !this.renderableChart.isMultimetric,
        rawToLabelMapping: mapHumanReadableLabelsToRawKey(
          this.dataResource?.rawChartData[0],
          this.args.dataConfig,
          this.args.viewConfig,
        ),
      };
    });
  }

  get visualizationType() {
    return this.renderableChart.visualizationType;
  }

  get chartOptions() {
    let options = {
      range: this.args.range,
      chartData: this.chartData,
      width: this.args.width,
      viewConfig: this.viewConfig,
      dataConfig: this.args.dataConfig,
      chartType: 'bar',
      app: this.appService.app,
      isMultimetric: this.renderableChart?.isMultimetric || false,
    };

    options.viewConfig.showLegendInTooltips =
      this.isHorizontalBarWithCounter || this.renderableChart.segmentBy;

    let builder = new SerieschartBuilder(options);
    return builder.buildTheme(this.visualizationType);
  }

  get isHorizontalBarWithCounter() {
    return this.visualizationType === VISUALIZATION_TYPES.HORIZONTAL_BAR_WITH_COUNTER;
  }

  get aggregationFunction() {
    return this.args.dataConfig?.series?.firstObject?.type;
  }

  get shouldAllowZeroValues() {
    return shouldAllowZeroValues(this.viewConfig.metrics?.[0], this.aggregationFunction);
  }

  get hasData() {
    if (this.args.disabled) {
      return false;
    }

    return this.chartData.some((singleSeries) => {
      return singleSeries.data.some((dataArray) => {
        if (dataArray.length > 1) {
          return dataArray[1] || (this.shouldAllowZeroValues && dataArray[1] === 0);
        } else {
          return true;
        }
      });
    });
  }

  get showEmptyState() {
    return this.args.dashboard?.isPaywalled || this.args.isPaywalled || !this.hasData;
  }

  get emptyStateClass() {
    if (this.isHorizontalBarWithCounter) {
      return '';
    }

    return 'h-full';
  }
}
