/* import __COLOCATED_TEMPLATE__ from './data-export.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { CountSummary } from './custom/report/underlying-data-modal';
import { action } from '@ember/object';
import type { FlexibleQueryTimeRange } from 'embercom/services/reporting-csv-export';
import Range from 'embercom/models/reporting/range';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import { inject as service } from '@ember/service';
import { compact, flatten } from 'underscore';
import type { FilterItem } from 'embercom/objects/reporting/unified/metrics/types';
import type { Attribute } from 'embercom/objects/reporting/unified/datasets/types';
import { changeFilter } from 'embercom/lib/reporting/custom/filter-helpers';
import type { LogicalFilterOperator } from './custom/filters';
import type ReportingUnderlyingDataService from 'embercom/services/reporting-underlying-data-service';
import { groupDropDownItems } from './custom/chart-builder/chart-builder-helpers';
import type IntlService from 'embercom/services/intl';
import type { DropDownItem } from './custom/chart-builder/chart-builder-helpers';
import type ReportingCsvExport from 'embercom/services/reporting-csv-export';
interface Args {}

interface Filters {
  type: 'and' | 'or';
  filters: any[];
}

export default class DataExport extends Component<Args> {
  @service declare reportingMetrics: ReportingMetrics;
  @service declare appService: any;
  @service declare reportingUnderlyingDataService: ReportingUnderlyingDataService;
  @service declare intl: IntlService;
  @service declare reportingCsvExport: ReportingCsvExport;
  @service declare store: any;
  @service declare intercomEventService: any;

  @tracked filters: Filters = {
    type: 'and',
    filters: [],
  };
  @tracked columns: any[] = this.getDefaultColumns();
  @tracked summary: CountSummary = {
    retrievedCount: 0,
    totalCount: 0,
  };
  @tracked exportDateRange: Range;
  @tracked sortBy = this.getDefaultSortBy();
  @tracked sortDirection: 'asc' | 'desc' = 'desc';
  @tracked timezone = '';
  @tracked pageFrom = 0;
  @tracked datasetId = 'conversation';
  previousSelection = this.columns;
  selectedDropdownItems = this.columns;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.timezone = this.appService.app.timezone;
    this.exportDateRange = Range.createFromPreset('past_4_weeks', this.timezone);
  }

  get conversationAttributeDescriptors() {
    return this.store.peekAll('conversation-attributes/descriptor');
  }

  getDefaultColumns() {
    return this.reportingUnderlyingDataService.getDatasetDefaultColumns(this.datasetId);
  }

  getDefaultSortBy() {
    return this.reportingUnderlyingDataService.getDatasetTimeAttribute(this.datasetId);
  }

  @action
  setDatasetId(datasetId: string) {
    this.datasetId = datasetId;
    this.sortBy = this.getDefaultSortBy();
    this.columns = this.getDefaultColumns();
    this.resetPageFrom();
    this.resetFilters();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'dataset_changed',
      object: 'reporting_dataset_export',
      dataset_id: this.datasetId,
    });
  }

  resetFilters() {
    let compatibleFilters = this.filters.filters.every((filter) => {
      let properties = this.filterableDatasetProperties;
      return properties.some((property) => property.id === filter.data.attribute);
    });

    if (!compatibleFilters) {
      this.filters = {
        type: 'and',
        filters: [],
      };
    }
  }

  @action
  updateCountSummary(summary: CountSummary) {
    this.summary = summary;
  }

  @action
  onSortUpdate(sortBy: string, sortDirection: 'asc' | 'desc') {
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
  }

  get rowsPerPage() {
    return 100;
  }

  @action
  resetPageFrom() {
    this.pageFrom = 0;
  }

  @action
  updatePageFrom(pages: number) {
    this.pageFrom += pages;
  }

  private getTablePropertiesFromDatasetAttributes() {
    let attributes = this.reportingMetrics.getDatasetAttributesFor(this.datasetId);

    return compact([...attributes]).map((attribute: Attribute) => {
      return {
        text: this.reportingMetrics.translateKey(attribute.name) || '',
        value: attribute.id,
        isSelected: this.columns.indexOf(attribute.id) !== -1,
        icon: attribute.icon,
        headerName: this.reportingMetrics.translateKey(attribute.headerName) || undefined,
        group: attribute.group,
      };
    });
  }

  get tableProperties(): FilterItem[] {
    return this.getTablePropertiesFromDatasetAttributes();
  }

  get filterableDatasetProperties() {
    let properties = this.reportingMetrics.getDatasetAttributesFor(this.datasetId);

    properties = flatten([
      ...properties,
      this.reportingMetrics.getFilterableDatasetCdaAttributesFor(this.datasetId),
    ]);
    return properties;
  }

  @action
  onFiltersChanged(
    index: number,
    attribute: { id: string; field: string },
    values: string[],
    operator: string | null,
  ) {
    this.filters = changeFilter(this.filters, index, attribute, values, operator);
    this.resetPageFrom();
    this.applyDateRange(values);
  }

  applyDateRange(dateRange: any) {
    this.exportDateRange.selectedRange = dateRange.selectedRange;

    if (dateRange.selectedRange === 'custom') {
      this.exportDateRange.updateRange(dateRange.startMoment, dateRange.endMoment);
    }
  }

  @action
  onFiltersCleared() {
    this.filters = {
      type: 'and',
      filters: [],
    };
    this.resetPageFrom();
  }

  @action
  applyLogicalFilterOperator(operator: LogicalFilterOperator) {
    this.filters = {
      type: operator,
      filters: this.filters.filters,
    };
  }

  get hasData() {
    return this.summary.totalCount > 0;
  }

  get groupedTableProperties() {
    let allAttributes = this.reportingMetrics.getDatasetAttributesFor(this.datasetId);
    let groupedProperties = groupDropDownItems(
      this.tableProperties as DropDownItem[],
      allAttributes,
      this.intl,
    );

    return [...groupedProperties];
  }

  @action
  onCloseColumnSelector() {
    if (this.previousSelection !== this.selectedDropdownItems) {
      this.setColumns(this.selectedDropdownItems);
      this.resetPageFrom();
    }
  }

  @action
  setColumns(columns: string[]) {
    this.columns = this.reportingUnderlyingDataService.getOrderedDatasetAttributeIds(
      columns,
      this.previousSelection,
      this.datasetId,
    );

    this.reportingUnderlyingDataService.setDatasetColumnSelectionInCache(
      this.datasetId,
      this.columns,
    );

    this.previousSelection = this.columns;
  }

  @action
  onSelectionChange(selection: string[]) {
    this.previousSelection = this.selectedDropdownItems;
    this.selectedDropdownItems = selection.uniq();
  }

  get countSummary() {
    return this.intl.t('reporting.data-table.view-summary', {
      currentCount: this.intl.formatNumber(this.summary.retrievedCount),
      totalCount: this.intl.formatNumber(this.summary.totalCount),
    });
  }

  get isWithinRowLimit() {
    return this.summary.totalCount <= 10_000_000;
  }

  get exportTimeRange(): FlexibleQueryTimeRange {
    let dataModel;

    let datasetTimeAttribute =
      this.reportingUnderlyingDataService
        .getDatasetTimeAttribute(this.datasetId)
        ?.split('.')
        .pop() || 'first_user_conversation_part_created_at';

    return {
      start: this.exportDateRange.startMoment.valueOf(),
      end: this.exportDateRange.endMoment.valueOf(),
      interval: this.exportDateRange.interval,
      property: datasetTimeAttribute,
      data_model: dataModel,
    };
  }

  get sendViaMail() {
    return this.summary.totalCount > 10_000;
  }

  @action
  exportCsv() {
    let analyticsEvent = { object: 'reporting_dataset_export', rows: this.summary.totalCount };
    this.reportingCsvExport.exportCsv(
      this.datasetId,
      this.filters,
      this.columns,
      this.exportTimeRange,
      this.sortBy,
      this.sortDirection,
      false,
      analyticsEvent,
      this.timezone,
      this.sendViaMail,
    );
  }

  @action
  onHoverExportButton() {
    if (this.summary.totalCount > 10_000_000) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'hovered_disabled_export_button',
        object: 'reporting_dataset_export',
        row_count: this.summary.totalCount,
      });
    } else if (this.summary.totalCount === 0) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'hovered_disabled_export_button',
        object: 'reporting_dataset_export',
        row_count: this.summary.totalCount,
      });
    }
  }

  get exportButtonTooltip() {
    if (this.summary.totalCount > 10_000_000) {
      return this.intl.t(
        'components.reporting.custom.report.data-table.export-csv-limit-exceeded-tooltip',
      );
    } else {
      return this.intl.t('reporting.data-export.export-csv-tooltip');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::DataExport': typeof DataExport;
    'reporting/data-export': typeof DataExport;
  }
}
