/* import __COLOCATED_TEMPLATE__ from './report.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { isEmpty, flatten } from 'underscore';
import { tracked } from '@glimmer/tracking';
import { filterableProperties as defaultFilterableProperties } from '../../../lib/reporting/custom/filter-helpers';
import { isPresent } from '@ember/utils';
import { registerDestructor } from '@ember/destroyable';
import { captureException } from '@sentry/browser';
import { ALLOWED_FILTERABLE_ATTRIBUTE_IDS } from 'embercom/services/reporting-standalone';
import { trackedInLocalStorage } from 'ember-tracked-local-storage';
import Range from 'embercom/models/reporting/range';

export const DATE_FILTER_SAVED_KEY = 'custom_report_date_filter_saved';
export const DATE_FILTER_LOADED_KEY = 'custom_report_date_filter_loaded';
export const DATE_RANGE_STORAGE_KEY = 'reporting:custom-report:dateRange';

export default class Report extends Component {
  @service store;
  @service appService;
  @service intercomConfirmService;
  @service intercomEventService;
  @service notificationsService;
  @service router;
  @service permissionsService;
  @service reportSubscriptionService;
  @service favoritesService;
  @service customReportsService;
  @service reportingMetrics;
  @service reportingService;
  @service navbarCollapsingService;
  @service intl;
  @service reportAccessService;

  @tracked dateRange;
  @tracked showSubscriptionModal = false;
  @trackedInLocalStorage({
    keyName: DATE_RANGE_STORAGE_KEY,
  })
  localStorageDateRange;
  timezoneList = this.customReportsService.timezones();

  constructor() {
    super(...arguments);
    if (this.args.report.isNew) {
      window.onbeforeunload = (event) => {
        event.preventDefault();
        // Included for legacy support, e.g. Chrome/Edge < 119
        event.returnValue = this.intl.t('reporting.custom-reports.report.unload-warning');
        this.intercomEventService.trackAnalyticsEvent({
          action: 'reload',
          property: 'page',
          object: 'custom_report',
          section: 'reports',
        });
        return event.returnValue;
      };

      registerDestructor(this, () => {
        window.onbeforeunload = undefined;
      });
    }
    if (this.showDateFilterLoaded && this.localStorageDateRange) {
      this.notificationsService.notify(
        this.intl.t('reporting.custom-reports.report.date-filter-applied'),
      );
      window.sessionStorage.setItem(DATE_FILTER_LOADED_KEY, 'true');
    }
  }

  get reportState() {
    return {
      settings: this.args.settings,
      dateRange: this.range,
      filters: this.args.report.filters,
      timezone: this.args.report.reportTimezone,
    };
  }

  get range() {
    if (this.args.editMode) {
      return this.args.report.range;
    }

    return this.localStorageRange || this.args.report.range;
  }

  get localStorageRange() {
    if (this.restrictedViewAccess) {
      return null;
    }

    return this.localStorageDateRange
      ? Range.createFromModel(this.localStorageDateRange, this.args.report.reportTimezone)
      : null;
  }

  resetRangeFromLocalStorage() {
    this.localStorageDateRange = null;
    localStorage.removeItem(DATE_RANGE_STORAGE_KEY);
  }

  get conversationAttributeDescriptors() {
    return this.store.peekAll('conversation-attributes/descriptor');
  }

  get filterableProperties() {
    if (this.args.isStandalone) {
      return ALLOWED_FILTERABLE_ATTRIBUTE_IDS;
    }
    return defaultFilterableProperties().concat('teammate', 'team');
  }

  get filterableMetricProperties() {
    return flatten([
      this.reportingMetrics.getPropertiesByIds(this.filterableProperties),
      this.reportingMetrics.getFilterableDatasetCdaAttributesFor('conversation'),
    ]);
  }

  get filtersWithDateRange() {
    return this.args.report.filters;
  }

  get hasFilters() {
    return isPresent(this.filtersWithDateRange);
  }

  get haveFiltersChanged() {
    return (
      this.args.report.haveFiltersChanged ||
      (isPresent(this.localStorageRange) &&
        !this.rangesAreEqual(this.args.report.range, this.localStorageRange))
    );
  }

  rangesAreEqual(range1, range2) {
    if (range1?.selectedRange === 'custom' && range2?.selectedRange === 'custom') {
      return range1.start === range2.start && range1.end === range2.end;
    }
    return range1?.selectedRange === range2?.selectedRange;
  }

  get reportInViewMode() {
    return !this.args.editMode;
  }

  @action
  resetFiltersInViewMode() {
    this.resetRangeFromLocalStorage();
    this.args.report.rollbackAttributes();
    this.args.report.reload();
    this.notificationsService.notifyConfirmation(
      this.intl.t('reporting.custom-reports.report.filter-reset'),
    );
  }

  get isAddFilterDisabled() {
    return this.restrictedViewAccess;
  }

  get sharedAnalyticsData() {
    return {
      object: 'custom_report',
      custom_report_id: this.args.report.id,
      custom_report_name: this.args.report.title || 'untitled',
    };
  }

  @action
  trackAnalyticsEvent(data) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.sharedAnalyticsData,
      ...data,
    });
  }

  @task({ drop: true }) *saveReport() {
    yield this.withReportAccess(async () => {
      let report = await this.performSaveReport.perform();
      this.notificationsService.notifyConfirmation('Your report has been saved');
      this.localStorageDateRange = this.args.report.dateRange;
      if (this.args.onReportSaved) {
        this.args.onReportSaved(report);
      }
    });
  }

  @task({ drop: true }) *performSaveReport() {
    let isNew = this.args.report.isNew;
    let editedAttributesData = this.editedReportAnalyticsData(this.args.report);
    let templatedCharts = this.args.report.availableCharts.filter(
      (chart) => chart.hasDirtyAttributes && isPresent(chart.templateId),
    );

    let report = yield this.args.report.save();
    this.trackAnalyticsEvent({
      action: 'edited_report',
      object: 'custom_report',
      ...editedAttributesData,
    });
    templatedCharts.forEach((tc) =>
      this.trackAnalyticsEvent({
        action: 'added_templated_chart_on_report',
        object: 'custom_chart',
        template_id: tc.templateId,
        custom_chart_id: tc.id,
      }),
    );

    if (isNew) {
      this.router.transitionTo('apps.app.reports.custom-reports.report.show', report.id);
      this.openShareSideDrawer({ isNew: true });
    }
    return report;
  }

  @action
  async withReportAccess(action) {
    try {
      return await action();
    } catch (e) {
      if (e.jqXHR.status === 403) {
        this.reportAccessService.loadReportAccessModal(this.args.report.id, () => {
          this.args.report.rollbackAttributes();
          if (!this.reportAccessService.hasAnyReportAccessType(this.args.report)) {
            // if user or workspace access has been revoked, we want to transtion
            this.router.transitionTo('apps.app.reports.overview');
          }
        });
      } else {
        captureException(e);
        console.error(e);
        this.notificationsService.notifyError('Something went wrong, try reloading the page.');
        return undefined;
      }
    }
  }

  @action
  cancelChanges() {
    this.args.revert();
    this.notificationsService.notifyConfirmation(
      this.intl.t('reporting.custom-reports.report.changes-discarded'),
    );
  }

  @action
  editChart(chartId) {
    this.trackAnalyticsEvent({
      action: 'clicked',
      object: 'edit_chart',
    });
    if (!this.args.editMode) {
      this.args.enableEditMode(true, false);
    }
    if (this.args.isStandalone) {
      this.router.transitionTo('apps.app.standalone.reports.report.show.chart.edit', chartId, {
        queryParams: {
          cr2AddingChartToReport: true,
          isStandalone: this.args.isStandalone,
        },
      });
    } else {
      this.router.transitionTo('apps.app.reports.custom-reports.report.show.chart.edit', chartId, {
        queryParams: {
          cr2AddingChartToReport: true,
        },
      });
    }
  }

  @task({ drop: true }) *deleteChart(chartId) {
    if (this.customReportsService.canDeleteReport) {
      let chart = this.store.peekRecord('reporting/custom/chart', chartId);
      yield this.args.report.removeChart(chart, { save: false });
      this.trackAnalyticsEvent({
        action: 'deleted',
        object: 'custom_chart',
      });
    } else {
      this.customReportsService.loadDeletePermissionsModal();
    }
  }

  applyDateRangeFilter(filterValues) {
    if (this.showDateFilterSaved) {
      this.notificationsService.notifyConfirmation(
        this.intl.t('reporting.custom-reports.report.date-filter-saved'),
      );
      if (window.sessionStorage) {
        try {
          window.sessionStorage.setItem(DATE_FILTER_SAVED_KEY, 'true');
        } catch {} // eslint-disable-line no-empty
      }
    }
    this.args.report.applyDateRange(filterValues);
    if (this.reportInViewMode) {
      this.localStorageDateRange = this.args.report.dateRange;
    }
  }

  get showDateFilterSaved() {
    return (
      this.reportInViewMode &&
      (!window.sessionStorage ||
        (window.sessionStorage && !window.sessionStorage.getItem(DATE_FILTER_SAVED_KEY)))
    );
  }

  get showDateFilterLoaded() {
    return (
      this.reportInViewMode &&
      (!window.sessionStorage ||
        (window.sessionStorage && !window.sessionStorage.getItem(DATE_FILTER_LOADED_KEY)))
    );
  }

  @action
  updateFilter(index, attribute, filterValues, operator) {
    this.trackAnalyticsEvent({
      action: 'filtered',
      filter_name: attribute.id,
    });
    this.intercomEventService.trackEvent('filtered-custom-report');

    if (attribute.id === 'time') {
      this.applyDateRangeFilter(filterValues);
      return;
    }

    this.args.report.applyFilter(index, attribute, filterValues, operator);
  }

  @action
  clearFilters() {
    this.trackAnalyticsEvent({
      action: 'clear_filters',
    });
    this.args.report.dateRange = null;
    this.args.report.filters = {};
  }

  @action
  applyLogicalFilterOperator(operator) {
    this.args.report.applyLogicalFilterOperator(operator);
  }

  @action
  addChart(buttonPosition) {
    this.trackAnalyticsEvent({
      action: 'clicked',
      object: 'add_chart',
      button_position: buttonPosition,
    });
    this.intercomEventService.trackEvent('created-custom-report-chart');
    this.transitionToNewChartPage();
  }

  @task({ drop: true }) *duplicateChart(params) {
    if (this.customReportsService.canChangeCustomReports) {
      let { chartId } = params;
      let sourceChart = yield this.store.peekRecord('reporting/custom/chart', chartId);
      let duplicatedChart = this.args.report.duplicateChart(sourceChart);
      if (isEmpty(duplicatedChart)) {
        return;
      }
      this.trackAnalyticsEvent({
        action: 'duplicated',
        object: 'custom_chart',
      });
      this.notificationsService.notifyConfirmation('Your chart was successfully duplicated');
      this.scrollToChart(duplicatedChart.id);
    } else {
      this.customReportsService.loadChangePermissionModal();
    }
  }

  scrollToChart(chartId) {
    let selector = `[draggable-chart-card-id="${chartId}"]`;
    let chartElement = document.querySelector(selector);
    if (!chartElement) {
      return;
    }
    chartElement.scrollIntoView();
  }

  @action
  reorderCharts(source, destination) {
    let reportCharts = this.args.report.charts;
    if (!reportCharts.isFulfilled) {
      throw new Error('report.charts promise is not fulfilled');
    }
    if (reportCharts.isRejected) {
      throw new Error('report.charts promise was rejected');
    }
    let charts = reportCharts.toArray();
    let deleted = charts.splice(source, 1)[0];
    charts.insertAt(destination, deleted);
    reportCharts.setObjects(charts);
  }

  async transitionToNewChartPage() {
    if (this.args.isStandalone) {
      this.router.transitionTo(
        'apps.app.standalone.reports.report.show.chart.new',
        this.args.report.id,
        {
          queryParams: {
            source: 'custom_report',
            isStandalone: this.args.isStandalone,
            cr2AddingChartToReport: true,
          },
        },
      );
    } else {
      this.router.transitionTo(
        'apps.app.reports.custom-reports.report.show.chart.new',
        this.args.report.id,
        {
          queryParams: {
            source: 'custom_report',
            cr2AddingChartToReport: true,
          },
        },
      );
    }
  }

  @action
  openSubscriptionModal() {
    this.showSubscriptionModal = true;
  }

  @action
  closeSubscriptionModal() {
    this.showSubscriptionModal = false;
  }

  @action
  openChartTemplateSidePanel() {
    this.trackAnalyticsEvent({
      action: 'opened',
      object: 'chart_template_side_panel',
    });
    this.args.reportMode.isSidePanelOpen = true;
  }

  @action
  closeChartTemplateSidePanel() {
    this.trackAnalyticsEvent({
      action: 'closed',
      object: 'chart_template_side_panel',
    });
    this.args.reportMode.isSidePanelOpen = false;
  }

  get knownValuesEndpointSource() {
    let sourcesMap = this.args.report.charts?.map((chartState) => {
      let metrics = chartState.chartSeries.map((series) => series.metric);
      return metrics.map((metric) => metric.firstSource);
    });
    let sources = new Set(sourcesMap.flat());
    return [...sources].compact();
  }

  get shouldRenderTimezones() {
    let appTimezone = this.timezoneList.find((tz) => tz.value === this.appService.app.timezone);
    return isPresent(appTimezone);
  }

  get timezones() {
    return this.timezoneList.map((tz) => {
      return {
        text: tz.description,
        value: tz.description,
      };
    });
  }

  get currentTimezoneValue() {
    return this.reportState.timezone;
  }

  get currentTimezoneName() {
    return this.getTimezoneName(this.currentTimezoneValue);
  }

  @action
  updateTimezone(name) {
    let value = this.getTimezoneValue(name);
    this.args.report.applyTimeZone(value);
  }

  getTimezoneName(value) {
    return this.timezoneList.find((tz) => tz.value === value)?.description;
  }

  getTimezoneValue(name) {
    return this.timezoneList.find((tz) => tz.description === name)?.value;
  }

  @task({ drop: true })
  *duplicateReport() {
    let { report, onReportSaved } = this.args;
    if (report.isNew) {
      return;
    }

    return yield this.withReportAccess(async () => {
      if (report.hasDirtyAttributes) {
        let confirmed = await this.customReportsService.confirmSave(false);
        if (confirmed === true) {
          await this.performSaveReport.perform(report);
        } else {
          report.rollbackAttributes();
        }
      }
      //this returns json object of report or empty object comment here https://github.com/intercom/embercom/pull/49596#discussion_r627234404
      let duplicatedReport = await this.postDuplicateReport.perform(report);

      if (isEmpty(duplicatedReport)) {
        return;
      }
      this.trackAnalyticsEvent({
        action: 'duplicated',
        object: 'custom_report',
      });

      if ('id' in duplicatedReport) {
        this.router.transitionTo(
          'apps.app.reports.custom-reports.report.show',
          duplicatedReport.id,
        );
      }
      // @ts-ignore
      this.notificationsService.notifyConfirmation(
        'Your custom report was successfully duplicated',
      );
      if (onReportSaved) {
        onReportSaved(duplicatedReport);
      }
    });
  }

  @task({ drop: true })
  *postDuplicateReport(report) {
    try {
      let result = yield report.duplicateReport({
        app_id: this.appService.app.id,
        admin_id: this.appService.app.currentAdmin.id,
        id: report.id,
      });
      return result;
    } catch (_e) {
      //TODO: localize string
      this.notificationsService.notifyError('Something went wrong, try reloading the page.');
      return undefined;
    }
  }

  editedReportAnalyticsData(report) {
    let reportChangedAttributes = report.changedAttributes();
    return {
      title_changed: 'title' in reportChangedAttributes,
      description_changed: 'description' in reportChangedAttributes,
      filters_changed: 'filters' in reportChangedAttributes,
      number_of_charts: report.charts.length,
      timezone_changed: 'timezone' in reportChangedAttributes,
      timezone_changed_from_default: this.isTimezoneUpdatedFromDefault(report),
    };
  }

  isTimezoneUpdatedFromDefault(report) {
    let timezoneChanges = report.changedAttributes()['timezone'];
    if (isEmpty(timezoneChanges) || !timezoneChanges) {
      return false;
    }
    let [previousTimeZone] = timezoneChanges;
    let defaultTimeZone = this.appService.app.timezone;
    return !isPresent(previousTimeZone) || previousTimeZone === defaultTimeZone;
  }

  @task({ drop: true })
  *deleteReport() {
    let { report } = this.args;

    let confirm = yield this.intercomConfirmService.confirm({
      title: this.intl.t('reporting.custom-reports.report.delete-confirmation-modal.title'),
      body: this.intl.t('reporting.custom-reports.report.delete-confirmation-modal.body'),
      primaryButtonType: 'primary-destructive',
      confirmButtonText: this.intl.t(
        'reporting.custom-reports.report.delete-confirmation-modal.confirm-text',
      ),
    });

    if (!confirm) {
      return;
    }
    this.trackAnalyticsEvent({
      action: 'deleted',
      object: 'custom_report',
    });

    try {
      yield report.destroyRecord();
      this.notificationsService.notifyConfirmation('Your report has been deleted');

      if (this.favoritesService.isFavoriteReport(report.id)) {
        yield this.favoritesService.removeFromFavorites.perform(report.id);
      }

      this.router.transitionTo('apps.app.reports.overview');
    } catch (e) {
      if (e.jqXHR.status === 403) {
        this.reportAccessService.loadReportAccessModal(report.id, () => {
          if (!this.reportAccessService.hasAnyReportAccessType(report)) {
            //owners don't have report access. But they won't get 403. If user/workspace access has been revoked we want to transitionTo
            report.rollbackAttributes();
            this.router.transitionTo('apps.app.reports.overview');
          }
        });
      } else {
        captureException(e);
        console.error(e);
      }
    }
  }

  get emptyReport() {
    return !this.args.report.hasCharts;
  }

  get restrictedViewAccess() {
    if (this.appService.app.canShareReportsInternally) {
      return this.reportAccessService.adminIsRestricted(this.args.report);
    } else {
      return false;
    }
  }

  get canEditReportOrReportIsNew() {
    return this.reportAccessService.canEditReportOrReportIsNew(this.args.report);
  }

  @action
  openShareSideDrawer({ isNew = false }) {
    if (this.customReportsService.canChangeCustomReports) {
      this.args.reportMode.isShareSideDrawerOpen = true;
      this.args.reportMode.isReportNew = isNew;
    } else {
      this.customReportsService.loadChangePermissionModal();
    }
  }

  @action
  closeShareSideDrawer() {
    this.args.reportMode.isShareSideDrawerOpen = false;
    if (this.navbarCollapsingService.collapsed) {
      this.navbarCollapsingService.toggleNavbarCollapsed();
    }
  }
}
