/* import __COLOCATED_TEMPLATE__ from './fin-content-table.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import { type FinAIType } from 'embercom/lib/knowledge-hub/constants';
import TableColumnBuilder from 'embercom/lib/reporting/custom/table-column-builder';
import TableDataConfigBuilder from 'embercom/lib/reporting/custom/table-data-config-builder';
import { EntityType } from 'embercom/models/data/entity-types';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

interface Args {
  renderableChart?: any;
  aiType: FinAIType;
}

type TableRow = {
  [key: string]: number;
} & {
  groupName: string;
};

const EXCLUDED_ENTITY_TYPES: any = [EntityType.ConversationExcerpt];

export default class FinContentTable extends Component<Args> {
  @service declare intl: IntlService;
  @tracked selectedEntityTypes: Array<EntityType> = [];
  @service declare knowledgeHubService: KnowledgeHubService;

  get tableDataConfig() {
    return new TableDataConfigBuilder(this.args.renderableChart).buildDataConfig();
  }

  @action
  onEntityTypesChange(entityTypes: Array<EntityType>) {
    this.selectedEntityTypes = entityTypes;
  }

  get availableEntityTypes() {
    return this.knowledgeHubService
      .getSearchObjectTypes(this.args.aiType)
      .filter((entityType) => !EXCLUDED_ENTITY_TYPES.includes(entityType));
  }

  isEntityTypeSelected(tableRow: TableRow) {
    let displayableEntityTypes =
      this.selectedEntityTypes.length > 0 ? this.selectedEntityTypes : this.availableEntityTypes;

    return displayableEntityTypes.any((entityType) =>
      tableRow.groupName.startsWith(`${entityType}:`),
    );
  }

  get tableViewConfig() {
    let viewConfig = this.args.renderableChart.buildViewConfigForTable();
    return {
      ...viewConfig,
      scrollable: false,
      isVisibleRowAfterLoading: this.isEntityTypeSelected.bind(this),
    };
  }

  get columns() {
    return new TableColumnBuilder(this.args.renderableChart, getOwner(this)).buildColumns();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Bespoke::FinContentTable': typeof FinContentTable;
    'reporting/bespoke/fin-content-table': typeof FinContentTable;
  }
}
