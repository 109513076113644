/* import __COLOCATED_TEMPLATE__ from './data-table.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Metric from 'embercom/objects/reporting/unified/metrics/types';
import type IntlService from 'ember-intl/services/intl';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type ReportingUnderlyingDataService from 'embercom/services/reporting-underlying-data-service';
import type ApplicationInstance from '@ember/application/instance';
import { registerDestructor } from '@ember/destroyable';
import type ReportingCsvExport from 'embercom/services/reporting-csv-export';
import { type LogicalFilter } from 'embercom/components/reporting/custom/filters';
import { type FlexibleQueryTimeRange } from 'embercom/services/reporting-csv-export';
import { modifier } from 'ember-modifier';
import { timeDataModelForDrillIn } from 'embercom/lib/reporting/custom/data-config-builder-helpers';
import { type FieldMetric } from 'embercom/objects/reporting/unified/metrics/types';

interface Args {
  renderableChart: RenderableChart;
  setDataTableExportFunction: (exportFunction: () => void) => void;
  setDataTableTotalCount: (count: number) => void;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export interface CountSummary {
  retrievedCount: number;
  totalCount: number;
}

export default class DataTable extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;
  @service declare reportingUnderlyingDataService: ReportingUnderlyingDataService;
  @service declare reportingCsvExport: ReportingCsvExport;
  @service declare permissionsService: $TSFixMe;
  @service declare navbarCollapsingService: $TSFixMe;

  @tracked sortBy: any = this.initialSortBy;
  @tracked sortDirection: 'asc' | 'desc' = 'desc';
  @tracked pageFrom = 0;
  @tracked private summary: CountSummary = {
    retrievedCount: 0,
    totalCount: 0,
  };

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);
    registerDestructor(this, () => {
      this.resetPageFrom();
    });
  }

  get rowsPerPage() {
    return 50;
  }

  get filters(): LogicalFilter {
    let chartSeriesFilters = this.chartSeries.filters;
    let reportFilters = this.args.renderableChart.reportState.filters;

    return {
      type: 'and',
      filters: [chartSeriesFilters, reportFilters],
    };
  }

  get initialSortBy() {
    return this.reportingUnderlyingDataService.getSortByAttributeId(
      this.metric.datasetId,
      this.metric,
    );
  }

  get chartSeries(): ChartSeries {
    return this.args.renderableChart.chartSeries.firstObject;
  }

  get metric(): Metric {
    return this.chartSeries.metric;
  }

  get range() {
    return this.args.renderableChart.dateRange;
  }

  get drillInTimeRange(): FlexibleQueryTimeRange {
    let dataModel;

    if (this.metric.type === 'field') {
      dataModel = timeDataModelForDrillIn((this.metric as FieldMetric).property);
    }

    return {
      start: this.range.startMoment.valueOf(),
      end: this.range.endMoment.valueOf(),
      interval: this.range.interval,
      property: this.metric.timeProperty,
      data_model: dataModel,
    };
  }

  get exportButtonTooltip() {
    if (this.summary.totalCount > 10000) {
      return this.intl.t(
        'components.reporting.custom.report.data-table.export-csv-disabled-tooltip',
      );
    } else {
      return this.intl.t('components.reporting.custom.report.data-table.export-csv-tooltip');
    }
  }

  get hasData() {
    return this.summary.totalCount > 0;
  }

  get countSummary() {
    return this.intl.t('reporting.data-table.view-summary', {
      currentCount: this.intl.formatNumber(this.summary.retrievedCount),
      totalCount: this.intl.formatNumber(this.summary.totalCount),
    });
  }

  get timezone() {
    return this.args.renderableChart.reportState?.timezone || this.appService.app.timezone;
  }

  get columns() {
    // These are hardcoded to what's shown in the CSAT remarks table for now
    let baseColumns = [
      'conversation_rating_sent.rating_index',
      'conversation_rating_sent.remark',
      'conversation.companies.name',
      'conversation.first_user.name',
      'conversation_rating_sent.rated_actor_type',
      'conversation.id',
    ];
    return this.metric.id === 'conversation_rating.any_agent.count'
      ? [
          ...baseColumns.slice(0, 1),
          'conversation_rating_sent.rated_actor_id.human_id',
          ...baseColumns.slice(1),
        ]
      : baseColumns;
  }

  resetPageFromOnFiltersChange = modifier((_: any) => {
    this.resetPageFrom();
  });

  @action
  updateCountAndExportFunctions(summary: CountSummary) {
    this.summary = summary;
    this.args.setDataTableExportFunction(this.exportCsv);
    this.args.setDataTableTotalCount(summary.totalCount);
  }

  @action
  onHoverExportButton() {
    if (this.summary.totalCount > 10000 || this.summary.totalCount === 0) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'hovered_disabled_export_button',
        object: 'data_table',
        row_count: this.summary.totalCount,
      });
    }
  }

  get sendViaMail() {
    return this.summary.totalCount > 10_000;
  }

  @action
  onSortUpdate(sortBy: string, sortDirection: 'asc' | 'desc') {
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
  }

  @action
  resetPageFrom() {
    this.pageFrom = 0;
  }

  @action
  updatePageFrom(pages: number) {
    this.pageFrom += pages;
  }

  @action
  exportCsv() {
    let analyticsEvent = { object: 'data_table', rows: this.summary.totalCount };
    this.reportingCsvExport.exportCsv(
      this.metric.datasetId,
      this.filters,
      this.columns,
      this.drillInTimeRange,
      this.sortBy,
      this.sortDirection,
      false,
      analyticsEvent,
      this.timezone,
      this.sendViaMail,
      this.args.renderableChart,
      this.chartSeries,
    );
  }

  @action
  onClose() {
    if (this.navbarCollapsingService.collapsed) {
      this.navbarCollapsingService.toggleNavbarCollapsed();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Flexible::DataTable': typeof DataTable;
    'reporting/flexible/data-table': typeof DataTable;
  }
}
