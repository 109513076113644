/* import __COLOCATED_TEMPLATE__ from './topic-definition-pills.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { indexBy } from 'underscore';

export default class TopicDefinitionPillsComponent extends Component {
  @tracked customAttributeFilterLabels = [];
  @service store;
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get participantTypes() {
    return this.args.topic.authorTypeIsAny
      ? this.intl.t(
          'reporting.conversational-insights.topic-definition-pills.participant-types.any-participant',
        )
      : this.intl.t(
          'reporting.conversational-insights.topic-definition-pills.participant-types.participant-is',
          {
            participantType: this.args.topic.authorTypeNames.join(', '),
          },
        );
  }

  get ccdaOperatorLabels() {
    return {
      nin: this.intl.t(
        'reporting.conversational-insights.topic-definition-pills.operator-labels.is-not',
      ),
      eq: this.intl.t(
        'reporting.conversational-insights.topic-definition-pills.operator-labels.equal',
      ),
      unknown: this.intl.t(
        'reporting.conversational-insights.topic-definition-pills.operator-labels.unknown',
      ),
      known: this.intl.t(
        'reporting.conversational-insights.topic-definition-pills.operator-labels.known',
      ),
    };
  }

  @task
  *fetchConversationAttributeDescriptors() {
    if (!this.app.canUseFeature('conversation_attributes')) {
      return;
    }

    let allDescriptors = yield ConversationAttributeDescriptor.peekAllAndMaybeLoad();
    let descriptors = indexBy(allDescriptors.toArray(), 'id');

    this.customAttributeFilterLabels = this.args.topic.conversationCustomData.map((filter) =>
      this.buildCustomAttributeFilterLabel({ descriptors, filter }),
    );
  }

  buildCustomAttributeFilterLabel({ filter, descriptors }) {
    let descriptor = descriptors[filter.field_id];
    let printableOperatorLabel = this.ccdaOperatorLabels[filter.operator];
    let output = [descriptor.name, printableOperatorLabel];

    let filterHasListOfValues = filter.operator === 'eq' || filter.operator === 'nin';

    if (filterHasListOfValues) {
      if (descriptor.dataType === 'list') {
        let valueLabels = indexBy(descriptor.listOptions.toArray(), 'listOptionId');
        output.push(filter.values.map((id) => valueLabels[id].label).join(', '));
      } else if (descriptor.dataType === 'boolean') {
        output.push(filter.values.join(', '));
      }
    }

    return output.join(' ');
  }
}
