/* import __COLOCATED_TEMPLATE__ from './metric-cell.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
import templateOnlyComponent from '@ember/component/template-only';
import type { ChartSeriesDefinition } from 'embercom/components/reporting/automation/ai-insights/fin-insights/jobs';

interface Signature {
  Element: HTMLElement;
  Args: {
    chartSeries: ChartSeriesDefinition;
    value: number | string | null;
    onExploreConversations: () => void;
  };
}

const MetricCell = templateOnlyComponent<Signature>();
export default MetricCell;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::FinInsights::MetricCell': typeof MetricCell;
    'reporting/automation/ai-insights/fin-insights/metric-cell': typeof MetricCell;
  }
}
