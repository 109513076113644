/* import __COLOCATED_TEMPLATE__ from './data-table-cell.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';

interface TeammateMapping {
  id: number;
  name: string;
}

interface KnowledgeHubContentReference {
  id: number;
  title: string;
  localized_entity_name: string;
  entity_type: number;
  deleted: boolean;
}

type Args = {
  dataType: string;
  value: any;
};

export default class DataTableCell extends Component<Args> {
  @action
  formatAdminNames(cellValue: TeammateMapping | TeammateMapping[]) {
    let valueArray = [cellValue].flat();
    return valueArray.map((admin) => admin.name).join(', ');
  }

  @action
  formatKnowledgeHubContentReferences(
    cellValue: KnowledgeHubContentReference | KnowledgeHubContentReference[],
  ) {
    let valueArray = [cellValue].flat();
    return valueArray
      .map((item: KnowledgeHubContentReference) => `${item.title} (${item.localized_entity_name})`)
      .join(', ');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::DataTableCell': typeof DataTableCell;
  }
}
