/* import __COLOCATED_TEMPLATE__ from './treemap.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ChartDataResource from 'embercom/lib/reporting/chart-data-resource';
import { useResource } from 'ember-resources';
import { isEmpty, isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import { restartableTask } from 'ember-concurrency-decorators';
import { get } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { action } from '@ember/object';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import Highcharts, { type Options, type SeriesOptionsType } from 'highcharts';
import { SERIES_COLOR_PALETTE } from 'embercom/lib/reporting/flexible/constants';
import { cached } from 'tracked-toolbox';

// TODO localise all strings once they are confirmed
/* eslint-disable @intercom/intercom/no-bare-strings */
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Bespoke::AiInsights::Curate::Treemap': typeof TreemapChart;
  }
}

interface TreemapArgs {
  renderableChart: RenderableChart;
  extraContext: {
    onSelectedTopicChanged: (topicName: string | null, subtopics: string[]) => void;
    topTopicsDisplayed?: number;
  };
}

interface SignalFlexibleResponseViewBy {
  groups: Array<{
    values: string[];
    aggregations: Array<{ name: string; values: number[] }>;
    name: string;
    type: string;
  }>;
}

interface Topic {
  name: string;
  description: string;
  subtopics: Set<string>;
}

interface TopicsHash {
  [key: string]: Topic;
}

interface SerializedSubtopic {
  topic_name: string;
  topic_description: string;
  subtopic_name: string;
}

interface HighchartsNode {
  id: string;
  parent: string;
  name: string;
  value: number;
  metricValue: number | undefined;
  color?: string;
  isSubtopic: boolean;
}

const TOPICS_SERIES_NAME = 'Topics';

export default class TreemapChart extends Component<TreemapArgs> {
  @service declare appService: $TSFixMe;
  @service declare reportingMetrics: ReportingMetrics;

  @tracked topics: Record<string, Topic> = {};
  @tracked topicLimit = this.args.extraContext.topTopicsDisplayed || 20;
  @tracked currentNodeId: string | null = null;
  highchartsChart: any;

  constructor(owner: unknown, args: TreemapArgs) {
    super(owner, args);
    taskFor(this.loadTopics).perform();
  }

  dataResource = useResource(this, ChartDataResource, () => ({
    renderableChart: this.args.renderableChart,
  }));

  // This populates the topics hash with a structure like this:
  // { topicName: { subtopicName: true } }
  @restartableTask
  *loadTopics() {
    let response = (yield get('/ember/ai_insights/topics', {
      app_id: this.appService.app.id,
    })) as { topics: SerializedSubtopic[] };
    let topics = {} as TopicsHash;
    for (let entry of response.topics) {
      let topic = topics[entry.topic_name] || {
        name: entry.topic_name,
        description: entry.topic_description,
        subtopics: new Set(),
      };
      topic.subtopics.add(entry.subtopic_name);
      topics[topic.name] = topic;
    }
    this.topics = topics;
  }

  get currentTreeLevel() {
    if (isPresent(this.currentNodeId)) {
      return Number.parseInt(this.currentNodeId!.split('.')[0], 10) || 0;
    }
    return 0;
  }

  get currentTopicName() {
    if (isPresent(this.currentNodeId)) {
      return this.highchartsChart.series[0].nodeMap[this.currentNodeId!]?.name;
    }
    return 'Topics';
  }

  @action
  updateChartReference() {
    // The chart changes every time the metric is changed, so we need to find the chart by the series name
    this.highchartsChart = Highcharts.charts.find(
      (chart) => chart && chart.series[0].name === TOPICS_SERIES_NAME,
    );
  }

  hexColorPalette = SERIES_COLOR_PALETTE.map((colorVar) => {
    let style = getComputedStyle(document.body);
    return style.getPropertyValue(`--${colorVar}-70`);
  });

  getColor(index: number) {
    return this.hexColorPalette[index % this.hexColorPalette.length];
  }

  @cached
  get sortedTopicData() {
    // First gather all the data for each series into a hash with the structure:
    // {
    //   [topicName]: {
    //     name: string,
    //     value: number, // sum of the volumes for the topic
    //     subtopics: {
    //       [subtopicName]: {
    //         name: string,
    //         value: number,  // value from the first series (volume)
    //         metricValue: number,  // value from the second series (metric)
    //       },
    //       ...
    //     },
    //   },
    //   ...
    // }
    let topicHierarchy = this.dataResource.rawChartData.reduce(
      (acc: Record<string, any>, response: SignalFlexibleResponseViewBy, seriesIndex: number) => {
        response.groups[0].values.forEach((topicSubtopicPair, topicSubtopicPairIndex) => {
          // TODO: the toString() is a hack to allow tests to pass, we should just write the correct needed tests
          let [topicName, subtopicName] = topicSubtopicPair.toString().split(':');
          // ignore subtopics that don't belong to the topic
          if (this.topics[topicName]?.subtopics.has(subtopicName)) {
            acc[topicName] ||= {
              name: topicName,
              value: undefined,
              subtopics: {},
            };
            // The first series is the volume
            if (seriesIndex === 0) {
              // sum the volume for the topic
              let subtopicValue = response.groups[0].aggregations[0].values[topicSubtopicPairIndex];
              if (subtopicValue) {
                acc[topicName].value ||= 0;
                acc[topicName].value += subtopicValue;
              }

              acc[topicName].subtopics[subtopicName] = {
                name: subtopicName,
                value: subtopicValue,
              };
            }
          }
        });
        return acc;
      },
      {},
    );

    return Object.values(topicHierarchy).sort(
      (topicA: any, topicB: any) => topicB.value - topicA.value,
    );
  }

  get chartData() {
    if (isEmpty(this.topics)) {
      return [];
    }

    if (isPresent(this.dataResource.rawChartData)) {
      // Limit to the top N topics, map it to the format needed for highcharts
      // {
      //   id: '1.1',
      //   parent: '',
      //   name: 'Topic 1',
      //   value: 123,
      //   metricValue: 0.5,
      // },
      // ...
      let allNodes: HighchartsNode[] = this.sortedTopicData
        .slice(0, this.topicLimit)
        .reduce((acc: HighchartsNode[], topic: any, index: number) => {
          let topicNode = {
            id: `1.${index + 1}`,
            parent: '',
            name: topic.name,
            value: topic.value,
            metricValue: undefined,
            color: this.getColor(index) || 'rgba(255,255,255,0)',
            isSubtopic: false,
          };
          let subTopicNodes = Object.values(topic.subtopics).map((subtopic: any, index: number) => {
            return {
              id: `${topicNode.id}.${index + 1}`,
              parent: topicNode.id,
              name: subtopic.name,
              value: subtopic.value,
              metricValue: undefined,
              color: undefined,
              isSubtopic: true,
            };
          });
          return acc.concat(topicNode, ...subTopicNodes);
        }, []) as HighchartsNode[];

      return allNodes;
    } else {
      return [];
    }
  }

  get percentageCover() {
    if (isEmpty(this.dataResource.rawChartData)) {
      return 0;
    }

    let totalVolume = 0;
    let displayedVolume = 0;
    this.sortedTopicData.forEach((topic: { value: number }, index: number) => {
      totalVolume += topic.value;
      if (index < this.topicLimit) {
        displayedVolume += topic.value;
      }
    });

    return Number(100 * (displayedVolume / totalVolume)).toFixed(1);
  }

  @action
  onTopicClick(event: any) {
    let currentRootNode = event.point.series.rootNode;
    let pointId = event.point.id;
    // Ignore clicks on subtopics, which have ids like '1.1.1'
    if (pointId.split('.').length <= 2) {
      let newNodeId = currentRootNode === pointId ? null : pointId;
      this.changeNodeTo(newNodeId);
    }
  }

  @action
  goBackToTopics() {
    this.changeNodeTo(null, true);
  }

  get chartOptions(): Options {
    return {
      plotOptions: {
        series: {
          borderWidth: 1,
        },
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
    };
  }

  get highchartsSeries(): SeriesOptionsType[] {
    let component = this;
    return [
      {
        type: 'treemap',
        name: TOPICS_SERIES_NAME,
        turboThreshold: 0,
        data: this.chartData,
        breadcrumbs: {
          floating: true,
        },
        allowTraversingTree: true,
        layoutAlgorithm: 'squarified',
        animation: false,
        borderWidth: 1,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          style: {
            color: 'black',
            textOutline: '0px white',
            fontWeight: 'normal',
          },
        },
        events: {
          click: this.onTopicClick.bind(this),
          afterAnimate: this.onAfterAnimate.bind(this),
        },
        levels: [
          {
            level: 1,
            borderWidth: 3,
            dataLabels: {
              enabled: true,
              style: {
                fontSize: '14px',
              },
            },
            // @ts-ignore
            levelIsConstant: false,
          },
          {
            level: 2,
            borderWidth: 1,
            borderColor: '#ffffff',
            dataLabels: {
              enabled: false,
              style: {
                fontSize: '13px',
              },
            },
            // @ts-ignore
            levelIsConstant: true,
          },
        ],
        tooltip: {
          headerFormat: '',
          // eslint-disable-next-line object-shorthand
          pointFormatter: function (this: any): string {
            let point = this;
            let typeLabel = point.isSubtopic ? 'Subtopic' : 'Topic';
            let topicName = point.name;
            let description;
            if (!point.isSubtopic) {
              description = component.topics[topicName]?.description;
            }

            let value =
              `<b>${typeLabel}:</b> ${topicName}<br>` + `<b>Conversations:</b> ${point.value}`;
            if (description) {
              value += `<br>${description}`;
            }
            return value;
          },
        },
        accessibility: {
          exposeAsGroupOnly: true,
        },
      },
    ];
  }

  @action
  onTopicLimitChange(event: any) {
    this.topicLimit = event.target.value;
  }

  @action
  onAfterAnimate() {
    this.updateChartReference();
    this.changeNodeTo(null);
  }

  changeNodeTo(nodeId: string | null, drillUp = false) {
    // When drilling down, show only the labels for the current level
    if (nodeId === null) {
      this.highchartsChart.series[0].options.levels[1].dataLabels.enabled = false;
    } else {
      this.highchartsChart.series[0].options.levels[1].dataLabels.enabled = true;
    }

    if (drillUp) {
      this.highchartsChart.series[0].drillUp();
    }
    this.currentNodeId = nodeId;

    let topicName = nodeId ? this.highchartsChart.series[0].nodeMap[nodeId]?.name : null;
    let subtopics = topicName ? Array.from(this.topics[topicName]?.subtopics || []) : [];

    this.args.extraContext.onSelectedTopicChanged(topicName, subtopics);
  }
}
