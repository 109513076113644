/* import __COLOCATED_TEMPLATE__ from './suggestion-card.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */
import Component from '@glimmer/component';
import { ReviewType } from 'embercom/models/content-service/content-review-request';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { EntityType } from 'embercom/models/data/entity-types';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import { tracked } from '@glimmer/tracking';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { type ReviewableContent } from 'embercom/components/knowledge-hub/content-editor/content-review';
import { action } from '@ember/object';
import { EditorMode } from 'embercom/components/knowledge-hub/content-editor/drawer-editor';
import { isPresent } from '@ember/utils';

interface Args {
  suggestion: ContentWrapper;
  loadSuggestions: () => Promise<void>;
  showEditButton?: boolean;
  canOpenReviewEditor?: boolean;
}

export default class SuggestionCard extends Component<Args> {
  @service declare intl: IntlService;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;
  @service declare knowledgeHubService: KnowledgeHubService;

  @tracked openReviewEditor = false;
  @tracked declare suggestionContent: ReviewableContent;

  get reviewMode() {
    return EditorMode.REVIEW;
  }

  get showEditButton() {
    return this.args.showEditButton ?? true;
  }

  get canOpenReviewEditor() {
    return this.args.canOpenReviewEditor ?? true;
  }

  get icon() {
    switch (this.args.suggestion.entityType) {
      case EntityType.ArticleContent:
        return 'article';
      case EntityType.ContentSnippet:
        return 'note';
      default:
        return 'note';
    }
  }

  get title() {
    let objectName = objectNames[this.args.suggestion.entityType];
    let reviewType = this.args.suggestion.contentReviewType;

    if (reviewType === ReviewType.CREATE_CONTENT) {
      return this.intl.t(`knowledge-hub.content-editor.drawer-editor.titles.add-${objectName}`);
    } else if (reviewType === ReviewType.EDIT_CONTENT) {
      return this.intl.t(`knowledge-hub.content-editor.drawer-editor.titles.edit-${objectName}`);
    }
    return this.intl.t(
      `knowledge-hub.content-editor.drawer-editor.titles.fallback-title-${objectName}`,
    );
  }

  get entityTypeName() {
    return objectNames[this.args.suggestion.entityType];
  }

  get cardDescription() {
    return isPresent(this.args.suggestion.contentData.latest_content_review_request_reason)
      ? `${this.args.suggestion.contentData.latest_content_review_request_reason}.`
      : undefined;
  }

  @action
  onCloseReviewEditor() {
    this.args.loadSuggestions();
    this.openReviewEditor = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::SuggestionCard': typeof SuggestionCard;
  }
}
