/* import __COLOCATED_TEMPLATE__ from './fin-insights.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
import Component from '@glimmer/component';

import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { use } from 'ember-resources/util/function-resource';
import { inject as service } from '@ember/service';
import { cached } from 'tracked-toolbox';
import type IntlService from 'ember-intl/services/intl';
import { indexBy } from 'underscore';
import { fetchTopicsTaxonomy } from 'embercom/components/reporting/automation/ai-insights/fin-insights/topics-data-resources';
import type {
  Topic,
  TopicTaxonomy,
} from 'embercom/components/reporting/automation/ai-insights/fin-insights/topics-data-resources';
import {
  jobs,
  type JobId,
} from 'embercom/components/reporting/automation/ai-insights/fin-insights/jobs';

interface Args {}

export default class FinInsights extends Component<Args> {
  @service declare appService: any;
  @service declare intl: IntlService;

  @tracked selectedJobId: JobId = 'identifyFinContentGaps';
  @tracked currentTopicId: string | null = null;

  @tracked exploringTopicId: string | null = null;
  @tracked exploringSubtopicId: string | null = null;
  @tracked showSidePanel = false;
  @tracked selectedTopicObject: Topic | null = null;
  @tracked selectedSubTopic: string | null = null;
  @tracked type: 'suggestions' | 'conversations' = 'suggestions';
  @tracked metricsForRow: Record<string, string> | undefined = undefined;

  get isStandaloneApp() {
    return this.appService.app.isStandaloneApp;
  }

  @use topicsTaxonomy = AsyncData<TopicTaxonomy>(({ abortSignal }) =>
    fetchTopicsTaxonomy(this.appService.app.id, abortSignal),
  );

  getTopicById(id: string | null) {
    if (id === null) {
      return null;
    }
    return this.topicsTaxonomy.value?.[id] ?? null;
  }

  get currentTopic() {
    return this.getTopicById(this.currentTopicId);
  }

  get exploringTopic() {
    return this.getTopicById(this.exploringTopicId);
  }

  get serializedReport() {
    return {
      id: 'fin-insights',
      date_range: {
        selection: 'past_4_weeks',
      },
    };
  }

  @cached
  get jobs() {
    return indexBy(jobs(this.intl), (job) => job.id);
  }

  get availableJobs() {
    return Object.values(this.jobs).flat();
  }

  get selectedJob() {
    return this.jobs[this.selectedJobId];
  }

  @action navigateToTopic(topicId: string) {
    this.currentTopicId = topicId;
  }

  @action
  onShowConversationExplorer(
    topic: string,
    subtopic: string | null,
    type: 'suggestions' | 'conversations',
    metrics: Record<string, string> | undefined,
  ) {
    this.exploringTopicId = topic;
    this.exploringSubtopicId = subtopic;
    this.type = type;
    this.metricsForRow = metrics;
    this.showSidePanel = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::FinInsights': typeof FinInsights;
  }
}
