/* import __COLOCATED_TEMPLATE__ from './input.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { INFINITY, type Label } from 'embercom/models/reporting/custom/bucket-interval';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from 'underscore';
import type IntlService from 'ember-intl/services/intl';

const DEFAULT_LABEL = 's';
interface Args {
  label: Label | null;
  value: number;
  placeholder: number;
  updateIntervalValue: (value: number) => void;
  updateIntervalLabel: (label: Label | null) => void;
}

export default class Input extends Component<Args> {
  @service declare appService: any;
  @service declare intl: IntlService;

  @tracked inputValue: number | undefined = this.args.value;
  @tracked rawLabelValue: Label | null = this.args.label;

  get label() {
    return this.labelMapping(this.rawLabelValue);
  }

  get placeholder() {
    if (Number.isFinite(this.args.placeholder)) {
      return this.args.placeholder;
    }
    return INFINITY;
  }

  get groupList() {
    return [
      {
        items: [
          {
            text: this.intl.t('reporting.custom-reports.chart.side-panel.secs'),
            value: 's',
          },
          {
            text: this.intl.t('reporting.custom-reports.chart.side-panel.mins'),
            value: 'm',
          },
          {
            text: this.intl.t('reporting.custom-reports.chart.side-panel.hours'),
            value: 'h',
          },
          {
            text: this.intl.t('reporting.custom-reports.chart.side-panel.days'),
            value: 'd',
          },
        ],
      },
    ];
  }

  get value() {
    let value = this.inputValue;
    if (value === undefined || Number.isFinite(value)) {
      return value;
    } else {
      return INFINITY;
    }
  }

  get disableDropdown() {
    return this.rawLabelValue === null && !Number.isFinite(this.inputValue);
  }

  @action
  handleInput(event: Event) {
    let value = (event.target as HTMLInputElement).value;
    if (isEmpty(value)) {
      this.inputValue = undefined;
      let rawLabel: Label | null = Number.isFinite(this.args.placeholder) ? DEFAULT_LABEL : null;
      this.updateLabel(rawLabel);
      this.args.updateIntervalValue(this.args.placeholder);
    } else {
      let cleanedValue = value
        .split('')
        .map((char) => {
          let num = Number(char);
          return isNaN(num) ? undefined : num;
        })
        .compact()
        .join('');
      this.inputValue = isEmpty(cleanedValue) ? this.args.placeholder : Number(cleanedValue);
      this.args.updateIntervalValue(this.inputValue);
      if (cleanedValue && this.rawLabelValue === null) {
        this.updateLabel(DEFAULT_LABEL);
      }
    }
  }

  labelMapping(label: Label | null) {
    switch (label) {
      case 's':
        return this.intl.t('reporting.custom-reports.chart.side-panel.secs');
      case 'm':
        return this.intl.t('reporting.custom-reports.chart.side-panel.mins');
      case 'h':
        return this.intl.t('reporting.custom-reports.chart.side-panel.hours');
      case 'd':
        return this.intl.t('reporting.custom-reports.chart.side-panel.days');
      default:
        return this.intl.t('reporting.custom-reports.chart.side-panel.none');
    }
  }

  @action
  updateLabel(option: Label | null) {
    this.rawLabelValue = option;
    this.args.updateIntervalLabel(option);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::TimeInterval::Input': typeof Input;
    'reporting/custom/chart-builder/time-interval/input': typeof Input;
  }
}
