/* import __COLOCATED_TEMPLATE__ from './chart-drill-in.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import type Chart from 'embercom/models/reporting/custom/chart';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ChartSeries from 'embercom/models/reporting/custom/chart-series';
import type Store from '@ember-data/store';
import type { PercentageMetric } from 'embercom/objects/reporting/unified/metrics/types';

function and(...filters: any) {
  return {
    type: 'and',
    filters: filters.flatMap((f: any) => (f.type === 'and' ? f.filters : f)),
  };
}

interface Args {
  isOpen: boolean;
  onClose: () => void;
  shouldExpandNavOnClose: boolean;
  chart: Chart;
  chartSeries: ChartSeries;
  renderableChart: RenderableChart;
  knownValuesEndpointSources: string[];
  conversationAttributeDescriptors: Array<ConversationAttributeDescriptor>;
  columns: string[];
  setColumns: (chartSeries: ChartSeries, columns: string[]) => void;
  isReportLevel: boolean;
  eventPlace: string;
  isStandalone?: boolean;
  sideDrawerWidth?: string;
}

export default class ReportingCustomReportChartDrillIn extends Component<Args> {
  @service navbarCollapsingService: any;
  @service declare store: Store;

  @action
  onClose() {
    if (this.navbarCollapsingService.collapsed && this.args.shouldExpandNavOnClose) {
      this.navbarCollapsingService.toggleNavbarCollapsed();
    }
    this.args.onClose();
  }

  get headerName() {
    return this.args.renderableChart.metricDescription(this.args.chartSeries);
  }

  get chartSeriesForDrillIn() {
    let metric = this.args.chartSeries.metric as PercentageMetric;

    if (metric.showDenominatorWithNumeratorFilters) {
      let chartSeriesFilters = this.args.chartSeries.filters || { type: 'and', filters: [] };
      let numeratorMetricFilters = metric.numerator.filter || { type: 'and', filters: [] };

      if (chartSeriesFilters.type !== 'or' && numeratorMetricFilters.type !== 'or') {
        return this.store.createFragment(ChartSeries.modelName, {
          metricId: metric.denominator.id,
          filters: and(chartSeriesFilters, numeratorMetricFilters),
        });
      }
    }

    return this.args.chartSeries;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ChartDrillIn': typeof ReportingCustomReportChartDrillIn;
    'reporting/custom/report/chart-drill-in': typeof ReportingCustomReportChartDrillIn;
  }
}
