/* import __COLOCATED_TEMPLATE__ from './sla-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

export default class SlaFilter extends Component {
  @service appService;
  @service intercomEventService;
  @service store;
  @service reportingUnderlyingDataService;
  @service intl;

  @tracked availableSlas = {};
  @tracked filteredSlas = {};
  @tracked selectedDropdownItems = this.args.selected.values || [];
  @tracked operator = this.args.selected.operator || 'category';
  @tracked label = this.currentLabel;

  constructor() {
    super(...arguments);
    this.availableSlas = this.reportingUnderlyingDataService.getAttributeKnownValues(
      'conversation_sla_status_log.conversation_sla_id',
    );
  }

  get allItems() {
    return Object.entries(this.filteredSlas).map(([slaId, slaName]) => ({
      text: slaName,
      value: slaId,
      isSelected: this.selectedDropdownItems.includes(slaId),
    }));
  }

  get selectedSlas() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get currentLabel() {
    if (this.selectedSlas.length === 0) {
      return this.intl.t('components.reporting.custom.chart-builder.filter-bar.sla-filter.any');
    }

    let slas = this.selectedSlas.map((slaId) => {
      return this.availableSlas[Number(slaId)];
    });
    return `${slas.join(', ')}`;
  }

  @action
  trackAnalytics() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_sla',
      object: this.args.analyticsObject,
      sla: this.selectedSlas,
      operator: this.operator,
    });
  }

  @action
  onClose() {
    if (this.isDestroying) {
      // for some reason onClose gets triggered when you remove the filter
      // even if the dropdown isn't currently open
      return;
    }

    this.label = this.currentLabel;
  }

  @action
  filterValues(filter) {
    let filteredEntries = Object.entries(this.availableSlas).filter(([_, slaName]) =>
      slaName?.toLowerCase()?.includes(filter.toLowerCase()),
    );

    this.filteredSlas = Object.fromEntries(filteredEntries);
  }
}
