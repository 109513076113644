/* import __COLOCATED_TEMPLATE__ from './title-cell.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type EntityType } from 'embercom/models/data/entity-types';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

interface Args {
  data: any;
  row: any;
}

export default class TitleCell extends Component<Args> {
  @service declare knowledgeHubService: KnowledgeHubService;
  @tracked content: LocalizedKnowledgeContent | null = null;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    taskFor(this.fetchContent).perform();
  }

  get contentType(): EntityType {
    let entityTypeString = this.args.row.groupName?.split(':')[0];
    return parseInt(entityTypeString, 10);
  }

  get contentId() {
    return this.args.row.groupName?.split(':')[1];
  }

  get isLoading() {
    return taskFor(this.fetchContent).isRunning;
  }

  get title() {
    if (this.isLoading) {
      return;
    }
    return this.content?.latestVersion?.versionTitle || this.content?.title;
  }

  @task({ drop: true })
  *fetchContent() {
    try {
      this.content = yield this.knowledgeHubService.findContent({
        contentId: this.contentId,
        contentType: this.contentType,
        peekFirst: true,
      });
    } catch (_e) {
      this.content = null;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Flexible::KnowledgeHub::TitleCell': TitleCell;
  }
}
