/* import __COLOCATED_TEMPLATE__ from './conversation-state-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

export default class ConversationStateFilter extends Component {
  @service intercomEventService;
  @service intl;
  // store the selection here so that the UI doesn't update until the drop down is closed
  @tracked selectedDropdownItems = this.args.selected.values || [];
  @tracked operator = this.args.selected.operator || 'category';

  get conversationStateLabels() {
    return {
      opened: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.conversation-state-filter.opened',
      ),
      closed: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.conversation-state-filter.closed',
      ),
      snoozed: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.conversation-state-filter.snoozed',
      ),
    };
  }

  get selectedStates() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get allItems() {
    return Object.entries(this.conversationStateLabels).map(([value, text]) => ({
      text,
      value,
      isSelected: this.selectedDropdownItems.includes(value),
    }));
  }

  @action
  trackAnalytics() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_conversation_state',
      object: this.args.analyticsObject,
      state: this.selectedStates,
      operator: this.operator,
    });
  }

  get currentLabel() {
    if (
      this.selectedDropdownItems?.length &&
      this.selectedDropdownItems[0] !== REPORTING_FILTER_SELECT_ALL
    ) {
      return this.selectedDropdownItems
        .map((value) => this.conversationStateLabels[value])
        .join(', ');
    } else {
      return this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.conversation-state-filter.any',
      );
    }
  }
}
