/* import __COLOCATED_TEMPLATE__ from './conversation-stream-options-dropdown-item.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import templateOnlyComponent from '@ember/component/template-only';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export interface Args {
  item: {
    text: string;
    icon: InterfaceIconName;
    isSelected: boolean;
    onSelectItem: () => void;
  };
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const ConversationStreamOptionsDropdownItem = templateOnlyComponent<Signature>();
export default ConversationStreamOptionsDropdownItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ConversationStream::ConversationStreamOptionsDropdownItem': typeof ConversationStreamOptionsDropdownItem;
    'reporting/custom/report/conversation-stream/conversation-stream-options-dropdown-item': typeof ConversationStreamOptionsDropdownItem;
  }
}
