/* import __COLOCATED_TEMPLATE__ from './fin-last-answer-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import type IntlService from 'embercom/services/intl';
import type { SafeString } from '@ember/template/-private/handlebars';
import { isEmpty } from '@ember/utils';
import { type SelectedFilter } from 'embercom/services/filter-pattern-service';
import { type FilterBarItem } from 'embercom/services/filter-pattern-service';

interface Args {
  initiallyOpened: boolean;
  icon: string;
  type: string;
  name: string;
  label: string;
  onClose: () => void;
  selected: SelectedFilter;
  setSelected: (filterValues: string[], operator?: string) => void;
  analyticsObject: string;
  removeFilter: () => void;
  isDisabled: boolean;
}

export enum LastAnswerType {
  Custom = 'custom_answer',
  AiGenerated = 'ai_answer',
  Any = REPORTING_FILTER_SELECT_ALL,
}

interface AnswerType {
  [key: string]: SafeString | string;
}

const ANALYTIC_NAME = 'fin_last_answer_type';

export default class FinLastAnswerFilter extends Component<Args> {
  @service declare appService: any;
  @service intercomEventService: any;
  @service intl!: IntlService;
  @tracked label = this.currentLabel;
  @tracked selectedDropdownItems: string[] = this.args.selected.values || [];
  @tracked operator: string = this.args.selected.operator || 'category';

  get currentLabel() {
    if (
      isEmpty(this.selectedDropdownItems) ||
      this.selectedDropdownItems[0] === LastAnswerType.Any
    ) {
      return this.anyAnswerType[LastAnswerType.Any].toString();
    } else {
      let allAnswerTypes = { ...this.anyAnswerType, ...this.answerTypes };
      let translatedSelectedDropdownItems = this.selectedDropdownItems.map(
        (value) => allAnswerTypes[value],
      );
      return translatedSelectedDropdownItems.join(', ');
    }
  }

  private get anyAnswerType(): AnswerType {
    return {
      [LastAnswerType.Any]: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.fin-last-answer-type.any',
      ),
    };
  }

  private get answerTypes(): AnswerType {
    return {
      [LastAnswerType.Custom]: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.fin-last-answer-type.custom',
      ),
      [LastAnswerType.AiGenerated]: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.fin-last-answer-type.ai-generated',
      ),
    };
  }

  private mapToDropDownItems(answerTypes: AnswerType): FilterBarItem[] {
    return Object.entries(answerTypes).map(([key, translationKey]) => ({
      text: translationKey.toString(),
      value: key,
      isSelected: this.selectedDropdownItems.includes(key),
    }));
  }

  get allItems() {
    return this.mapToDropDownItems(this.answerTypes);
  }

  @action
  trackAnalytics() {
    this.intercomEventService.trackAnalyticsEvent({
      action: ANALYTIC_NAME,
      object: this.args.analyticsObject,
      operator: this.operator,
    });
  }

  @action
  onClose() {
    if (this.isDestroying) {
      return;
    }

    this.label = this.currentLabel;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::FilterBar::FinLastAnswerFilter': typeof FinLastAnswerFilter;
    'reporting/custom/chart-builder/filter-bar/fin-last-answer-filter': typeof FinLastAnswerFilter;
  }
}
