/* import __COLOCATED_TEMPLATE__ from './report-deprecation-banner.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type CustomReportsService from 'embercom/services/custom-reports-service';
import type RouterService from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';

interface Args {
  replacementTemplateId: string;
  onLinkClick?: () => void;
}

export default class ReportDeprecationBanner extends Component<Args> {
  @service declare customReportsService: CustomReportsService;
  @service declare router: RouterService;
  @tracked showTemplateModal = false;
  @tracked showTemplateId = this.args.replacementTemplateId;

  @action
  openModal() {
    if (!this.customReportsService.canChangeCustomReports) {
      this.customReportsService.loadChangePermissionModal();
    } else {
      this.showTemplateModal = true;
    }
  }

  @action
  onLinkClick() {
    if (this.args.onLinkClick) {
      this.args.onLinkClick();
    } else {
      this.router.transitionTo('apps.app.reports.views.view', 'shared-reports');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ReportV2::ReportDeprecationBanner': typeof ReportDeprecationBanner;
    'reporting/custom/report-v2/report-deprecation-banner': typeof ReportDeprecationBanner;
  }
}
