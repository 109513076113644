/* RESPONSIBLE TEAM: team-ai-insights */

import { get } from 'embercom/lib/ajax';

export interface Topic {
  name: string;
  description: string;
  subtopics: Set<string>;
}

export interface TopicTaxonomy {
  [key: string]: Topic;
}

interface SerializedSubtopic {
  topic_name: string;
  topic_description: string;
  subtopic_name: string;
}

async function fetchTopicsTaxonomy(appId: any, signal?: AbortSignal) {
  let response = (await get(
    '/ember/ai_insights/topics',
    {
      app_id: appId,
    },
    { signal },
  )) as { topics: SerializedSubtopic[] };

  let topics = {} as TopicTaxonomy;
  for (let entry of response.topics) {
    let topic = topics[entry.topic_name] || {
      name: entry.topic_name,
      description: entry.topic_description,
      subtopics: new Set(),
    };
    topic.subtopics.add(entry.subtopic_name);
    topics[topic.name] = topic;
  }
  return topics;
}

export { fetchTopicsTaxonomy };
