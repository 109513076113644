/* import __COLOCATED_TEMPLATE__ from './search-results-table.hbs'; */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import percent, { toPercentString } from 'embercom/lib/percentage-formatter';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isNumber } from 'underscore';
import storage from 'embercom/vendor/intercom/storage';
import ENV from 'embercom/config/environment';

export default class ArticlesSearchResultsTable extends Component {
  @service appService;
  @service intl;
  @service intercomEventService;
  @service notificationsService;

  tableName = 'articles-search-results-table';
  localStorageRegex = new RegExp(`.*-${this.appService.app.get('currentAdmin.id')}`);

  @tracked selectedKeyword = null;
  @tracked showSearchOverlay = false;
  @tracked currentRow = {};
  @tracked deletedRows = [];

  constructor() {
    super(...arguments);
    this.initDeletedRows();
  }

  initDeletedRows() {
    let matchingItemsFromStorage = storage.findItemsByRegex(/articles-reporting-searches-results/);
    let itemsForCurrentAdmin = matchingItemsFromStorage.filter((x) =>
      this.localStorageRegex.test(x.key),
    );
    this.deletedRows = itemsForCurrentAdmin.map((x) => x.value.groupName);
  }

  get columns() {
    let basicColumns = [
      {
        label: this.intl.t('reporting.articles.search-results.keyword'),
        isMain: true,
        valuePath: 'groupName',
        isSortable: false,
        componentData: {
          getKeyword: (row) => row.groupName,
          toggleSearchDrawer: (row) => this.toggleSearchDrawer(row),
        },
        component: 'reporting/flexible/table-search-result-keyword-cell',
      },
      {
        label: this.intl.t('reporting.articles.search-results.searches'),
        valuePath: 'search-count',
        tooltip: this.intl.t('reporting.articles.search-results.searches-tooltip'),
        isSortable: true,
        type: 'number',
      },
      {
        label: this.intl.t('reporting.articles.search-results.click'),
        valuePath: 'click-count',
        isSortable: true,
        tooltip: this.intl.t('reporting.articles.search-results.click-tooltip'),
        sortingHelper: (row) =>
          this.getPercentageForSorting(row['click-count'], row['search-count']),
        getRowValue: (row) => this.formatPercentage(row['click-count'], row['search-count']),
      },
      {
        label: this.intl.t('reporting.articles.search-results.action'),
        component: 'reporting/flexible/delete-action',
        icon: 'people',
        valuePath: 'displayAs',
        componentData: {
          deleteRow: (row) => this.deleteRow(row),
        },
      },
    ];

    if (this.appService.app.canUseArticleResultsShown) {
      basicColumns.splice(3, 0, {
        label: this.intl.t('reporting.articles.search-results.shown'),
        valuePath: 'result-count',
        tooltip: this.intl.t('reporting.articles.search-results.shown-tooltip'),
        isSortable: true,
        getRowValue: (row) => (Number.parseFloat(row['result-count']) || 0).toFixed(2),
      });
    }

    return basicColumns;
  }

  @action
  deleteRow(row) {
    storage.set(this.storageKey(row), row);
    this.currentRow = row;
    this.deletedRows = [...this.deletedRows, row.groupName];

    this.notificationsService.notifyConfirmationWithButton(
      this.intl.t('reporting.articles.search-results.banner-text'),
      {
        label: this.intl.t('reporting.articles.search-results.banner-button'),
        action: this.undoDeletion,
      },
      ENV.APP._10000MS,
      'search-results-delete-banner',
    );
  }

  @action
  undoDeletion() {
    storage.remove(this.storageKey(this.currentRow));
    this.deletedRows = this.deletedRows.filter((title) => title !== this.currentRow.groupName);
    this.currentRow = {};
  }

  storageKey(row) {
    return `articles-reporting-searches-results-${row.groupName}-${this.appService.app.get(
      'currentAdmin.id',
    )}`;
  }

  @action
  toggleSearchDrawer(row = null) {
    if (row !== null) {
      this.showSearchOverlay = true;
      this.selectedKeyword = row.groupName;

      this.intercomEventService.trackAnalyticsEvent({
        action: 'opened',
        object: 'search_drawer',
        place: this.tableName,
        section: 'reports',
        selectedKeyword: row.groupName,
      });
    } else {
      this.showSearchOverlay = false;
      this.selectedKeyword = null;
    }
  }

  formatPercentage(value, total) {
    if (!total) {
      return `\u2014`;
    }

    let rawPercentage = percent(total, value || 0);
    return toPercentString(rawPercentage, 2);
  }

  getPercentageForSorting(value, total) {
    if (!total) {
      return -1;
    }

    return percent(total, value || 0);
  }

  get dataConfig() {
    let baseConfig = {
      source: 'article_search_event',
      filter: {
        type: 'and',
        filters: [
          {
            type: 'exists',
            data: {
              property: 'normalized_query',
            },
          },
          {
            type: 'category',
            data: {
              property: 'profanity',
              values: [false],
            },
          },
          {
            type: 'not_in_category',
            data: {
              property: 'result_count',
              values: [0],
            },
          },
          {
            type: 'category',
            data: {
              property: 'name',
              values: ['Educate Search'],
            },
          },
          {
            type: 'range',
            data: {
              operators: ['gt'],
              property: 'length',
              values: [3],
            },
          },
        ],
      },
      time: {
        property: 'created_at',
        start: FILTER_PROPERTY_PLACEHOLDER,
        end: FILTER_PROPERTY_PLACEHOLDER,
      },
    };

    let baseClickConfig = {
      source: 'article_search_event',
      filter: {
        type: 'and',
        filters: [
          {
            type: 'exists',
            data: {
              property: 'normalized_query',
            },
          },
          {
            type: 'category',
            data: {
              property: 'profanity',
              values: [false],
            },
          },
          {
            type: 'category',
            data: {
              property: 'name',
              values: ['Educate Search Click'],
            },
          },
          {
            type: 'range',
            data: {
              operators: ['gt'],
              property: 'length',
              values: [3],
            },
          },
        ],
      },
      time: {
        property: 'created_at',
        start: FILTER_PROPERTY_PLACEHOLDER,
        end: FILTER_PROPERTY_PLACEHOLDER,
      },
    };

    return {
      columns: [
        {
          name: 'search-count',
          aggregation: {
            type: 'count',
            data: {
              property: 'search_id',
            },
          },
          ...baseConfig,
        },
        {
          name: 'result-count',
          aggregation: {
            type: 'mean',
            data: {
              property: 'result_count',
            },
          },
          ...baseConfig,
        },
        {
          name: 'click-count',
          aggregation: {
            type: 'count',
            data: {
              property: 'search_id',
            },
          },
          ...baseClickConfig,
        },
      ],
      rows: [
        {
          name: 'row-queries',
          type: 'term',
          data: {
            term_size: 10000,
            property: 'normalized_query',
          },
        },
      ],
    };
  }

  get viewConfig() {
    return {
      rowsVisible: 10,
      sortState: { valuePath: 'search-count', direction: 'desc' },
      isVisibleRowAfterLoading: (row) => {
        if (
          this.deletedRows.length !== 0 &&
          this.deletedRows.find((title) => title === row.groupName)
        ) {
          return false;
        }
        return isNumber(row['search-count']);
      },
    };
  }
}
