/* import __COLOCATED_TEMPLATE__ from './underlying-data-modal.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Metric from 'embercom/objects/reporting/unified/metrics/types';
import type { FieldMetric, FilterItem } from 'embercom/objects/reporting/unified/metrics/types';
import {
  type MetricProperty,
  type MetricPropertyDefinition,
} from 'embercom/objects/reporting/unified/properties/types';
import type IntlService from 'ember-intl/services/intl';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import { compact } from 'underscore';
import type Chart from 'embercom/models/reporting/custom/chart';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { cached } from 'tracked-toolbox';
import TicketType from 'embercom/models/inbox/ticket-type';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import type ChartBuilderFilterService from 'embercom/services/chart-builder-filter-service';
import {
  type Attribute,
  CONVERSATION_ATTRIBUTES,
  SYSTEM_DEFINED_ATTRIBUTES,
} from 'embercom/objects/reporting/unified/datasets/types';
import type ReportingUnderlyingDataService from 'embercom/services/reporting-underlying-data-service';
import type ApplicationInstance from '@ember/application/instance';
import { registerDestructor } from '@ember/destroyable';
import type { StreamResourceManager } from 'embercom/components/reporting/custom/report/drill-in-preview-panel';
import { type DropDownItem, groupDropDownItems } from '../chart-builder/chart-builder-helpers';
import type ReportingCsvExport from 'embercom/services/reporting-csv-export';
import {
  type LogicalFilterOperator,
  type LogicalFilter,
} from 'embercom/components/reporting/custom/filters';
import Range from 'embercom/models/reporting/range';
import { type FlexibleQueryTimeRange } from 'embercom/services/reporting-csv-export';
import { changeFilter } from 'embercom/lib/reporting/custom/filter-helpers';
import { timeDataModelForDrillIn } from 'embercom/lib/reporting/custom/data-config-builder-helpers';

interface Args {
  chart: Chart;
  chartSeries: ChartSeries;
  renderableChart: RenderableChart;
  knownValuesEndpointSources: string[];
  conversationAttributeDescriptors: Array<ConversationAttributeDescriptor>;
  columns: string[];
  setColumns: (chartSeries: ChartSeries, columns: string[]) => void;
  isReportLevel: boolean;
  streamResourceManager: StreamResourceManager;
  isStandalone?: boolean;
}
interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export interface CountSummary {
  retrievedCount: number;
  totalCount: number;
}

export default class UnderlyingDataModal extends Component<Signature> {
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare intercomEventService: any;
  @service declare chartBuilderFilterService: ChartBuilderFilterService;
  @service declare attributeService: any;
  @service declare reportingUnderlyingDataService: ReportingUnderlyingDataService;
  @service declare reportingCsvExport: ReportingCsvExport;

  @tracked sortBy: any = this.initialSortBy;
  @tracked sortDirection: 'asc' | 'desc' = 'desc';
  @tracked pageFrom = 0;
  @tracked private summary: CountSummary = {
    retrievedCount: 0,
    totalCount: 0,
  };
  selectedDropdownItems = this.args.columns;
  @tracked drillInDateRange: Range = Range.copy(this.args.renderableChart.dateRange);
  @tracked filters: LogicalFilter = this.initialFilters;
  previousSelection = this.args.columns;

  partDatasets = [
    'conversation_part',
    'reporting_conversation_part',
    'teammate_handling_conversation',
    'consolidated_conversation_part',
  ];

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);
    registerDestructor(this, () => {
      this.resetPageFrom();
    });
  }

  get rowsPerPage() {
    return 50;
  }

  get drillInTimeRange(): FlexibleQueryTimeRange {
    let dataModel;

    if (this.metric.type === 'field') {
      dataModel = timeDataModelForDrillIn((this.metric as FieldMetric).property);
    }

    return {
      start: this.drillInDateRange.startMoment.valueOf(),
      end: this.drillInDateRange.endMoment.valueOf(),
      interval: this.drillInDateRange.interval,
      property: this.args.chartSeries.metric.timeProperty,
      data_model: dataModel,
    };
  }

  get initialFilters(): LogicalFilter {
    let chartSeriesFilters = this.args.chartSeries.filters.filters;
    let reportFilters = this.args.renderableChart.reportState.filters?.filters || [];

    // If there are no report filters, then its safe to use the same logical operator as the chart filters do...
    if (reportFilters.length === 0) {
      return {
        type: this.args.chartSeries.filters.type,
        filters: [...chartSeriesFilters],
      };
    }
    if (this.args.chartSeries.filters.type === 'or') {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'changed_chart_filters_from_or_to_and',
        object: 'underlying_data_modal',
        chartId: this.args.chart?.id,
      });
    }

    // TODO properly handle scenario where chartSeriesFilters uses a logical OR
    return {
      type: 'and',
      filters: [...chartSeriesFilters, ...reportFilters],
    };
  }

  get initialSortBy() {
    return this.reportingUnderlyingDataService.getSortByAttributeId(
      this.metric.datasetId,
      this.metric,
    );
  }

  get metric(): Metric {
    return this.args.chartSeries.metric;
  }

  get filterableMetricProperties(): MetricPropertyDefinition[] {
    let properties: MetricProperty[] = this.args.renderableChart.filterableMetricProperties;

    if (this.args.isReportLevel && !this.appService.app.canUseStandalone) {
      let teamPropertySupported = this.args.renderableChart.chartSeries
        .map((series: ChartSeries) => series.metric.teamProperty !== null)
        .includes(true);

      let teamAndTeammatePropIds: string[] = ['teammate'];

      if (teamPropertySupported) {
        teamAndTeammatePropIds.push('team');
      }

      let teamAndTeammateProps = this.reportingMetrics.getPropertiesByIds(teamAndTeammatePropIds);

      return compact([...properties, ...teamAndTeammateProps]);
    }
    return properties;
  }

  @action
  resetPageFrom() {
    this.pageFrom = 0;
  }

  @action
  updatePageFrom(pages: number) {
    this.pageFrom += pages;
  }

  @cached
  get ticketTypes() {
    return TicketType.peekAllAndMaybeLoad() || [];
  }

  get isWithinRowLimit() {
    return this.summary.totalCount <= 10_000_000;
  }

  get sendViaMail() {
    return this.summary.totalCount > 10_000;
  }

  @action
  exportCsv() {
    let analyticsEvent = { object: 'underlying_data_modal', rows: this.summary.totalCount };
    this.reportingCsvExport.exportCsv(
      this.metric.datasetId,
      this.filters,
      this.args.columns,
      this.drillInTimeRange,
      this.sortBy,
      this.sortDirection,
      false,
      analyticsEvent,
      this.args.renderableChart.reportState.timezone,
      this.sendViaMail,
      this.args.renderableChart,
      this.args.chartSeries,
    );
  }

  @action
  onSortUpdate(sortBy: string, sortDirection: 'asc' | 'desc') {
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
  }

  private getTablePropertiesFromDatasetAttributes() {
    let attributes = this.reportingMetrics.getDatasetAttributesFor(this.metric.datasetId);

    return compact([...attributes]).map((attribute: Attribute) => {
      return {
        text: this.reportingMetrics.translateKey(attribute.name) || '',
        value: attribute.id,
        isSelected: this.args.columns.indexOf(attribute.id) !== -1,
        icon: attribute.icon,
        headerName: this.reportingMetrics.translateKey(attribute.headerName) || undefined,
        group: attribute.group,
      };
    });
  }

  get tableProperties(): FilterItem[] {
    return this.getTablePropertiesFromDatasetAttributes();
  }

  @action
  onHoverExportButton() {
    if (this.summary.totalCount > 10_000_000) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'hovered_disabled_export_button',
        object: 'underlying_data_modal',
        row_count: this.summary.totalCount,
      });
    } else if (this.summary.totalCount === 0) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'hovered_disabled_export_button',
        object: 'underlying_data_modal',
        row_count: this.summary.totalCount,
      });
    }
  }

  get exportButtonTooltip() {
    if (this.summary.totalCount > 10_000_000) {
      return this.intl.t(
        'components.reporting.custom.report.data-table.export-csv-limit-exceeded-tooltip',
      );
    } else {
      return this.intl.t('components.reporting.custom.report.data-table.export-csv-tooltip');
    }
  }

  private mapAttributeToFilterItem(attribute: Attribute) {
    return {
      text: attribute.name,
      value: attribute.id,
      isSelected: this.args.columns.indexOf(attribute.id) !== -1,
      icon: attribute.icon || 'transfer',
    };
  }

  getConversationProperties() {
    return this.reportingMetrics
      .getDatasetAttributesForGroup(CONVERSATION_ATTRIBUTES, this.metric.datasetId)
      .map((attribute) => this.mapAttributeToFilterItem(attribute));
  }

  getSystemDefinedAttributes() {
    return this.reportingMetrics
      .getDatasetAttributesForGroup(SYSTEM_DEFINED_ATTRIBUTES, this.metric.datasetId)
      .map((attribute) => this.mapAttributeToFilterItem(attribute));
  }

  get groupedTableProperties() {
    let allAttributes = this.reportingMetrics.getDatasetAttributesFor(this.metric.datasetId);
    let groupedProperties = groupDropDownItems(
      this.tableProperties as DropDownItem[],
      allAttributes,
      this.intl,
    );

    let suggestedAttributeIds = this.reportingUnderlyingDataService.getSuggestedAttributeIds(
      this.metric,
      this.args.isStandalone,
    );
    let suggestedAttributes = this.tableProperties.filter((p) =>
      suggestedAttributeIds.includes(p.value),
    );

    return [
      {
        heading: this.intl.t('app.lib.reporting.custom.filter-headings.suggested-data'),
        items: suggestedAttributes,
      },
      ...groupedProperties,
    ];
  }

  @action
  onSelectionChange(selection: string[]) {
    this.previousSelection = this.selectedDropdownItems;
    this.selectedDropdownItems = selection.uniq();
  }

  @action
  onCloseColumnSelector() {
    if (this.previousSelection !== this.selectedDropdownItems) {
      this.args.setColumns(this.args.chartSeries, this.selectedDropdownItems);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'drill_in',
        columns: this.selectedDropdownItems,
        edited_property: 'columns',
        page_number: this.pageFrom / this.rowsPerPage,
        row_count: this.summary.totalCount,
      });
      this.resetPageFrom();
    }
  }

  @action
  onFiltersChanged(
    index: number,
    attribute: { id: string; field: string },
    values: string[],
    operator: string | null,
  ) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'updated_filters',
      object: 'underlying_data_modal',
      filter_name: attribute.id,
    });
    this.filters = changeFilter(this.filters, index, attribute, values, operator);
    this.resetPageFrom();
    this.applyDateRange(values);
  }

  applyDateRange(dateRange: any) {
    this.drillInDateRange.selectedRange = dateRange.selectedRange;

    if (dateRange.selectedRange === 'custom') {
      this.drillInDateRange.updateRange(dateRange.startMoment, dateRange.endMoment);
    }
  }

  @action
  onFiltersCleared() {
    this.filters = {
      type: 'and',
      filters: [],
    };
    this.resetPageFrom();
  }

  @action
  applyLogicalFilterOperator(operator: LogicalFilterOperator) {
    this.filters = {
      type: operator,
      filters: this.filters.filters,
    };
  }

  get hasData() {
    return this.summary.totalCount > 0;
  }

  get countSummary() {
    return this.intl.t('reporting.data-table.view-summary', {
      currentCount: this.intl.formatNumber(this.summary.retrievedCount),
      totalCount: this.intl.formatNumber(this.summary.totalCount),
    });
  }

  @action
  updateCountSummary(summary: CountSummary) {
    this.summary = summary;
  }

  get timezone() {
    return this.args.renderableChart.reportState?.timezone || this.appService.app.timezone;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::UnderlyingDataModal': typeof UnderlyingDataModal;
  }
}
