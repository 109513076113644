/* import __COLOCATED_TEMPLATE__ from './side-panel.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import {
  type Label,
  RangeInterval,
  type BucketRange,
} from 'embercom/models/reporting/custom/bucket-interval';
import type Chart from 'embercom/models/reporting/custom/chart';
import { DEFAULT_BUCKET_INTERVAL } from 'embercom/models/reporting/custom/chart';
import { isEqual } from 'underscore';

interface Args {
  isOpen: boolean;
  closePanel: () => void;
  chart: Chart;
}

const BUCKET_MINIMUM_INTERVALS = 1;
const BUCKET_MAXIMUM_INTERVALS = 10;

export default class SidePanel extends Component<Args> {
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare store: Store;

  initalIntervalRanges: BucketRange[] = this.args.chart.viewByBucketInterval.range;
  @tracked bucketIntervals: RangeInterval[] = this.args.chart.viewByBucketInterval.intervals;

  get infinity() {
    return Infinity;
  }

  @action
  deleteInterval(bucketInterval: RangeInterval) {
    bucketInterval.delete();
    this.bucketIntervals = this.bucketIntervals.rejectBy('isDeleted');
    this.args.chart.viewByBucketInterval.range = [...this.ranges];
  }

  get ranges() {
    return this.bucketIntervals.rejectBy('isDeleted').map((interval) => interval.toRange());
  }

  get lastRange() {
    return this.ranges[this.ranges.length - 1];
  }

  get disableAddInterval() {
    return this.ranges.length >= BUCKET_MAXIMUM_INTERVALS;
  }

  get disableDeleteInterval() {
    return this.ranges.length === BUCKET_MINIMUM_INTERVALS;
  }

  @action
  updateIntervalValue(updateTo: boolean, interval: RangeInterval, value: number) {
    if (updateTo) {
      // update to
      interval.updateUnitInterval(interval.fromUnitValue, {
        value,
        unit: interval.toUnitValue.unit,
      });
    } else {
      // update from
      interval.updateUnitInterval(
        { value, unit: interval.fromUnitValue.unit },
        interval.toUnitValue,
      );
    }
    this.args.chart.viewByBucketInterval.range = [...this.ranges];
  }

  @action
  updateIntervalLabel(updateTo: boolean, interval: RangeInterval, label: Label | null) {
    if (updateTo) {
      interval.updateUnitInterval(interval.fromUnitValue, {
        value: interval.toUnitValue.value,
        unit: label,
      });
    } else {
      interval.updateUnitInterval(
        { value: interval.fromUnitValue.value, unit: label },
        interval.toUnitValue,
      );
    }
    this.args.chart.viewByBucketInterval.range = [...this.ranges];
  }

  @action
  addInterval() {
    if (this.lastRange.to) {
      let newRange: BucketRange = { from: this.lastRange.to };
      this.args.chart.viewByBucketInterval.range = [...this.ranges, newRange];
      let lastBucketInterval = this.bucketIntervals[this.bucketIntervals.length - 1];
      this.bucketIntervals = [
        ...this.bucketIntervals,
        RangeInterval.ofFromUnit({
          value: lastBucketInterval.toUnitValue.value,
          unit: lastBucketInterval.toUnitValue.unit,
        }),
      ];
    } else {
      let newRange: BucketRange = { from: 0 };
      this.args.chart.viewByBucketInterval.range = [...this.ranges, newRange];
      this.bucketIntervals = [...this.bucketIntervals, new RangeInterval(newRange)];
    }
  }

  @action
  cancel() {
    this.args.closePanel();
    if (isPresent(this.initalIntervalRanges)) {
      this.args.chart.viewByBucketInterval.range = this.initalIntervalRanges;
      this.bucketIntervals = this.initalIntervalRanges.map((range) => new RangeInterval(range)); // we are undoing all deletes
    } else {
      // for legacy charts, going to the interval side panel and clicking cancel could remove all intervals
      this.args.chart.viewByBucketInterval.range = DEFAULT_BUCKET_INTERVAL.range;
      this.bucketIntervals = DEFAULT_BUCKET_INTERVAL.range.map(
        (range: BucketRange) => new RangeInterval(range),
      );
    }
  }

  get disableSave() {
    // disable when user has made no changes
    return isEqual(this.initalIntervalRanges, this.args.chart.viewByBucketInterval.range);
  }

  @action
  save() {
    this.args.closePanel();
    this.args.chart.viewByBucketInterval.range = this.ranges;
    this.initalIntervalRanges = this.ranges;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::SidePanel': typeof SidePanel;
    'reporting/custom/chart-builder/time-interval/side-panel': typeof SidePanel;
  }
}
