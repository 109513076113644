/* import __COLOCATED_TEMPLATE__ from './banner-component.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Chart from 'embercom/models/reporting/custom/chart';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { Config } from 'embercom/objects/inbox/composer-config';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import { getOwner } from '@ember/application';
import type Session from 'embercom/services/session';
import type IntlService from 'ember-intl/services/intl';
import { type BorderColor, type Color } from 'embercom/models/reporting/custom/component';
import { trackAnalyticsEventForChart } from 'embercom/lib/reporting/analytics';
import type { BlockList } from '@intercom/interblocks.ts';

interface Signature {
  Args: {
    chart: Chart;
    deleteChart: () => void;
    editMode: boolean;
    report: {
      createdById: string;
      createdFromTemplateId: string;
    };
  };
}
export default class BannerComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare session: Session;
  @service declare intercomEventService: any;

  @tracked config;
  @tracked isHovered = false;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.config = this.buildComposerConfig();
  }

  get blocksDoc() {
    return this.args.chart.componentBlocks
      ? new BlocksDocument(this.args.chart.componentBlocks)
      : new BlocksDocument([{ text: this.title, type: 'paragraph' }]);
  }

  get color(): Color {
    return this.args.chart.componentColor ? this.args.chart.componentColor : 'bg-neutral-container';
  }

  get borderColor(): BorderColor {
    return this.args.chart.componentBorderColor
      ? this.args.chart.componentBorderColor
      : 'border-neutral-container-emphasis';
  }

  textSizeClass(blockType: string) {
    switch (blockType) {
      case 'heading':
        return 'reporting__composer-text-size-heading';
      case 'subheading':
        return 'reporting__composer-text-size-subheading';
      case 'subheading3':
        return 'reporting__composer-text-size-subheading3';
      default:
        return 'text-base';
    }
  }

  get title() {
    let componentBlocks = this.args.chart.componentBlocks;
    let title = '';

    if (componentBlocks?.length > 0) {
      componentBlocks.forEach((block: any) => {
        title += `<div class="${this.textSizeClass(block.type)} text-${block.align}">${block.text}</div>`;
      });
    } else if (this.args.chart.titleForDisplay) {
      title = this.args.chart.titleForDisplay;
    }

    return title;
  }

  @action
  updateColor(backgroundColor: Color, borderColor: BorderColor) {
    this.updateVisualizationOptions(backgroundColor, borderColor, this.args.chart.componentBlocks);
  }

  @action
  onChange(blockDoc: BlocksDocument) {
    this.updateVisualizationOptions(this.color, this.borderColor, blockDoc.blocks);
  }

  updateVisualizationOptions(color: Color, borderColor: BorderColor, blocks: BlockList) {
    let existingOptions = this.args.chart.visualizationOptions;
    let component = {
      blocks,
      color,
      borderColor,
      isMigrationBanner: false, // once the banner has been edited, we'll assume it's no longer a migration banner
    };

    if (existingOptions) {
      existingOptions.component = component;
    } else {
      this.args.chart.visualizationOptions = this.store.createFragment(
        'reporting/custom/visualization-options',
        { component },
      );
    }
  }

  private buildComposerConfig(): Config {
    let config = new Config(getOwner(this), this.session.workspace.id, {
      allowedImageFileTypes: [],
      allowedAttachmentTypes: [],
      uploader: EmbercomFileUploader,
    });
    config.enableAutoLink = true;
    config.placeholder = this.intl.t('reporting.custom-reports.chart.title-input');
    config.allowTextAlignment = true;
    config.showAllTextAlignment = true;
    config.allowedBlocks = ['paragraph', 'heading', 'subheading', 'subheading3'];
    return config;
  }

  get sharedAnalyticsData() {
    return {
      object: 'banner_chart',
      section: 'reports',
      chart_id: this.args.chart.id,
      place: 'reporting_banner',
      report_created_by_id: this.args.report.createdById,
      template_id: this.args.report.createdFromTemplateId,
      banner_title: this.title,
    };
  }

  @action
  trackDescriptionClick(event: MouseEvent) {
    trackAnalyticsEventForChart(event.target as HTMLElement, {
      ...this.sharedAnalyticsData,
      action: 'clicked',
      interaction: 'description',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Bespoke::BannerComponent': typeof BannerComponent;
    'reporting/bespoke/banner-component': typeof BannerComponent;
  }
}
