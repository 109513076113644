/* import __COLOCATED_TEMPLATE__ from './sankey-chart.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import ChartDataResource from 'embercom/lib/reporting/chart-data-resource';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import type { Options, SeriesOptionsType, SeriesSankeyOptions } from 'highcharts';
import { ConversationFunnelConfig } from 'embercom/components/reporting/automation/ai-insights/funnel-config';
import PALETTE from '@intercom/pulse/lib/palette';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

type ExtendedSeriesSankeyNodesOptionsObject =
  | Highcharts.SeriesSankeyNodesOptionsObject
  | { selected?: boolean };
type ExtendedSeriesSankeyOptions =
  | SeriesSankeyOptions
  | { nodes: Array<ExtendedSeriesSankeyNodesOptionsObject> };

export const SANKEY_COLORS = {
  queryType: PALETTE['vis-azure-80'],
  agentOutcome: PALETTE['vis-blue-80'],
  csat: PALETTE['vis-slateblue-80'],
};

const FILTER_STATE_TO_SELECTED_NODES: Record<string, string[]> = {
  automateWithFin: ['queryTypeInformational', 'finRoutedToTeam', 'teammateOnly'],
  improveCustomerExperience: ['finAssumedResolved', 'finRoutedToTeam', 'negativeCSAT'],
  improveFirstContactResolution: ['queryTypeActions', 'queryTypeComplex', 'finRoutedToTeam'],
  seeWhereFinIsDoingWell: [
    'queryTypeInformational',
    'queryTypePersonalized',
    'queryTypeActions',
    'queryTypeComplex',
    'finConfirmedResolved',
    'finAssumedResolved',
    'positiveCSAT',
  ],
};

interface Signature {
  renderableChart: RenderableChart;
  extraContext: {
    filterState: string;
  };
}

type OptionsWithSankey = Options & { plotOptions: { sankey: { borderRadius: number } } };

function safelyAdjustLayout(series: any, adjust: (series: any) => void) {
  if (!series.adjustingLayout) {
    series.adjustingLayout = true;
    // because layout adjustments can trigger a redraw, we need to prevent infinite recursion
    adjust(series);
    series.redraw();
    series.adjustingLayout = false;
  }
}

function adjustNodeLayout(event: Highcharts.PointerEventObject) {
  let chart = event.target as any;
  let series = chart.series[0];

  safelyAdjustLayout(series, () => {
    // verticallyAlignTeammateAndClosedNodes(series);
    // it's important to adjust the padding last because the other changes
    // can influence the data label positions
    // adjustPaddingIfDataLabelsOverlap(series);
  });
}

export default class SankeyChart extends Component<Signature> {
  @service declare intl: IntlService;

  dataResource = useResource(this, ChartDataResource, () => ({
    renderableChart: this.args.renderableChart,
  }));

  get highchartsSeries() {
    return [this.sankeySeries] as SeriesOptionsType[];
  }

  highchartsOptions: OptionsWithSankey = {
    // a sankey chart doesn't have axes, or a legend
    // we are adding some dummy series so we can show stuff in the chart legend
    // but having other series will enable the axes, so we need to hide them
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    tooltip: {
      enabled: true,
      style: {
        zIndex: 100, // Higher than dataLabels zIndex
      },
      useHTML: true,
      backgroundColor: undefined,
      shadow: false,
      borderWidth: 0,
      borderColor: undefined,
      borderRadius: 0,
      padding: 0,
    },
    chart: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: 20, // we need to avoid the labels from being cut off at the top
      animation: false, // disables animations on redraw (but not initial draw)
      events: {
        redraw: adjustNodeLayout.bind(this),
      },
    },
    plotOptions: {
      series: {
        // a rectangle is rendered as a circle by default because of the legend marker's default borderRadius setting
        legendSymbol: 'rectangle',
        states: {
          inactive: {
            // this is really for the node data labels. The opacity for the links and nodes cannot be set separately
            // here, but they can be controlled via the styles defined in the hbs file
            opacity: 1,
          },
        },
      },
      sankey: {
        nodeWidth: 220,
        borderRadius: 0, // we want the nodes to be rectangles with no rounded edges
        nodePadding: 30,
        nodeAlignment: 'top',
        dataLabels: {
          padding: 0,
          color: 'black',
          useHTML: true,
          align: 'left',
          // crop: false & overflow: allow tell highcharts that it's okay to render the labels in the margins
          crop: false,
          overflow: 'allow',
          allowOverlap: false, // we detect if labels are hidden and increase padding
          x: 10,
          enabled: true,
          zIndex: 10,
          // see https://www.highcharts.com/docs/chart-concepts/templating
          nodeFormat: `
            <div class="text-default">
              <div class="font-semibold leading-4">
                {point.sum:,.0f}
                ({(divide (multiply 100 point.sum) series.options.custom.total):.0f}%)
                {point.title}
              </div>
              <div class="text-11px font-normal leading-4">
                {point.name}
              </div>
            </div>
          `,
        },
        tooltip: {
          headerFormat: undefined,

          pointFormat:
            '{point.fromNode.name} \u2192 {point.toNode.name}: {point.weight} conversations',
          nodeFormat: '{point.name}: {point.sum} conversations',
        },
      },
    },
  };

  get metrics() {
    return ConversationFunnelConfig.getMetricsFromChartDataRequest(this.dataResource.chartData);
  }

  get hasData() {
    let topOfFunnel =
      this.metrics.queryTypeInformational ||
      this.metrics.queryTypePersonalized ||
      this.metrics.queryTypeComplex ||
      this.metrics.queryTypeActions;
    return !!topOfFunnel;
  }

  // TODO: localize these when the strings are finalized
  /* eslint-disable @intercom/intercom/no-bare-strings */
  get sankeySeries(): ExtendedSeriesSankeyOptions {
    let filterState = this.args.extraContext.filterState;
    let nodeSelected = (nodeId: string) =>
      !(
        filterState === 'exploreYourData' ||
        FILTER_STATE_TO_SELECTED_NODES[filterState].includes(nodeId)
      );
    let metrics = this.metrics;
    return {
      type: 'sankey',
      linkColorMode: 'gradient',
      animation: false,
      custom: {
        // this is used in the data label template (plotOptions.sankey.nodeFormat)
        total: metrics.conversations,
      },
      nodes: [
        {
          id: 'queryTypeInformational',
          title: 'Informational',
          name: 'Solved with content',
          color: SANKEY_COLORS.queryType,
          selected: nodeSelected('queryTypeInformational'),
        },
        {
          id: 'queryTypePersonalized',
          title: 'Personalized',
          name: 'Needs customer-specific details',
          color: SANKEY_COLORS.queryType,
          selected: nodeSelected('queryTypePersonalized'),
        },
        {
          id: 'queryTypeActions',
          title: 'Actions',
          name: 'Requires an update in another system',
          color: SANKEY_COLORS.queryType,
          selected: nodeSelected('queryTypeActions'),
        },
        {
          id: 'queryTypeComplex',
          title: 'Complex',
          name: 'Needs investigation',
          color: SANKEY_COLORS.queryType,
          selected: nodeSelected('queryTypeComplex'),
        },
        {
          id: 'finConfirmedResolved',
          name: 'Fin confirmed resolved',
          color: SANKEY_COLORS.agentOutcome,
          selected: nodeSelected('finConfirmedResolved'),
        },
        {
          id: 'finAssumedResolved',
          name: 'Fin assumed resolved',
          color: SANKEY_COLORS.agentOutcome,
          selected: nodeSelected('finAssumedResolved'),
        },
        {
          id: 'finRoutedToTeam',
          name: 'Fin routed to team',
          color: SANKEY_COLORS.agentOutcome,
          selected: nodeSelected('finRoutedToTeam'),
        },
        {
          id: 'teammateOnly',
          name: 'Teammate only',
          color: SANKEY_COLORS.agentOutcome,
          selected: nodeSelected('teammateOnly'),
        },
        {
          id: 'positiveCSAT',
          name: 'Positive AI CSAT',
          color: SANKEY_COLORS.csat,
          selected: nodeSelected('positiveCSAT'),
        },
        {
          id: 'neutralCSAT',
          name: 'Neutral AI CSAT',
          color: SANKEY_COLORS.csat,
          selected: nodeSelected('neutralCSAT'),
        },
        {
          id: 'negativeCSAT',
          name: 'Negative AI CSAT',
          color: SANKEY_COLORS.csat,
          selected: nodeSelected('negativeCSAT'),
        },
      ],
      data: [
        // the Fin flows
        {
          from: 'queryTypeInformational',
          to: 'finConfirmedResolved',
          weight: metrics.informationalAndFinConfirmedResolved,
          selected:
            this.args.extraContext.filterState !== 'exploreYourData' &&
            this.args.extraContext.filterState !== 'seeWhereFinIsDoingWell',
        },
        {
          from: 'queryTypeInformational',
          to: 'finAssumedResolved',
          weight: metrics.informationalAndFinAssumedResolved,
          selected:
            this.args.extraContext.filterState !== 'exploreYourData' &&
            this.args.extraContext.filterState !== 'seeWhereFinIsDoingWell',
        },
        {
          from: 'queryTypeInformational',
          to: 'finRoutedToTeam',
          weight: metrics.informationalAndFinRoutedToTeam,
          selected:
            this.args.extraContext.filterState !== 'automateWithFin' &&
            this.args.extraContext.filterState !== 'exploreYourData',
        },
        {
          from: 'queryTypeInformational',
          to: 'teammateOnly',
          weight: metrics.informationalAndTeammateOnly,
          selected:
            this.args.extraContext.filterState !== 'automateWithFin' &&
            this.args.extraContext.filterState !== 'exploreYourData',
        },
        {
          from: 'queryTypePersonalized',
          to: 'finConfirmedResolved',
          weight: metrics.personalizedAndFinConfirmedResolved,
          selected:
            this.args.extraContext.filterState !== 'exploreYourData' &&
            this.args.extraContext.filterState !== 'seeWhereFinIsDoingWell',
        },
        {
          from: 'queryTypePersonalized',
          to: 'finAssumedResolved',
          weight: metrics.personalizedAndFinAssumedResolved,
          selected:
            this.args.extraContext.filterState !== 'exploreYourData' &&
            this.args.extraContext.filterState !== 'seeWhereFinIsDoingWell',
        },
        {
          from: 'queryTypePersonalized',
          to: 'finRoutedToTeam',
          weight: metrics.personalizedAndFinRoutedToTeam,
          selected: this.args.extraContext.filterState !== 'exploreYourData',
        },
        {
          from: 'queryTypePersonalized',
          to: 'teammateOnly',
          weight: metrics.personalizedAndTeammateOnly,
          selected: this.args.extraContext.filterState !== 'exploreYourData',
        },
        {
          from: 'queryTypeActions',
          to: 'finConfirmedResolved',
          weight: metrics.actionsAndFinConfirmedResolved,
          selected:
            this.args.extraContext.filterState !== 'exploreYourData' &&
            this.args.extraContext.filterState !== 'seeWhereFinIsDoingWell',
        },
        {
          from: 'queryTypeActions',
          to: 'finAssumedResolved',
          weight: metrics.actionsAndFinAssumedResolved,
          selected:
            this.args.extraContext.filterState !== 'exploreYourData' &&
            this.args.extraContext.filterState !== 'seeWhereFinIsDoingWell',
        },
        {
          from: 'queryTypeActions',
          to: 'finRoutedToTeam',
          weight: metrics.actionsAndFinRoutedToTeam,
          selected:
            this.args.extraContext.filterState !== 'exploreYourData' &&
            this.args.extraContext.filterState !== 'improveFirstContactResolution',
        },
        {
          from: 'queryTypeActions',
          to: 'teammateOnly',
          weight: metrics.actionsAndTeammateOnly,
          selected: this.args.extraContext.filterState !== 'exploreYourData',
        },
        {
          from: 'queryTypeComplex',
          to: 'finConfirmedResolved',
          weight: metrics.complexAndFinConfirmedResolved,
          selected:
            this.args.extraContext.filterState !== 'exploreYourData' &&
            this.args.extraContext.filterState !== 'seeWhereFinIsDoingWell',
        },
        {
          from: 'queryTypeComplex',
          to: 'finAssumedResolved',
          weight: metrics.complexAndFinAssumedResolved,
          selected:
            this.args.extraContext.filterState !== 'exploreYourData' &&
            this.args.extraContext.filterState !== 'seeWhereFinIsDoingWell',
        },
        {
          from: 'queryTypeComplex',
          to: 'finRoutedToTeam',
          weight: metrics.complexAndFinRoutedToTeam,
          selected:
            this.args.extraContext.filterState !== 'exploreYourData' &&
            this.args.extraContext.filterState !== 'improveFirstContactResolution',
        },
        {
          from: 'queryTypeComplex',
          to: 'teammateOnly',
          weight: metrics.complexAndTeammateOnly,
          selected: this.args.extraContext.filterState !== 'exploreYourData',
        },
        {
          from: 'finConfirmedResolved',
          to: 'positiveCSAT',
          weight: metrics.finConfirmedResolvedAndPositiveCSAT,
          selected:
            this.args.extraContext.filterState !== 'exploreYourData' &&
            this.args.extraContext.filterState !== 'seeWhereFinIsDoingWell',
        },
        {
          from: 'finConfirmedResolved',
          to: 'neutralCSAT',
          weight: metrics.finConfirmedResolvedAndNeutralCSAT,
          selected: this.args.extraContext.filterState !== 'exploreYourData',
        },
        {
          from: 'finConfirmedResolved',
          to: 'negativeCSAT',
          weight: metrics.finConfirmedResolvedAndNegativeCSAT,
          selected: this.args.extraContext.filterState !== 'exploreYourData',
        },
        {
          from: 'finAssumedResolved',
          to: 'positiveCSAT',
          weight: metrics.finAssumedResolvedAndPositiveCSAT,
          selected:
            this.args.extraContext.filterState !== 'exploreYourData' &&
            this.args.extraContext.filterState !== 'seeWhereFinIsDoingWell',
        },
        {
          from: 'finAssumedResolved',
          to: 'neutralCSAT',
          weight: metrics.finAssumedResolvedAndNeutralCSAT,
          selected: this.args.extraContext.filterState !== 'exploreYourData',
        },
        {
          from: 'finAssumedResolved',
          to: 'negativeCSAT',
          weight: metrics.finAssumedResolvedAndNegativeCSAT,
          selected:
            this.args.extraContext.filterState !== 'exploreYourData' &&
            this.args.extraContext.filterState !== 'improveCustomerExperience',
        },
        {
          from: 'finRoutedToTeam',
          to: 'positiveCSAT',
          weight: metrics.finRoutedToTeamAndPositiveCSAT,
          selected: this.args.extraContext.filterState !== 'exploreYourData',
        },
        {
          from: 'finRoutedToTeam',
          to: 'neutralCSAT',
          weight: metrics.finRoutedToTeamAndNeutralCSAT,
          selected: this.args.extraContext.filterState !== 'exploreYourData',
        },
        {
          from: 'finRoutedToTeam',
          to: 'negativeCSAT',
          weight: metrics.finRoutedToTeamAndNegativeCSAT,
          selected:
            this.args.extraContext.filterState !== 'exploreYourData' &&
            this.args.extraContext.filterState !== 'improveCustomerExperience',
        },
        {
          from: 'teammateOnly',
          to: 'positiveCSAT',
          weight: metrics.teammateOnlyAndPositiveCSAT,
          selected: this.args.extraContext.filterState !== 'exploreYourData',
        },
        {
          from: 'teammateOnly',
          to: 'neutralCSAT',
          weight: metrics.teammateOnlyAndNeutralCSAT,
          selected: this.args.extraContext.filterState !== 'exploreYourData',
        },
        {
          from: 'teammateOnly',
          to: 'negativeCSAT',
          weight: metrics.teammateOnlyAndNegativeCSAT,
          selected: this.args.extraContext.filterState !== 'exploreYourData',
        },
      ],
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::SankeyChart': typeof SankeyChart;
    'reporting/automation/ai-insights/sankey-chart': typeof SankeyChart;
  }
}
