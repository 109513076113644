/* import __COLOCATED_TEMPLATE__ from './topic-summary-card.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
import templateOnlyComponent from '@ember/component/template-only';
import type { Topic } from 'embercom/components/reporting/automation/ai-insights/fin-insights/topics-data-resources';
interface Args {
  topic: Topic;
  metricsForRow?: Record<string, string>;
}

const TopicSummaryCard = templateOnlyComponent<Args>();
export default TopicSummaryCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::TopicSummaryCard': typeof TopicSummaryCard;
  }
}
