/* import __COLOCATED_TEMPLATE__ from './string-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { type FilterBarItem } from 'embercom/services/filter-pattern-service';
import type FilterPatternService from 'embercom/services/filter-pattern-service';

interface Args {
  initiallyOpened: boolean;
  icon: string;
  name: string;
  type: string;
  currentLabel: string;
  selectedValues: string[];
  allItems: FilterBarItem[];
  operator: string;
  removeFilter: () => void;
  filterKnownValuesLoader: (filter: string) => TaskGenerator<void> | void;
  onSelectionChange: (values: string[]) => void;
  trackAnalytics: () => void;
  updateFilter: (filterValues: string[], operator: string) => void;
  enableSearch?: boolean;
  isDisabled: boolean;
  isLoading?: boolean;
  onClose: () => void;
}

export default class StringFilter extends Component<Args> {
  @service declare appService: any;
  @service intl!: IntlService;
  @service filterPatternService!: FilterPatternService;

  @tracked knownValues: string[] = [];
  @tracked operator = this.args.operator;
  @tracked selectedDropdownItems = this.args.selectedValues;
  categoryFilters = ['category', 'not_in_category'];
  availableFilters = [...this.categoryFilters, 'exists', 'not_exists'];

  get items() {
    //TODO: move this logic to filter pattern service
    let { allItems, selectedValues } = this.args;
    let newAllItems = allItems.map((item) => {
      return { ...item, isSelected: selectedValues.includes(item.value) };
    });
    return [{ items: newAllItems }];
  }

  get operatorLabelMappings(): Record<string, string> {
    return this.filterPatternService.mappings;
  }

  get valuesForFilter() {
    let valuesOrPlaceholder = this.selectedDropdownItems.length
      ? this.selectedDropdownItems
      : [REPORTING_FILTER_SELECT_ALL];
    // for exists and not exists filters, values are empty as they are not needed
    return this.categoryFilters.includes(this.operator) ? valuesOrPlaceholder : [];
  }

  get openerLabel() {
    let { allItems, selectedValues } = this.args;
    if (allItems.length === 0) {
      return this.args.currentLabel;
    } else if (selectedValues.length === 0) {
      return this.intl.t('components.reporting.custom.chart-builder.filter-bar.any');
    } else {
      return selectedValues
        .map((value) => allItems.find((item) => item.value === value)?.text)
        .join(', ');
    }
  }

  @action
  removeFilter() {
    this.selectedDropdownItems = [];
    this.operator = '';
    this.args.removeFilter();
  }

  get currentLabel() {
    return this.filterPatternService.makeLabel(this.args.currentLabel, this.args.operator);
  }

  @action
  onClose() {
    if (this.isDestroying) {
      // for some reason onClose gets triggered when you remove the filter
      // even if the dropdown isn't currently open
      return;
    }

    this.args.trackAnalytics();
    this.args.updateFilter(this.valuesForFilter, this.operator);
    this.args.onClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::FilterBar::FilterPattern::StringFilter': typeof StringFilter;
  }
}
