/* import __COLOCATED_TEMPLATE__ from './workflow-name-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import type IntlService from 'ember-intl/services/intl';
import type { SelectedFilter } from 'embercom/services/filter-pattern-service';
import type ReportingUnderlyingDataService from 'embercom/services/reporting-underlying-data-service';
import { type AttributeMapping } from 'embercom/services/reporting-underlying-data-service';

interface Args {
  selected: SelectedFilter;
  loadKnownValuesSources: string[];
  analyticsObject: string;
  setSelected: (filterValues: string[], operator?: string) => void;
  removeFilter: () => void;
  initiallyOpened: boolean;
  icon: string;
  type: string;
  name: string;
  isDisabled: boolean;
}

interface Item {
  text: string;
  value: string;
  isSelected: boolean;
}

export default class WorkflowNameFilter extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare reportingUnderlyingDataService: ReportingUnderlyingDataService;
  @tracked availableWorkflows: AttributeMapping = {};
  @tracked filteredWorkflows: AttributeMapping = {};
  @tracked selectedDropdownItems = this.args.selected.values || [];
  @tracked operator = this.args.selected.operator || 'category';
  @tracked label = this.currentLabel;

  constructor(owner: unknown, args: $TSFixMe) {
    super(owner, args);
    this.availableWorkflows = this.reportingUnderlyingDataService.getAttributeKnownValues(
      'conversation.workflow_conversation_rating.workflow_id',
    );
  }

  get allItems(): Item[] {
    if (!this.filteredWorkflows) {
      return [];
    }

    return Object.entries(this.filteredWorkflows)
      .map(([workflowId, mappedValue]) => ({
        text: mappedValue as string,
        value: workflowId,
        isSelected: this.selectedDropdownItems.includes(workflowId),
      }))
      .sortBy('text');
  }

  get selectedWorkflows() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get currentLabel() {
    if (this.selectedWorkflows.length === 0) {
      return this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.workflow-name-filter.any',
      );
    }

    let workflows = this.selectedWorkflows.map((workflowId) => {
      return this.availableWorkflows[Number(workflowId)];
    });
    return `${workflows.join(', ')}`;
  }

  @action
  trackAnalytics() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_workflow',
      object: this.args.analyticsObject,
      workflow: this.selectedWorkflows,
      operator: this.operator,
    });
  }

  @action
  onClose() {
    if (this.isDestroying) {
      // for some reason onClose gets triggered when you remove the filter
      // even if the dropdown isn't currently open
      return;
    }

    this.label = this.currentLabel;
  }

  @action
  filterValues(filter: string) {
    let filteredEntries = Object.entries(this.availableWorkflows).filter(
      ([_, workflowName]) =>
        workflowName && workflowName.toLowerCase().includes(filter.toLowerCase()),
    );

    this.filteredWorkflows = Object.fromEntries(filteredEntries);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::FilterBar::WorkflowNameFilter': typeof WorkflowNameFilter;
    'reporting/custom/chart-builder/filter-bar/workflow-name-filter': typeof WorkflowNameFilter;
  }
}
